import React, { useEffect, useState } from "react";
import Accordion from "../../../../molecules/accordion";
import Info from "../../../../icons/Info";
import VesselInformation from "./vesselInformation";
import Ship from "../../../../icons/Ship";
import Picture from "../../../../icons/Picture";
import Document from "../../../../icons/Document";
import Tools from "../../../../icons/Tools";
import Tree from "../../../../icons/Tree";
import Chip from "../../../../icons/Chip";
import VesselMachinery from "./vesselMachinery";
import DPControlSystem from "./dpControlSystems";
import RedundancyConcept from "./redundancyConcept";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import {
  getData,
  patchData,
  postData,
  postFormData,
} from "../../../../../services";
import { useCookies } from "react-cookie";
import Disk from "../../../../icons/Disk";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";
import { useParams } from "react-router-dom";
import VesselTrials from "./VesselTrials";
import VesselPhoto from "./vesselPhoto";
import VesselDocuments from "./vesselDocument";

const AddVessels = () => {
  const [cookies] = useCookies(["t"]);
  const { id } = useParams();
  const [vesselData, setVesselData] = useState({});

  // State for Vessel Information
  const [vesselInformation, setVesselInformation] = useState({
    vesselName: "",
    ownerOperator: "",
    industrialMission: "",
    imoNumber: "",
    imoClass: "",
    classificationSociety: "",
    dpClass: "",
    flag: "",
    anniversaryDate: null,
    lastAnnualTrial: null,
    yearOfConstruction: "",
    lengthOverall: "",
    tonnage: "",
    draught: "",
  });

  console.log("vesselInformation--->", vesselInformation);
  // State for Redundant Groups
  const [redundantGroupsRows, setRedundantGroupsRows] = useState([
    {
      name: "",
    },
    {
      name: "",
    },
    {
      name: "",
    },
  ]);

  const redundantGroupsCount = redundantGroupsRows?.length;

  // State for Worst Case Failure
  const [worstCaseFailureRows, setWorstCaseFailureRows] = useState([
    {
      conditions: "",
      thrusters_available: "",
      min_online_generators: "",
    },
    {
      conditions: "",
      thrusters_available: "",
      min_online_generators: "",
    },
    {
      conditions: "",
      thrusters_available: "",
      min_online_generators: "",
    },
  ]);

  const worstCaseFailureCount = worstCaseFailureRows?.length;

  // State for vessel DP Control System
  const [dPControlSystemRows, setDPControlSystemRows] = useState([
    {
      control_system: "",
      model: "",
    },
    {
      control_system: "",
      model: "",
    },
    {
      control_system: "",
      model: "",
    },
  ]);

  const dPControlSystemCount = dPControlSystemRows?.length;

  const [vesselMachineryRows, setVesselMachineryRows] = useState([
    {
      machinery: "",
      power: "",
    },
    {
      machinery: "",
      power: "",
    },
    {
      machinery: "",
      power: "",
    },
  ]);

  const [vesselImage, setVesselImage] = useState("");
  const [singleLineImage, setSingleLineImage] = useState("");
  const [thrusterRedundancyImage, setThrusterRedundancyImage] = useState("");

  console.log("vesselImage--->", vesselImage);

  const vesselMachineryCount = vesselMachineryRows?.length;

  const [documents, setDocuments] = useState([
    {
      id: 1,
      document_name: "",
      category: "",
      date: "",
      third_party_visible: false,
      customer_visible: false,
      document_link: null,
    },
  ]);

  useEffect(() => {
    setVesselInformation((prev) => ({
      ...prev, // Spread the previous state to retain existing values
      vesselName: vesselData?.vessel_name || "",
      ownerOperator: vesselData?.owner || "",
      industrialMission: vesselData?.industrial_mission || "",
      imoNumber: vesselData?.imo_number || "",
      imoClass: vesselData?.imo_dp_equipment_class || "",
      classificationSociety: vesselData?.classification_society || "",
      dpClass: vesselData?.dp_class || "",
      flag: vesselData?.flag || "",
      anniversaryDate: vesselData?.anniversary_date || null,
      lastAnnualTrial: vesselData?.last_annual_trial_date || null,
      yearOfConstruction: vesselData?.year_of_construction || "",
      lengthOverall: vesselData?.length_over_all || "",
      tonnage: vesselData?.tonnage || "",
      draught: vesselData?.draught || "",
    }));
    if (vesselData?.vessel_machinery) {
      setVesselMachineryRows(
        vesselData?.vessel_machinery.map((row) => ({
          machinery: row.machinery,
          power: row.power,
        })),
      );
    }
    if (vesselData?.vessel_photo) {
      setVesselImage(vesselData?.vessel_photo);
    }
    if (vesselData?.single_line_photo) {
      setSingleLineImage(vesselData?.single_line_photo);
    }
    if (vesselData?.thruster_redundancy_photo) {
      setThrusterRedundancyImage(vesselData?.thruster_redundancy_photo);
    }
    if (vesselData?.dp_control_systems) {
      setDPControlSystemRows(
        vesselData?.dp_control_systems.map((row) => ({
          control_system: row.control_system,
          model: row.model,
        })),
      );
    }
    if (vesselData?.redundant_groups) {
      setRedundantGroupsRows(
        vesselData?.redundant_groups.map((row) => ({
          name: row.name,
        })),
      );
    }
    if (vesselData?.worst_case_failure) {
      setWorstCaseFailureRows(
        vesselData?.worst_case_failure.map((row) => ({
          conditions: row.conditions,
          thrusters_available: row.thrusters_available,
          min_online_generators: row.min_online_generators,
        })),
      );
    }
    if (vesselData?.vessel_documents) {
      setDocuments(
        vesselData.vessel_documents.map((doc, index) => ({
          id: index + 1, // or use doc._id if it's in your response
          document_name: doc.document_name || "",
          category: doc.category || "",
          date: doc.date || "",
          document_link: doc.document_link || null, // Assuming it's a link or file path
          customer_visible: doc.customer_visible || false,
          third_party_visible: doc.third_party_visible || false,
        })),
      );
    }
  }, [vesselData]);

  console.log("dp_control_systems--->", dPControlSystemRows);
  console.log("vesselMachineryRows----->", vesselMachineryRows);
  console.log("Vesseldata---->", vesselData);

  // State for Vessel Machinery
  //   const [vesselMachineryRows, setVesselMachineryRows] = useState([
  //     {
  //       machinery: "4X Caterpillar 3516C",
  //       power: "2100 kW",
  //     },
  //     {
  //       machinery: "4X Caterpillar 3516C",
  //       power: "2100 kW",
  //     },
  //     {
  //       machinery: "4X Caterpillar 3516C",
  //       power: "2100 kW",
  //     },
  //     {
  //       machinery: "4X Caterpillar 3516C",
  //       power: "2100 kW",
  //     },
  //     {
  //       machinery: "4X Caterpillar 3516C",
  //       power: "2100 kW",
  //     },
  //     {
  //       machinery: "4X Caterpillar 3516C",
  //       power: "2100 kW",
  //     },
  //   ]);

  //   const [dPControlSystemRows, setDPControlSystemRows] = useState([
  //     {
  //       instrument: "4X Caterpillar 3516C",
  //       manufacturer: "Kongsberg Maritime",
  //     },
  //     {
  //       instrument: "4X Caterpillar 3516C",
  //       manufacturer: "Kongsberg Maritime",
  //     },
  //     {
  //       instrument: "4X Caterpillar 3516C",
  //       manufacturer: "Kongsberg Maritime",
  //     },
  //     {
  //       instrument: "4X Caterpillar 3516C",
  //       manufacturer: "Kongsberg Maritime",
  //     },
  //   ]);
  console.log("worstCaseFailureRows--->", worstCaseFailureRows);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const formData = new FormData();
    // formData.append("file", vesselImage);
    const payload = {
      vessel_name: vesselInformation.vesselName,
      owner: vesselInformation.ownerOperator,
      industrial_mission: vesselInformation.industrialMission,
      imo_number: parseInt(vesselInformation.imoNumber, 10),
      imo_dp_equipment_class: vesselInformation.imoClass,
      classification_society: vesselInformation.classificationSociety,
      dp_class: vesselInformation.dpClass,
      flag: vesselInformation.flag,
      anniversary_date: vesselInformation.anniversaryDate,
      last_annual_trial_date: vesselInformation.lastAnnualTrial,
      year_of_construction: parseInt(vesselInformation.yearOfConstruction, 10),
      length_over_all: parseFloat(vesselInformation.lengthOverall),
      tonnage: parseFloat(vesselInformation.tonnage),
      draught: parseFloat(vesselInformation.draught),
      vessel_machinery: vesselMachineryRows,
      dp_control_systems: dPControlSystemRows,
      redundant_groups: redundantGroupsRows,
      worst_case_failure: worstCaseFailureRows,
      vessel_documents: documents,
      vessel_photo: vesselImage,
      single_line_photo: singleLineImage,
      thruster_redundancy_photo: thrusterRedundancyImage,
    };
    formData.append(
      "vessel_name",
      vesselInformation.vesselName ? vesselInformation.vesselName : "",
    );
    formData.append(
      "owner",
      vesselInformation.ownerOperator ? vesselInformation.ownerOperator : "",
    );
    formData.append(
      "industrial_mission",
      vesselInformation.industrialMission
        ? vesselInformation.industrialMission
        : "",
    );
    formData.append(
      "imo_number",
      vesselInformation.imoNumber
        ? parseInt(vesselInformation.imoNumber, 10)
        : 1,
    );
    formData.append(
      "imo_dp_equipment_class",
      vesselInformation.imoClass ? vesselInformation.imoClass : "",
    );
    formData.append(
      "classification_society",
      vesselInformation.classificationSociety
        ? vesselInformation.classificationSociety
        : "",
    );
    formData.append(
      "dp_class",
      vesselInformation.dpClass ? vesselInformation.dpClass : "",
    );
    formData.append(
      "flag",
      vesselInformation.flag ? vesselInformation.flag : "",
    );
    formData.append(
      "anniversary_date",
      vesselInformation.anniversaryDate
        ? vesselInformation.anniversaryDate
        : "",
    );
    formData.append(
      "year_of_construction",
      vesselInformation.yearOfConstruction
        ? parseInt(vesselInformation.yearOfConstruction, 10)
        : 0,
    );
    formData.append(
      "length_over_all",
      vesselInformation.lengthOverall
        ? parseFloat(vesselInformation.lengthOverall)
        : 0,
    );
    formData.append(
      "tonnage",
      vesselInformation.tonnage ? parseFloat(vesselInformation.tonnage) : 0,
    );
    formData.append(
      "draught",
      vesselInformation.draught ? parseFloat(vesselInformation.draught) : 0,
    );

    // Append the other fields (arrays or JSON)
    formData.append(
      "vessel_machinery",
      vesselMachineryRows
        ? JSON.stringify(vesselMachineryRows)
        : JSON.stringify([
            {
              machinery: "",
              power: "",
            },
            {
              machinery: "",
              power: "",
            },
            {
              machinery: "",
              power: "",
            },
          ]),
    );
    formData.append(
      "dp_control_systems",
      dPControlSystemRows
        ? dPControlSystemRows
        : JSON.stringify([
            {
              control_system: "",
              model: "",
            },
            {
              control_system: "",
              model: "",
            },
            {
              control_system: "",
              model: "",
            },
          ]),
    );
    formData.append(
      "redundant_groups",
      redundantGroupsRows
        ? JSON.stringify(redundantGroupsRows)
        : JSON.stringify([
            {
              name: "",
            },
            {
              name: "",
            },
            {
              name: "",
            },
          ]),
    );
    formData.append(
      "worst_case_failure",
      worstCaseFailureRows
        ? JSON.stringify(worstCaseFailureRows)
        : JSON.stringify([
            {
              conditions: "",
              thrusters_available: "",
              min_online_generators: "",
            },
            {
              conditions: "",
              thrusters_available: "",
              min_online_generators: "",
            },
            {
              conditions: "",
              thrusters_available: "",
              min_online_generators: "",
            },
          ]),
    );
    formData.append(
      "vessel_documents",
      documents
        ? JSON.stringify(documents)
        : JSON.stringify([
            {
              id: 1,
              document_name: "",
              category: "",
              date: "2024-11-06T00:00:00Z",
              document_link: "",
            },
          ]),
    );

    console.log("vesselImages", vesselImage);

    if (id) {
      try {
        // Make API call using postData
        const result = await patchData({
          endpoint: "vessel/update",
          data: payload,
          params: { id: id },
          token: cookies.t,
        });
        if (result) {
          toast.success(`Vessel Updated successfully`, {
            style: confimationStyles,
            duration: 2000,
          });

          fetchVesselData(result?.data?.data?.id);
        }

        console.log("API Response:", result);
      } catch (error) {
        console.error("Error submitting vessel information:", error);
        //   toast.error("Test data not found");
        toast.error(error, {
          style: errorStyles,
          duration: 1000,
        });
      }
    } else {
      try {
        // Make API call using postData
        const result = await postData({
          endpoint: "vessel/create",
          data: payload,
          params: {},
          token: cookies.t,
        });

        console.log("result----->", result?.data?.data?.id);

        if (result) {
          toast.success(`Vessel added successfully`, {
            style: confimationStyles,
            duration: 2000,
          });

          //   fetchVesselData(result?.data?.data?.id);
        }

        console.log("API Response:", result);
      } catch (error) {
        console.error("Error submitting vessel information:", error);
        //   toast.error("Test data not found");
        toast.error(error, {
          style: errorStyles,
          duration: 1000,
        });
      }
    }
  };

  const fetchVesselData = async () => {
    console.log("id------>", id);
    try {
      const res = await getData({
        endpoint: "vessel/getVesselDetailsById",
        token: cookies.t,
        params: { id: id },
      });
      setVesselData(res?.data || {});
    } catch (err) {
      console.log("Error fetching vessel data:", err);
      setVesselData({}); // Ensure vesselData is empty on error
    }
  };

  useEffect(() => {
    if (id) {
      fetchVesselData();
    }
  }, []);

  const accordionData1 = [
    {
      title: "Vessel Information",
      icon: <Ship />,
      content: (
        <VesselInformation
          vesselInformation={vesselInformation}
          setVesselInformation={setVesselInformation}
        />
      ),
    },
    {
      title: "Vessel Photo",
      icon: <Picture />,
      content: (
        <VesselPhoto
          vesselImage={vesselImage}
          setVesselImage={setVesselImage}
          id={id}
        />
      ),
    },
    {
      title: "Redundancy Concept",
      icon: <Tree />,
      content: (
        <RedundancyConcept
          redundantGroupsRows={redundantGroupsRows}
          setRedundantGroupsRows={setRedundantGroupsRows}
          singleLineImage={singleLineImage}
          setSingleLineImage={setSingleLineImage}
          thrusterRedundancyImage={thrusterRedundancyImage}
          setThrusterRedundancyImage={setThrusterRedundancyImage}
          redundantGroupsCount={redundantGroupsCount}
          worstCaseFailureRows={worstCaseFailureRows}
          setWorstCaseFailureRows={setWorstCaseFailureRows}
          worstCaseFailureCount={worstCaseFailureCount}
          id={id}
        />
      ),
      removePadding: true,
    },
    {
      title: "Vessel Documents",
      icon: <Document />,
      content: (
        <VesselDocuments documents={documents} setDocuments={setDocuments} />
      ),
    },
    {
      title: "Vessel Machinery",
      icon: <Tools />,
      content: (
        <VesselMachinery
          vesselMachineryRows={vesselMachineryRows}
          setVesselMachineryRows={setVesselMachineryRows}
        />
      ),
      count: vesselMachineryCount,
    },
    {
      title: "DP Control Systems",
      icon: <Chip />,
      content: (
        <DPControlSystem
          dPControlSystemRows={dPControlSystemRows}
          setDPControlSystemRows={setDPControlSystemRows}
        />
      ),
      count: dPControlSystemCount,
    },
  ];

  return (
    <>
      <div
        style={{
          // display: "flex",
          // alignItems: "center", // Vertically centers the items
          // justifyContent: "space-between", // Spaces out items
          // padding: "10px", // Optional: Add some padding around the container
          // //   backgroundColor: "#f8f9fa", // Optional: Add a background color
          // borderRadius: "4px", // Optional: Add rounded corners
          // marginBottom: "5px",
          // //   backgroundColor: "#f2f2f2",s
          //-----
          display: "flex",
          alignItems: "center", // Vertically centers the items
          justifyContent: "space-between", // Spaces out items
          paddingTop: "10px", // Optional: Add some padding around the container
          // backgroundColor: "#e0e0e0", // Optional: Add a background color
          // borderRadius: "4px", // Optional: Add rounded corners
          backgroundColor: "white",
          position: "sticky",
          top: "0px",
          marginTop: "-25px",
          marginBottom: "12px",
          zIndex: "99",
          paddingBottom: "10px",
          // boxShadow: "0px 4px 6px -2px rgba(0, 0, 0, 0.1)",
          // top: "30px",
        }}
      >
        {/* <Breadcrumb
          labels={[
            "Vessels",
            id ? `${vesselData?.vessel_name}` : "Add Vessel",
            " ",
          ]}
          logos={[
            <Ship />, // Logo for "Vessels"
            // <Ship />, // Logo for "Edit Trial"
          ]}
        /> */}
        <Breadcrumb
          labels={[`${vesselData?.vessel_name || "Add Vessel"}`]} // Labels for the breadcrumb
          logos={[
            <Ship width="20px" height="20px" />, // Logo for "Vessels"
            null, // No logo for "Settings"
          ]}
          // paths={[`/dashboard/vessel/${vessel_id}`]} // Paths for each breadcrumb
          paths={[`/dashboard/vessel/add`]} // Paths for each breadcrumb
          excludeLastPath={false}
          stepsToRemove={0}
        />

        <button
          onClick={handleSubmit}
          style={{
            ...styles.submitButton,
            marginTop: "0px !important",
          }}
        >
          {/* <Disk /> */}
          Save
        </button>
      </div>

      <Accordion items={accordionData1} />
      <div style={{ height: "20px" }}></div>
      {id && <VesselTrials vesselId={id} />}
    </>
  );
};
const styles = {
  submitButton: {
    marginTop: "16px",
    padding: "8px 16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default AddVessels;
