import React, { useEffect, useState } from "react";
import MyTextInput from "../../../../atoms/MyTextInput";
import { postData } from "../../../../../services";
import { useCookies } from "react-cookie";
import { DateSelector } from "../../../../molecules/MonthYearSelector";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";
import Trash from "../../../../icons/Trash";
import AddBold from "../../../../icons/AddBold";
import Modal from "../../../../organisms/modals/DeleteModal"; // Import your modal
import Notes from "../../../../icons/Notes";
import ArrowUp from "../../../../icons/ArrowUp";
import ArrowDown from "../../../../icons/ArrowDown";
import TestStepImage from "./testStepImage";

// const TestProcedure = () => {
// const [methodFields, setMethodFields] = useState([
//   {
//     method: "",
//     expected_result: "",
//     notes: "",
//     result: "",
//     is_notes_visible: false,
//     testSteps: [], // Array for storing test step images/notes
//     isExpanded: false, // To toggle accordion
//   },
// ]);
// const [setup, setSetup] = useState("");
// const [testRemarks, setTestRemarks] = useState("");
// const [completeDate, setCompleteDate] = useState(null);
// const [witnessedBy, setWitnessedBy] = useState("");

// const [setup, setSetup] = useState("");
// const [testRemarks, setTestRemarks] = useState("");
// const [completeDate, setCompleteDate] = useState(null);
// const [witnessedBy, setWitnessedBy] = useState("");

const TestProcedure = ({
  methodFields,
  setMethodFields,
  setup,
  setSetup,
  testRemarks,
  setTestRemarks,
  completeDate,
  setCompleteDate,
  witnessedBy,
  setWitnessedBy,
  validationErrors,
  setValidationErrors,
}) => {
  const [cookies] = useCookies(["t"]);

  const [testStepData, setTestStepData] = useState([
    {
      note: "",
      image_link: null,
    },
  ]);

  console.log("validationErrors-->", validationErrors);

  const [capturedImage5, setCapturedImage5] = useState(null);
  const [note25, setNote25] = useState(""); // State to store the note entered by the user
  const [testImage3, setTestImage3] = useState(null);
  const [testNote3, setTestNote3] = useState("");

  const [isModalActive, setIsModalActive] = useState(false); // State for modal
  const [selectedFieldIndex, setSelectedFieldIndex] = useState(null); // State for selected field

  console.log("testStepData", testStepData);

  useEffect(() => {
    if (testNote3 && testImage3) {
      setTestStepData((prevNotes) => [
        ...prevNotes,
        { note: testNote3, image_link: testImage3 },
      ]);
    }
    if (capturedImage5) {
      setTestStepData((prevNotes) => [
        ...prevNotes,
        { note: note25, image_link: capturedImage5 },
      ]);
    }
  }, [testNote3, testImage3, capturedImage5]); // Dependency array watches for changes in testNote and testImage

  const handleMethodChange = (index, field, value) => {
    const updatedFields = [...methodFields];
    updatedFields[index][field] = value;
    setMethodFields(updatedFields);
  };

  const addMethodField = () => {
    setMethodFields([
      ...methodFields,
      {
        method: "",
        expected_result: "",
        notes: "",
        result: "",
        is_notes_visible: false,
        testSteps: [],
        isExpanded: false,
      },
    ]);
  };
  const confirmDeleteField = (index) => {
    setSelectedFieldIndex(index);
    setIsModalActive(true); // Show modal for delete confirmation
  };

  const handleDeleteField = () => {
    const updatedFields = methodFields.filter(
      (_, i) => i !== selectedFieldIndex,
    );
    setMethodFields(updatedFields);
    setIsModalActive(false); // Close modal after deletion
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const payload = { setup, methodFields, completeDate, witnessedBy };

    try {
      const result = await postData({
        endpoint: "vesselTrial/create",
        data: payload,
        token: cookies.t,
      });
      toast.success("Vessel Trial added successfully", {
        style: confimationStyles,
        duration: 2000,
      });
    } catch (error) {
      console.error("Error submitting vessel information:", error);
      toast.error(error.message || "An error occurred", {
        style: errorStyles,
        duration: 1000,
      });
    }
  };

  // const handleAddNotes = (index) => {
  //   const updatedFields = [...methodFields];
  //   updatedFields[index].is_notes_visible = true; // Show notes input for specific method field
  //   setMethodFields(updatedFields);
  // };
  const handleAddNotes = (index) => {
    const updatedFields = [...methodFields];
    updatedFields[index].is_notes_visible =
      !updatedFields[index].is_notes_visible; // Toggle the visibility
    setMethodFields(updatedFields);
  };

  const handleNotesChange = (index, value) => {
    const updatedFields = [...methodFields];
    updatedFields[index].notes = value; // Update notes for specific method field
    setMethodFields(updatedFields);
  };

  const toggleExpandSection = (index) => {
    const updatedFields = [...methodFields];
    updatedFields[index].isExpanded = !updatedFields[index].isExpanded;
    setMethodFields(updatedFields);
  };

  const handleTestStepChange = (index, updatedTestSteps) => {
    const updatedFields = [...methodFields];
    updatedFields[index].testSteps = updatedTestSteps;
    setMethodFields(updatedFields);
  };

  const handleFileUpload = (index, files) => {
    const updatedFields = [...methodFields];
    const newTestSteps = files.map((file) => ({ file, notes: "" }));
    updatedFields[index].testSteps = [
      ...updatedFields[index].testSteps,
      ...newTestSteps,
    ];
    setMethodFields(updatedFields);
  };

  const handleNotesChangeForTestStep = (index, stepIndex, value) => {
    const updatedFields = [...methodFields];
    updatedFields[index].testSteps[stepIndex].notes = value;
    setMethodFields(updatedFields);
  };

  console.log("validationErrors.setup", validationErrors.setup);

  return (
    <form onSubmit={handleSubmit}>
      {/* Setup Field */}
      <div className="fullWidth">
        <MyTextInput
          type="text"
          name="setup"
          customholder="Setup"
          value={setup}
          onChange={(e) => setSetup(e.target.value)}
          // blurError={validationErrors.setup} // Pass validation error
        />
        {/* {validationErrors.setup && (
          <span
            className="error"
            style={{ color: "#be0f00", fontSize: "14px" }}
          >
            {validationErrors.setup}
          </span>
        )} */}
      </div>
      {/* Dynamic Method Fields */}
      {methodFields.map((field, index) => (
        <div key={index} className="methodContainer">
          <div className="methodHeader">
            <span>
              <b>{`#${index + 1}`}</b>
            </span>
            <button
              type="button"
              className="deleteButton"
              onClick={() => confirmDeleteField(index)}
              style={{ marginRight: "6px" }}
            >
              <Trash />
            </button>
          </div>
          <div className="fullWidth">
            <MyTextInput
              type="text"
              name={`method${index}`}
              customholder="Method"
              value={field.method}
              onChange={(e) =>
                handleMethodChange(index, "method", e.target.value)
              }
              preview={true}
            />
          </div>
          <div className="fullWidth">
            <MyTextInput
              type="text"
              name={`expectedResult${index}`}
              customholder="Expected Result"
              value={field.expected_result}
              onChange={(e) =>
                handleMethodChange(index, "expected_result", e.target.value)
              }
              preview={true}
            />
          </div>

          {/* Notes input is shown based on the isNotesVisible flag for each index */}
          {field?.is_notes_visible && (
            <div className="fullWidth">
              {console.log("field?.is_notes_visible", field?.is_notes_visible)}
              <MyTextInput
                type="text"
                value={field?.notes}
                onChange={(e) => handleNotesChange(index, e.target.value)}
                customholder="Additional Notes"
                className="notes-input"
                preview={true}
              />
            </div>
          )}
          <div className="fullWidth">
            <MyTextInput
              type="text"
              name={`result${index}`}
              customholder="Result"
              value={field.result}
              onChange={(e) =>
                handleMethodChange(index, "result", e.target.value)
              }
              preview={true}
            />
          </div>
          {/* ------------------------------------ */}

          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            <button type="button" onClick={() => handleAddNotes(index)}>
              <Notes />
            </button>
          </div>
          {/* <div className="accordion-header1">
            <div style={{ fontSize: "16px", fontWeight: "bold" }}>
              Test Step Image
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <button
                type="button" // Prevent form submission
                onClick={() => toggleExpandSection(index)}
                className="accordion-toggle"
              >
                {field.isExpanded ? <ArrowUp /> : <ArrowDown />}
              </button>
            </div>
          </div> */}

          {/* {field.isExpanded && (
            // <div className="testStepSection">
            //   <input
            //     type="file"
            //     multiple
            //     onChange={(e) =>
            //       handleFileUpload(index, Array.from(e.target.files))
            //     }
            //   />
            //   {field.testSteps.map((step, stepIndex) => (
            //     <div key={stepIndex} className="testStepItem">
            //       <div>{step.file.name}</div>
            //       <textarea
            //         placeholder="Add notes for this step"
            //         value={step.notes}
            //         onChange={(e) =>
            //           handleNotesChangeForTestStep(
            //             index,
            //             stepIndex,
            //             e.target.value,
            //           )
            //         }
            //       />
            //     </div>
            //   ))}
            // </div>
            <div>
              <TestStepImage
                testStepData={testStepData}
                // setTestStepData={setTestStepData}
                setTestStepData={(updatedSteps) =>
                  handleTestStepChange(index, updatedSteps)
                }
                capturedImage5={capturedImage5}
                setCapturedImage5={setCapturedImage5}
                note25={note25}
                setNote25={setNote25}
                testImage3={testImage3}
                setTestImage3={setTestImage3}
                testNote3={testNote3}
                setTestNote3={setTestNote3}
              />
            </div>
          )} */}
        </div>
      ))}
      <button type="button" onClick={addMethodField}>
        <AddBold width="24" height="24" />
      </button>
      <div className="fullWidth" style={{ marginTop: "15px" }}>
        <MyTextInput
          type="text"
          name="testRemarks"
          customholder="Test Remarks"
          value={testRemarks}
          onChange={(e) => setTestRemarks(e.target.value)}
        />
      </div>
      {/* Complete Date and Witnessed By */}
      <div className="row" style={{ display: "flex", marginTop: "15px" }}>
        <div style={{ width: "50%", position: "relative" }}>
          <DateSelector
            placeholder="Complete Date"
            selectedDate={completeDate}
            onChange={setCompleteDate}
          />
        </div>
        <div style={{ width: "50%" }}>
          <MyTextInput
            type="text"
            name="witnessedBy"
            customholder="Witnessed By"
            value={witnessedBy}
            onChange={(e) => setWitnessedBy(e.target.value)}
            // blurError={validationErrors.witnessedBy} // Pass validation error
          />
        </div>
      </div>
      {isModalActive && (
        <Modal
          isActive={isModalActive}
          onClose={() => setIsModalActive(false)}
          title="Method Field"
          name={`Field #${selectedFieldIndex + 1}`}
          onClick={handleDeleteField}
          description="Are you sure you want to delete this method field?"
        />
      )}
      <style jsx>{`
        .fullWidth {
          width: 100%;
          margin-bottom: 16px;
        }
        .halfWidth {
          width: 48% !important;
        }
        .row {
          display: flex;
          justify-content: space-between;
          margin-bottom: 16px;
        }
        .methodContainer {
          margin-bottom: 16px;
        }
        .methodHeader {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 10px;
        }
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          color: white;
          border: none;
          border-radius: 40px;
          cursor: pointer;
          background: none;
        }
        .deleteButton {
          background: none;
          border: none;
          cursor: pointer;
          color: red;
          padding: 0;
          margin: 0;
        }
        .deleteButton:hover {
          color: darkred;
        }
        .accordion-header1 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          background-color: #f5f5f5;
          margin-top: 12px;
          height: 40px;
          padding: 10px;
          border-radius: 5px;
        }
      `}</style>
    </form>
  );
};

export default TestProcedure;
