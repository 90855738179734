import React, { useEffect, useState } from "react";
import Modal from "../../../../organisms/modals/DeleteModal";
import MyTextInput from "../../../../atoms/MyTextInput";
import { DateSelector } from "../../../../molecules/MonthYearSelector";
import Trash from "../../../../icons/Trash";
import AddBold from "../../../../icons/AddBold";
import MyDropdown from "../../../../atoms/MyDropdown";
import ArrowUp from "../../../../icons/ArrowUp";
import ArrowDown from "../../../../icons/ArrowDown";
import DownloadBold from "../../../../icons/DownloadBold";
import { getData, postFormData } from "../../../../../services";
import { useCookies } from "react-cookie";
import { ObjectId } from "bson";

const AllRecommendation = ({
  vesselId,
  trialId,
  recommendationsData,
  setRecommendationsData,
  filterClosed,
}) => {
  // const data = [
  //   {
  //     _id: "673c27d593b9451f493e08b3",
  //     recommendation_rows: [
  //       {
  //         sno: 1,
  //         number: "A 1",
  //         description: "Amandeep",
  //         type: "A",
  //         status: "Closed",
  //         recommendations: [
  //           {
  //             no: "#1.1",
  //             note: "erdtfyuhijok",
  //             documentDate: "2024-11-21T00:00:00.000Z",
  //             file: null,
  //             _id: "673c76d20f2a365a497536a0",
  //           },
  //           {
  //             no: "#1.2",
  //             note: "Amlesh",
  //             documentDate: "2024-11-21T00:00:00.000Z",
  //             file: null,
  //             _id: "673c76d20f2a365a497536a1",
  //           },
  //           {
  //             no: "#1.3",
  //             note: "Varun",
  //             documentDate: "2024-11-12T00:00:00.000Z",
  //             file: null,
  //             _id: "673c76d20f2a365a497536a2",
  //           },
  //         ],
  //         _id: "673c93160fa819bb90ca5ac1",
  //       },
  //     ],
  //   },
  //   {
  //     _id: "673c3d468faecc65214dadff",
  //     recommendation_rows: [
  //       {
  //         sno: 1,
  //         number: "B 1",
  //         description: "heloooo1",
  //         type: "B",
  //         status: "Closed",
  //         recommendations: [
  //           {
  //             no: "#1.1",
  //             note: "Hellooo2",
  //             documentDate: "2024-11-18T00:00:00.000Z",
  //             file: null,
  //             _id: "673d63b2a580d6dc7b38144f",
  //           },
  //           {
  //             no: "#1.2",
  //             note: "Hellooo3",
  //             documentDate: "2024-11-21T00:00:00.000Z",
  //             file: null,
  //             _id: "673d63b2a580d6dc7b381450",
  //           },
  //         ],
  //         _id: "673d63b2a580d6dc7b38144e",
  //       },
  //     ],
  //   },
  //   {
  //     _id: "673d6460a580d6dc7b3814f7",
  //     recommendation_rows: [
  //       {
  //         sno: 1,
  //         number: "B 1",
  //         description: "hiiii1111",
  //         type: "B",
  //         status: "Opened",
  //         recommendations: [
  //           {
  //             no: "#1.1",
  //             note: "Hiii2",
  //             documentDate: "2024-11-14T00:00:00.000Z",
  //             file: null,
  //             _id: "673d6460a580d6dc7b3814fa",
  //           },
  //           {
  //             no: "#1.2",
  //             note: "hiii3",
  //             documentDate: "2024-11-21T00:00:00.000Z",
  //             file: null,
  //             _id: "673d6460a580d6dc7b3814fb",
  //           },
  //         ],
  //         _id: "673d6460a580d6dc7b3814f9",
  //       },
  //       {
  //         sno: 2,
  //         number: "B 2",
  //         description: "hiiiuuhb1",
  //         type: "B",
  //         status: "Opened",
  //         recommendations: [
  //           {
  //             no: "#2.1",
  //             note: "dfgthyj",
  //             documentDate: "2024-11-21T00:00:00.000Z",
  //             file: null,
  //             _id: "673d6460a580d6dc7b3814fd",
  //           },
  //           {
  //             no: "#2.2",
  //             note: "ASDFGHJKL",
  //             documentDate: "2024-11-14T00:00:00.000Z",
  //             file: null,
  //             _id: "673d6460a580d6dc7b3814fe",
  //           },
  //           {
  //             no: "#2.3",
  //             note: "YVHBJNKJ9",
  //             documentDate: "2024-11-22T00:00:00.000Z",
  //             file: null,
  //             _id: "673d6460a580d6dc7b3814ff",
  //           },
  //         ],
  //         _id: "673d6460a580d6dc7b3814fc",
  //       },
  //     ],
  //   },
  // ];
  const [cookies] = useCookies(["t"]);
  // const [recommendationsData, setRecommendationsData] = useState();
  const [isModalActive, setIsModalActive] = useState(false);
  const [deleteTarget, setDeleteTarget] = useState({});

  console.log("filterClosed--->", filterClosed);

  console.log("recommendationsData--->", recommendationsData);

  const handleAddRecommendation = (parentId, rowId) => {
    const updatedData = recommendationsData.map((parent) => {
      if (parent._id === parentId) {
        const updatedRows = parent.recommendation_rows.map((row) => {
          if (row._id === rowId) {
            const newRecIndex = row.recommendations.length + 1;
            const newRecommendation = {
              no: `#${row.sno}.${newRecIndex}`,
              note: "",
              documentDate: new Date().toISOString(),
              file: null,
              // _id: Date.now().toString(), // Generate a unique ID
              _id: new ObjectId().toString(), // Generate a MongoDB-like ObjectId
            };
            return {
              ...row,
              recommendations: [...row.recommendations, newRecommendation],
            };
          }
          return row;
        });
        return { ...parent, recommendation_rows: updatedRows };
      }
      return parent;
    });
    setRecommendationsData(updatedData);
  };

  const handleDeleteRecommendation = (parentId, rowId, recIndex) => {
    const updatedData = recommendationsData.map((parent) => {
      if (parent._id === parentId) {
        const updatedRows = parent.recommendation_rows.map((row) => {
          if (row._id === rowId) {
            const updatedRecs = row.recommendations.filter(
              (_, index) => index !== recIndex,
            );
            return {
              ...row,
              recommendations: updatedRecs.map((rec, idx) => ({
                ...rec,
                no: `#${row.sno}.${idx + 1}`, // Re-index recommendations
              })),
            };
          }
          return row;
        });
        return { ...parent, recommendation_rows: updatedRows };
      }
      return parent;
    });
    setRecommendationsData(updatedData);
    setIsModalActive(false);
  };

  // };
  const handleInputChange = (parentId, rowId, recId, field, value) => {
    console.log(
      "parentId, rowId, recId, field, value",
      parentId,
      rowId,
      recId,
      field,
      value,
    );

    const updatedData = recommendationsData.map((parent) => {
      if (parent._id === parentId) {
        const updatedRows = parent.recommendation_rows.map((row) => {
          if (row._id === rowId) {
            // If recId is null, update the row field directly
            if (recId === null) {
              return { ...row, [field]: value };
            }
            // Otherwise, update the specific recommendation
            const updatedRecs = row.recommendations.map((rec) => {
              if (rec._id === recId) {
                return { ...rec, [field]: value };
              }
              return rec;
            });
            return { ...row, recommendations: updatedRecs };
          }
          return row;
        });
        return { ...parent, recommendation_rows: updatedRows };
      }
      return parent;
    });

    setRecommendationsData(updatedData);
  };

  const confirmDeleteRecommendation = (parentId, rowId, recIndex) => {
    setDeleteTarget({ parentId, rowId, recIndex });
    setIsModalActive(true);
  };

  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRecommendations = (rowId) => {
    setExpandedRows((prev) =>
      prev.includes(rowId)
        ? prev.filter((id) => id !== rowId)
        : [...prev, rowId],
    );
  };

  // const filterRecommendationRows = (data, filterClosed) => {
  //   return data
  //     .map((test) => {
  //       const filteredRecommendationRows = test.recommendation_rows?.filter(
  //         (row) => {
  //           // If filterClosed is true, filter for 'Closed' status
  //           if (filterClosed) {
  //             return row.status === "Closed";
  //           }
  //           // If filterClosed is undefined or false, return both Opened and Closed status
  //           return true;
  //         },
  //       );

  //       // Return the test object with the filtered rows
  //       return {
  //         ...test,
  //         recommendation_rows: filteredRecommendationRows,
  //       };
  //     })
  //     .filter((test) => test.recommendation_rows.length > 0); // Filter out items with no recommendation rows
  // };

  const filterRecommendationRows = (data, filterClosed) => {
    return data
      .map((test) => {
        const filteredRecommendationRows = test?.recommendation_rows?.filter(
          (row) => {
            // If filterClosed is true, show both Opened and Closed status
            if (filterClosed === true) {
              return true; // Return both Opened and Closed
            }
            // If filterClosed is undefined or false, show only Opened status
            return row.status === "Opened";
          },
        );

        // Return the test object with the filtered rows
        return {
          ...test,
          recommendation_rows: filteredRecommendationRows,
        };
      })
      .filter((test) => test.recommendation_rows?.length > 0); // Filter out items with no recommendation rows
  };

  const filteredData = filterRecommendationRows(
    recommendationsData,
    filterClosed,
  );

  console.log("filteredData--->", filteredData);
  console.log("recommendationsData-->", recommendationsData);

  return (
    <div>
      {filteredData.map((parent) => (
        <div
          key={parent._id}
          style={{
            borderRadius: "3px",
            padding: "10px",
            marginBottom: "10px",
            // boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.1)",
            border: "1px solid #e8e8e8",
          }}
        >
          <div
            style={{
              fontSize: "18px",
              fontWeight: "500",
            }}
          >
            <b>{parent.test_title}</b>
          </div>

          <table className="recommendation-table" style={{ width: "100%" }}>
            <tbody>
              {parent.recommendation_rows.map((row) => (
                <>
                  {/* Row containing Number, Description, Type, and Status */}
                  <tr key={`row-${row._id}`}>
                    <td>
                      <b>#{row.sno}</b>
                    </td>
                    <td style={{ width: "10%" }}>
                      <MyTextInput
                        name="number"
                        value={row.number}
                        onChange={(e) =>
                          handleInputChange(
                            parent._id,
                            row._id,
                            null,
                            "number",
                            e.target.value,
                          )
                        }
                        customholder="Number"
                      />
                    </td>
                    <td style={{ width: "50%" }}>
                      <MyTextInput
                        name="description"
                        value={row.description}
                        onChange={(e) =>
                          handleInputChange(
                            parent._id,
                            row._id,
                            null,
                            "description",
                            e.target.value,
                          )
                        }
                        customholder="Description"
                      />
                    </td>
                    <td style={{ width: "10%" }}>
                      <MyDropdown
                        placeholder="Type"
                        data={[
                          { label: "A", value: "A" },
                          { label: "B", value: "B" },
                          { label: "C", value: "C" },
                        ]}
                        selectedValue={row.type}
                        onChange={(option) =>
                          handleInputChange(
                            parent._id,
                            row._id,
                            null,
                            "type",
                            option,
                          )
                        }
                      />
                    </td>
                    <td style={{ width: "15%" }}>
                      <MyDropdown
                        placeholder="Status"
                        data={[
                          { label: "Opened", value: "Opened" },
                          { label: "Closed", value: "Closed" },
                        ]}
                        selectedValue={row.status}
                        onChange={(option) =>
                          handleInputChange(
                            parent._id,
                            row._id,
                            null,
                            "status",
                            option,
                          )
                        }
                      />
                    </td>
                    {/* <td style={{ padding: "10px" }}>
                      <a href="#" style={{ marginLeft: "10px" }}>
                        Related Test
                      </a>
                    </td> */}
                    <td style={{ padding: "10px" }}>
                      <a
                        href={`/dashboard/Trial/${vesselId}/${trialId}/${parent._id}/edit`}
                        style={{ marginLeft: "10px" }}
                      >
                        Related Test
                      </a>
                    </td>
                  </tr>
                  <tr key={`rec-${row._id}`}>
                    <td colSpan={6}>
                      <div className="recommendations-container">
                        <div className="accordion-header1">
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "10px",
                              justifyContent: "space-between",
                            }}
                          >
                            <div style={{ display: "flex", gap: "10px" }}>
                              <div
                                style={{
                                  fontSize: "16px",
                                  fontWeight: "bold",
                                }}
                                onClick={() => toggleRecommendations(row._id)}
                              >
                                Recommendation #{row.sno} Closeout Evidence
                              </div>
                              <button
                                onClick={() =>
                                  handleAddRecommendation(parent._id, row._id)
                                }
                                style={{ marginTop: "0px !important" }}
                              >
                                <AddBold width={20} height={20} />
                              </button>
                            </div>
                            <button
                              onClick={() => toggleRecommendations(row._id)}
                            >
                              {expandedRows.includes(row._id) ? (
                                <ArrowUp />
                              ) : (
                                <ArrowDown />
                              )}
                            </button>
                          </div>
                        </div>

                        {expandedRows.includes(row._id) && (
                          <table
                            className="recommendations-table"
                            style={{ width: "100%" }}
                          >
                            {/* <tbody>
                              {row.recommendations.map((rec, recIndex) => (
                                <tr
                                  key={rec._id}
                                  className="recommendation-item"
                                >
                                  <td>
                                    <b>{rec.no}</b>
                                  </td>
                                  <td style={{ width: "60%" }}>
                                    <MyTextInput
                                      name="note"
                                      value={rec.note}
                                      onChange={(e) =>
                                        handleInputChange(
                                          parent._id,
                                          row._id,
                                          rec._id,
                                          "note",
                                          e.target.value,
                                        )
                                      }
                                      customholder="Note"
                                    />
                                  </td>
                                  <td>
                                    <div style={{ position: "relative" }}>
                                      <DateSelector
                                        selectedDate={
                                          new Date(rec.documentDate)
                                        }
                                        onChange={(date) =>
                                          handleInputChange(
                                            parent._id,
                                            row._id,
                                            rec._id,
                                            "documentDate",
                                            date,
                                          )
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <input
                                      type="file"
                                      className="compact-file-input"
                                      // onChange={(e) =>
                                      //   handleInputChange(
                                      //     parent._id,
                                      //     row._id,
                                      //     rec._id,
                                      //     "file",
                                      //     e.target.files[0],
                                      //   )
                                      // }
                                      onChange={async (e) => {
                                        const file = e.target.files[0]; // Get the selected file
                                        if (file) {
                                          const formData = new FormData();
                                          formData.append("file", file);

                                          try {
                                            // Call the upload API
                                            const response = await postFormData(
                                              {
                                                endpoint: "vessel/upload-file",
                                                data: formData,
                                                token: cookies.t, // Use your auth token
                                              },
                                            );

                                            const s3Link =
                                              response?.data?.data
                                                ?.s3UploadedLink;

                                            if (s3Link) {
                                              console.log(
                                                "File uploaded successfully:",
                                                s3Link,
                                              );
                                              handleInputChange(
                                                parent._id,
                                                row._id,
                                                rec._id,
                                                "file",
                                                s3Link,
                                              );
                                            } else {
                                              console.error(
                                                "Error: S3 link not returned",
                                              );
                                            }
                                          } catch (error) {
                                            console.error(
                                              "File upload failed:",
                                              error,
                                            );
                                          }
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <button
                                      className="download-button"
                                      style={{ marginTop: "10px" }}
                                      onClick={() => {
                                        if (!rec.file) {
                                          alert(
                                            "File not available for download.",
                                          );
                                          return;
                                        }

                                        const anchor =
                                          document.createElement("a");
                                        anchor.href = rec.file; // Assuming rec.file contains the S3 link
                                        anchor.download = rec.file
                                          .split("/")
                                          .pop(); // Use the file name from the URL or specify one
                                        anchor.target = "_blank"; // This ensures it doesn't open in the same window
                                        anchor.click(); // Trigger the download
                                      }}
                                    >
                                      <DownloadBold
                                        color={rec.file ? "black" : "gray"}
                                      />
                                    </button>
                                  </td>
                                  <td>
                                    <button
                                      onClick={() =>
                                        confirmDeleteRecommendation(
                                          parent._id,
                                          row._id,
                                          recIndex,
                                        )
                                      }
                                    >
                                      <Trash />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody> */}
                            <tbody>
                              {row.recommendations.length === 0 ? (
                                // Show this div if there are no recommendations
                                <tr>
                                  <td
                                    colSpan="6"
                                    style={{
                                      textAlign: "center",
                                      padding: "20px",
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <p
                                        style={{
                                          fontWeight: "bold",
                                          marginTop: "unset",
                                          marginBottom: "unset",
                                        }}
                                      >
                                        No Closeout Evidence available
                                      </p>
                                      <button
                                        onClick={() =>
                                          handleAddRecommendation(
                                            parent._id,
                                            row._id,
                                          )
                                        }
                                        style={{ marginTop: "0px !important" }}
                                      >
                                        <AddBold width={24} height={24} />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              ) : (
                                // Show this if there are recommendations
                                row.recommendations.map((rec, recIndex) => (
                                  <tr
                                    key={rec._id}
                                    className="recommendation-item"
                                  >
                                    <td>
                                      <b>{rec.no}</b>
                                    </td>
                                    <td style={{ width: "60%" }}>
                                      <MyTextInput
                                        name="note"
                                        value={rec.note}
                                        onChange={(e) =>
                                          handleInputChange(
                                            parent._id,
                                            row._id,
                                            rec._id,
                                            "note",
                                            e.target.value,
                                          )
                                        }
                                        customholder="Note"
                                      />
                                    </td>
                                    <td>
                                      <div style={{ position: "relative" }}>
                                        <DateSelector
                                          selectedDate={
                                            new Date(rec.documentDate)
                                          }
                                          onChange={(date) =>
                                            handleInputChange(
                                              parent._id,
                                              row._id,
                                              rec._id,
                                              "documentDate",
                                              date,
                                            )
                                          }
                                        />
                                      </div>
                                    </td>
                                    <td>
                                      <input
                                        type="file"
                                        className="compact-file-input"
                                        onChange={async (e) => {
                                          const file = e.target.files[0];
                                          if (file) {
                                            const formData = new FormData();
                                            formData.append("file", file);

                                            try {
                                              const response =
                                                await postFormData({
                                                  endpoint:
                                                    "vessel/upload-file",
                                                  data: formData,
                                                  token: cookies.t,
                                                });

                                              const s3Link =
                                                response?.data?.data
                                                  ?.s3UploadedLink;

                                              if (s3Link) {
                                                console.log(
                                                  "File uploaded successfully:",
                                                  s3Link,
                                                );
                                                handleInputChange(
                                                  parent._id,
                                                  row._id,
                                                  rec._id,
                                                  "file",
                                                  s3Link,
                                                );
                                              } else {
                                                console.error(
                                                  "Error: S3 link not returned",
                                                );
                                              }
                                            } catch (error) {
                                              console.error(
                                                "File upload failed:",
                                                error,
                                              );
                                            }
                                          }
                                        }}
                                      />
                                    </td>
                                    <td>
                                      <button
                                        className="download-button"
                                        style={{ marginTop: "10px" }}
                                        onClick={() => {
                                          if (!rec.file) {
                                            alert(
                                              "File not available for download.",
                                            );
                                            return;
                                          }

                                          const anchor =
                                            document.createElement("a");
                                          anchor.href = rec.file;
                                          anchor.download = rec.file
                                            .split("/")
                                            .pop();
                                          anchor.target = "_blank";
                                          anchor.click();
                                        }}
                                      >
                                        <DownloadBold
                                          color={rec.file ? "black" : "gray"}
                                        />
                                      </button>
                                    </td>
                                    <td>
                                      <button
                                        onClick={() =>
                                          confirmDeleteRecommendation(
                                            parent._id,
                                            row._id,
                                            recIndex,
                                          )
                                        }
                                      >
                                        <Trash />
                                      </button>
                                    </td>
                                  </tr>
                                ))
                              )}
                            </tbody>
                          </table>
                        )}
                      </div>
                    </td>
                  </tr>
                  {/* <tr>
                    <td colSpan="7">
                      <hr style={{ margin: "5px 0" }} />
                    </td>
                  </tr> */}
                </>
              ))}
            </tbody>
          </table>
          {/* <button
            onClick={() => handleAddRecommendationObject(parent._id)}
            style={{
              marginTop: "10px",
              backgroundColor: "#007bff",
              color: "#fff",
              borderRadius: "5px",
              padding: "5px 10px",
              cursor: "pointer",
            }}
          >
            Add Recommendation
          </button> */}
        </div>
      ))}

      {isModalActive && (
        <Modal
          onClose={() => setIsModalActive(false)}
          onClick={() =>
            handleDeleteRecommendation(
              deleteTarget.parentId,
              deleteTarget.rowId,
              deleteTarget.recIndex,
            )
          }
        >
          Are you sure you want to delete this recommendation?
        </Modal>
      )}

      <style jsx>{`
        .accordion-header1 {
          // display: flex;
          // justify-content: space-between;
          // align-items: center;
          // margin-bottom: 8px;
          background-color: #f5f5f5;
          // margin-top: 12px;
          // height: 50px;
          padding: 8px;
          border-radius: 5px;
        }
        .recommendation-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 10px;
        }
        .recommendation-table th,
        .recommendation-table td {
          padding: 8px;
          border: none;
          text-align: left;
        }
        .recommendation-table th {
          background-color: #f5f5f5;
          font-weight: bold;
        }
        .recommendations-list {
          display: flex;
          flex-direction: column;
        }
        .recommendation-item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin-bottom: 10px;
        }
        .recommendation-item span {
          flex: 1;
        }
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          color: white;
          border: none;
          border-radius: 40px;
          cursor: pointer;
          background: none;
        }
        .compact-file-input {
          height: 42px; /* Adjust this value to make it as compact as you need */
          padding: 11px 11px; /* Reduces padding for a more compact appearance */
          font-size: 14px; /* Adjust font size if needed */
          cursor: pointer;
        }
      `}</style>
    </div>
  );
};

export default AllRecommendation;
