const MultiImage = ({ color = "white" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width="18"
      height="18"
    >
      <path d="M12.01,7.77c1.01-1.01,2.66-1.02,3.68,0l1.04,1.04c.23,.23,.62,.23,.85,0l5.95-5.95c-.8-1.69-2.53-2.86-4.52-2.86H10c-2.76,0-5,2.24-5,5v6c0,1.07,.34,2.06,.91,2.87l6.1-6.1Zm-2.51-4.77c.83,0,1.5,.67,1.5,1.5s-.67,1.5-1.5,1.5-1.5-.67-1.5-1.5,.67-1.5,1.5-1.5Zm2.83,20.82l-8.67-2.41c-2.66-.74-4.22-3.5-3.48-6.16l1.61-5.78c.23-.82,.65-1.54,1.21-2.12v3.66c0,3.87,3.13,7,7,7h9.14l-.65,2.34c-.74,2.66-3.5,4.22-6.16,3.48ZM24,5.21v5.79c0,2.76-2.24,5-5,5H10c-.97,0-1.87-.28-2.64-.75l6.06-6.06c.23-.23,.62-.23,.85,0l1.04,1.04c.98,.98,2.69,.98,3.67,0l5.02-5.02Z" />
    </svg>
  );
};

export default MultiImage;
