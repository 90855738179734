// import React from "react";
// import { useLocation, Link } from "react-router-dom";
// import "./styles.scss";
// import { ArrowRightIcon } from "@mui/x-date-pickers";

// const Breadcrumb = ({
//   maxItems = 2,
//   labels,
//   excludeLastPath = false,
//   stepsToRemove,
// }) => {
//   const location = useLocation();
//   const pathnames = location.pathname.split("/").filter((x) => x);

//   // Exclude the last part of the path if excludeLastPath is true
//   const effectivePathnames = stepsToRemove
//     ? pathnames.slice(0, -stepsToRemove)
//     : excludeLastPath
//     ? pathnames.slice(0, -1)
//     : pathnames;

//   // Generate the full paths for all breadcrumbs
//   const fullPathnames = effectivePathnames.map((value, index) => ({
//     pathname: `/${effectivePathnames.slice(0, index + 1).join("/")}`,
//     label: value.charAt(0).toUpperCase() + value.slice(1),
//   }));

//   // Determine which pathnames to display
//   let displayedPathnames =
//     fullPathnames.length > maxItems
//       ? fullPathnames.slice(-maxItems)
//       : fullPathnames;

//   const modifiedPathnames = displayedPathnames.map((item, index) => ({
//     ...item,
//     label: labels[index] || item.label,
//   }));

//   return (
//     <nav aria-label="Breadcrumb" className="breadcrumb">
//       <ol style={{ listStyle: "none", padding: 0, margin: 0 }}>
//         {modifiedPathnames.map((item, index) => (
//           <li key={item.pathname} style={{ display: "inline" }}>
//             {index === modifiedPathnames.length - 1 ? (
//               <span className="breadcrumb-item">{item.label}</span>
//             ) : (
//               <>
//                 <Link to={item.pathname} className="breadcrumb-link">
//                   {item.label}
//                 </Link>{" "}
//                 <ArrowRightIcon />
//               </>
//             )}
//           </li>
//         ))}
//       </ol>
//     </nav>
//   );
// };

// export default Breadcrumb;

// import React from "react";
// import { useLocation, Link } from "react-router-dom";
// import "./styles.scss";
// import { ArrowRightIcon } from "@mui/x-date-pickers";

// const Breadcrumb = ({
//   maxItems = 3,
//   labels,
//   logos = [], // Array of SVG elements or JSX
//   excludeLastPath = false,
//   stepsToRemove,
// }) => {
//   const location = useLocation();
//   const pathnames = location.pathname.split("/").filter((x) => x);

//   // Exclude the last part of the path if excludeLastPath is true
//   const effectivePathnames = stepsToRemove
//     ? pathnames.slice(0, -stepsToRemove)
//     : excludeLastPath
//     ? pathnames.slice(0, -1)
//     : pathnames;

//   // Generate the full paths for all breadcrumbs
//   const fullPathnames = effectivePathnames.map((value, index) => ({
//     pathname: `/${effectivePathnames.slice(0, index + 1).join("/")}`,
//     label: value.charAt(0).toUpperCase() + value.slice(1),
//   }));

//   // Determine which pathnames to display
//   let displayedPathnames =
//     fullPathnames.length > maxItems
//       ? fullPathnames.slice(-maxItems)
//       : fullPathnames;

//   const modifiedPathnames = displayedPathnames.map((item, index) => ({
//     ...item,
//     label: labels[index] || item.label,
//     logo: logos[index] || null, // Add SVG for each breadcrumb item
//   }));

//   return (
//     <nav aria-label="Breadcrumb" className="breadcrumb">
//       <ol
//         style={{
//           listStyle: "none",
//           padding: 0,
//           margin: 0,
//           display: "flex",
//           alignItems: "center",
//         }}
//       >
//         {/* Render breadcrumb items with SVG logos */}
//         {modifiedPathnames.map((item, index) => (
//           <li
//             key={item.pathname}
//             style={{
//               display: "inline-flex",
//               alignItems: "center",
//               marginRight: "8px",
//             }}
//           >
//             {item.logo && (
//               <span
//                 style={{
//                   display: "inline-flex",
//                   alignItems: "center",
//                   marginRight: "4px",
//                 }}
//               >
//                 {item.logo}
//               </span>
//             )}
//             {index === modifiedPathnames.length - 1 ? (
//               <span className="breadcrumb-item">{item.label}</span>
//             ) : (
//               <>
//                 <Link to={item.pathname} className="breadcrumb-link">
//                   {item.label}
//                 </Link>{" "}
//                 <ArrowRightIcon />
//               </>
//             )}
//           </li>
//         ))}
//       </ol>
//     </nav>
//   );
// };

// export default Breadcrumb;

import React from "react";
import { Link } from "react-router-dom";
import "./styles.scss";
import { ArrowRightIcon } from "@mui/x-date-pickers";

const Breadcrumb = ({
  labels = [], // Array of breadcrumb labels
  logos = [], // Array of SVG or JSX for breadcrumb icons
  paths = [], // Array of full paths for each breadcrumb item
  excludeLastPath = false,
  stepsToRemove = 0,
}) => {
  // Handle exclusion of the last path or custom steps
  const effectivePaths = stepsToRemove
    ? paths.slice(0, -stepsToRemove)
    : excludeLastPath
    ? paths.slice(0, -1)
    : paths;

  // Combine labels, logos, and paths into one array
  const modifiedPathnames = effectivePaths.map((path, index) => ({
    pathname: path, // Use paths directly passed in props
    label: labels[index] || path.charAt(0).toUpperCase() + path.slice(1), // Default to the path if no label
    logo: logos[index] || null, // Default no logo if not provided
  }));

  return (
    <nav aria-label="Breadcrumb" className="breadcrumb">
      <ol
        style={{
          listStyle: "none",
          padding: 0,
          margin: 0,
          display: "flex",
          alignItems: "center",
        }}
      >
        {/* Render breadcrumb items with SVG logos */}
        {modifiedPathnames.map((item, index) => (
          <li
            key={item.pathname}
            style={{
              display: "inline-flex",
              alignItems: "center",
              marginRight: "8px",
            }}
          >
            {/* Render logo if provided */}
            {item.logo && (
              <span
                style={{
                  display: "inline-flex",
                  alignItems: "center",
                  marginRight: "10px",
                }}
              >
                {item.logo}
              </span>
            )}
            {/* Render breadcrumb text or link */}
            {index === modifiedPathnames.length - 1 ? (
              <span className="breadcrumb-item">{item.label}</span>
            ) : (
              <>
                <Link to={item.pathname} className="breadcrumb-link">
                  {item.label}
                </Link>{" "}
                <ArrowRightIcon />
              </>
            )}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default Breadcrumb;
