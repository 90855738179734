// import { useEffect, useState } from "react";
// import InfoCard from "../../../atoms/InfoCard";
// import AdminIcon from "../../../icons/admin";
// import Business from "../../../icons/Business";
// import { getData } from "../../../../services";
// import { useCookies } from "react-cookie";
// import PageCard from "../../../atoms/PageCard";
// import Search from "../../../molecules/search";
// import FilterComponent from "../../../molecules/filters/FilterComponent";
// import CustomButton from "../../../atoms/CustomButton";
// import { useNavigate } from "react-router-dom";
// import NoContentIcon from "../../../icons/NocontentIcon";
// import CustomDatagrid from "../../../molecules/CustomDatagrid";
// import Modal from "../../../organisms/modals/Modal";
// import TrialDetails from "./AddTrials/trialsDetails";

// const Trials = () => {
//   const [infoCardData, setInfoCardData] = useState();
//   const [cookies] = useCookies(["t", "role"]);
//   const navigate = useNavigate();
//   const [isModalOpen, setIsModalOpen] = useState(false); // Modal state

//   const [trialDetails, setTrialDetails] = useState({
//     trials: "",
//     vesselName: "",
//     rollingScheme: false,
//     type: "",
//     startDate: null,
//     endDate: null,
//   });
//   const getDashBoardInfoCardData = async () => {
//     try {
//       const res = await getData({
//         endpoint: "getAllDashboard",
//         token: cookies.t,
//       });
//       setInfoCardData(res?.data);
//     } catch (err) {
//       console.log("err", err);
//     }
//   };

//   useEffect(() => {
//     getDashBoardInfoCardData();
//   }, []);

//   const handleOpenModal = () => {
//     setIsModalOpen(true); // Open modal
//   };

//   const handleCloseModal = () => {
//     setIsModalOpen(false); // Close modal
//   };

//   return (
//     <>
//       <div className="header-infoCardWrap info_card_mbr">
//         <div className="infoCardWrap">
//           <InfoCard
//             color="#B1E5FC"
//             title="Total Surveys"
//             data={infoCardData ? infoCardData?.surveyData : 0}
//             svgIcon={<Business />}
//             isSvg
//           />
//           <InfoCard
//             color="#B1E5FC"
//             title="Total Users"
//             data={infoCardData ? infoCardData?.userData : 0}
//             svgIcon={<AdminIcon />}
//             isSvg
//           />
//         </div>
//       </div>
//       <PageCard>
//         <div className="d-flex space-between gap-3-mbr">
//           <Search onSearchEmit={(value) => {}} />
//           <div className="filter-add">
//             <FilterComponent
//               filterData={[]}
//               isDateRange
//               dateRangeNames={["Survey Date", "Create Date"]}
//               onFilterChange={(filter) => {}}
//             />
//             {atob(cookies.role) === "Admin" && (
//               <div className="d-flex gap-3">
//                 <CustomButton
//                   type="btn-primary"
//                   text="Add Trial"
//                   handleClick={handleOpenModal} // Open modal on button click
//                   classes={"survery-btn-none-mbr"}
//                 />
//               </div>
//             )}
//           </div>
//         </div>

//         <CustomDatagrid
//           getRowId={(row) => row._id}
//           rows={[]}
//           columns={[]}
//           tHeight="50vh"
//         />

//         <div
//           style={{
//             display: "flex",
//             justifyContent: "center",
//             alignItems: "center",
//             height: "50vh",
//           }}
//         >
//           <div>
//             <NoContentIcon />
//             <div
//               className="graph-heading"
//               style={{
//                 fontSize: "18px",
//                 marginTop: "20px",
//                 textAlign: "center",
//               }}
//             >
//               No Records Found
//             </div>
//           </div>
//         </div>
//       </PageCard>

//       {/* Modal for Adding Trials */}
//       {isModalOpen && (
//         <Modal
//           id="trial-modal"
//           title="Add Trial"
//           isActive={isModalOpen}
//           onClose={handleCloseModal}
//           width="800px"
//         >
//           <TrialDetails
//             modal={true}
//             trialDetails={trialDetails}
//             setTrialDetails={setTrialDetails}
//           />
//         </Modal>
//       )}
//     </>
//   );
// };

// export default Trials;

import { useCallback, useEffect, useState } from "react";
import InfoCard from "../../../atoms/InfoCard";
import AdminIcon from "../../../icons/admin";
import Business from "../../../icons/Business";
import { deleteData, getData } from "../../../../services";
import { useCookies } from "react-cookie";
import PageCard from "../../../atoms/PageCard";
import Search from "../../../molecules/search";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import CustomButton from "../../../atoms/CustomButton";
import { useNavigate } from "react-router-dom";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import PageHeader from "../../../atoms/PageHeader";
import Pagination from "../../../molecules/Pagination";
import moment from "moment";
import Delete from "../../../icons/Delete";
import DeleteModal from "../../../organisms/modals/DeleteModal";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import TrialDetails from "./AddTrials/trialsDetails";
import Modal from "../../../organisms/modals/Modal";
import Trash from "../../../icons/Trash";
import NoContentIcon from "../../../icons/NocontentIcon";

const Trials = () => {
  const [infoCardData, setInfoCardData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchedValue, setSearchedValue] = useState("");
  const [data, setData] = useState([]);
  const [cookies] = useCookies(["t", "role"]);
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalData: null,
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });
  const [isTrialModalOpen, setIsTrialModalOpen] = useState(false); // Modal state

  const [trialDetails, setTrialDetails] = useState({
    trials: "",
    vesselName: "",
    rollingScheme: false,
    type: "",
    startDate: null,
    endDate: null,
  });

  const navigate = useNavigate();

  console.log("modalState", modalState);

  // Fetch dashboard info card data
  const getDashBoardInfoCardData = async () => {
    try {
      const res = await getData({
        endpoint: "getAllDashboard",
        token: cookies.t,
      });
      setInfoCardData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  const handleOpenTrialModal = () => {
    navigate(`/dashboard/Trial/add`);
  };

  const handleCloseModal = () => {
    setIsTrialModalOpen(false); // Close modal
  };

  // Fetch vessel data
  const fetchVesselTrialData = async () => {
    try {
      const res = await getData({
        endpoint: "vesselTrial/getAll",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchedValue,
        },
      });

      if (res) {
        console.log("res----->", res);
      }
      setData(res?.data || []);
    } catch (err) {
      console.log("Error fetching vessel data:", err);
      setData([]); // Ensure vesselData is empty on error
    }
  };

  const pageCount = data?.pageDetails?.count;

  useEffect(() => {
    getDashBoardInfoCardData();
    fetchVesselTrialData();
  }, [itemsPerPage, currentPage, searchedValue]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  console.log("data?.vesselData?.length", data?.vesselData?.length);

  const handleDeletion = async () => {
    try {
      const res = await deleteData({
        endpoint: `vesselTrial/delete`,
        params: {
          id: modalState.modalId, // Correct user_id being used here
        },
        token: cookies.t,
      });
      if (res?.data?.statusCode === 200) {
        toast.success("Vessel Trial Deleted Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
      } else {
        toast.error(res?.data?.message, { style: errorStyles, duration: 2000 });
      }
      fetchVesselTrialData(); // Refresh the data after successful deletion
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteModal = (vessel_trial_id, name) => {
    console.log("vessel_id", vessel_trial_id);
    console.log("name", name);
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: vessel_trial_id, // Correct user_id being set here
      name: name,
    }));
  };

  const columns = [
    { field: "trials", headerName: "Trials", flex: 2.5 },
    { field: "vessel_name", headerName: "Vessel", flex: 1.5 },
    // { field: "trials", headerName: "Status", flex: 1 },
    { field: "type", headerName: "Type", flex: 1 },
    {
      field: "is_rolling_scheme",
      headerName: "Rolling Scheme",
      flex: 1,
      renderCell: (params) => <span>{params.value ? "Yes" : "No"}</span>,
    },

    {
      field: "start_date",
      headerName: "Start Date",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.start_date).format("DD-MM-YYYY")}</span>
          </>
        );
      },
    },
    {
      field: "end_date",
      headerName: "End Date",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.end_date).format("DD-MM-YYYY")}</span>
          </>
        );
      },
    },

    {
      field: "actions",
      headerName: "Actions",
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span
              className="me-2 pointer"
              onClick={() =>
                handleDeleteModal(params.row._id, params.row.trials)
              }
            >
              {/* <Delete color="black" /> */}
              <Trash />
            </span>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="header-infoCardWrap info_card_mbr">
        {modalState.isDeleteModalOpen && (
          <DeleteModal
            name={modalState.name}
            id={modalState.modalId}
            isActive={modalState.isDeleteModalOpen}
            onClose={() =>
              setModalState((prevState) => ({
                ...prevState,
                isDeleteModalOpen: false,
              }))
            }
            title="Vessel Trial"
            onClick={handleDeletion}
          />
        )}
        {/* <div className="infoCardWrap">
          <InfoCard
            color="#B1E5FC"
            title="Total Vessels"
            data={infoCardData ? infoCardData?.surveyData : 0}
            svgIcon={<Business />}
            isSvg
          />
          <InfoCard
            color="#B1E5FC"
            title="Total Users"
            data={infoCardData ? infoCardData?.userData : 0}
            svgIcon={<AdminIcon />}
            isSvg
          />
        </div> */}
      </div>
      <PageHeader title="Trials" type="large" />
      <PageCard>
        {/* <PageHeader title="Trials" type="small" /> */}
        <div className="d-flex space-between gap-3-mbr">
          <Search
            // onSearchEmit={(value) => {
            //   // Implement search functionality if needed
            // }}
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
          />
          <div className="filter-add" style={{ marginBottom: "20px" }}>
            <FilterComponent
              filterData={[]}
              isDateRange
              dateRangeNames={["Survey Date", "Create Date"]}
              onFilterChange={(filter) => {
                // Implement filter functionality if needed
              }}
            />
            {atob(cookies.role) === "Admin" && (
              <div className="d-flex gap-3">
                <CustomButton
                  type="btn-primary"
                  text="Add Trial"
                  handleClick={() => handleOpenTrialModal()} // Open modal on button click
                  classes={"survery-btn-none-mbr"}
                />
              </div>
            )}
          </div>
        </div>

        {data && data?.trialsData && data?.trialsData?.length > 0 ? (
          <>
            <CustomDatagrid
              getRowId={(row) => row._id}
              rows={data?.trialsData}
              onCellClick={(data) => {
                const clickedColumnField = data?.field;
                console.log("clickedColumnField", data);
                const skipColumnField = "actions";

                if (clickedColumnField !== skipColumnField) {
                  navigate(
                    `/dashboard/Trial/${data?.row.vessel_id}/${data?.row._id}`,
                    {
                      state: {},
                    },
                  );
                } else {
                  console.log("Skipping navigation for action column");
                }
              }}
              columns={columns}
              tHeight="50vh"
            />
            {pageCount && pageCount > 10 && (
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={pageCount}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <div>
              <NoContentIcon />
              {/* <img
                src={NoContentIcon}
                alt=""
                style={{ height: "150px", width: "250px" }}
              /> */}
              <div
                className="graph-heading"
                style={{
                  fontSize: "18px",
                  marginTop: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                No Records Found
              </div>
            </div>
          </div>
        )}
        {/* Modal for Adding Trials */}
        {/* {isTrialModalOpen && (
          <Modal
            id="trial-modal"
            title="Add Trial"
            isActive={isTrialModalOpen}
            onClose={handleCloseModal}
            width="800px"
          > */}
        {/* <TrialDetails
          modal={true}
          trialDetails={trialDetails}
          setTrialDetails={setTrialDetails}
          setIsTrialModalOpen={setIsTrialModalOpen}
          fetchVesselTrialData={fetchVesselTrialData}
        /> */}
        {/* </Modal>
        )} */}
      </PageCard>
    </>
  );
};

export default Trials;
