// import React, { useRef, useState, useEffect } from "react";

// const CameraCapture = () => {
//   const [isCameraActive, setIsCameraActive] = useState(false);
//   const [capturedImage, setCapturedImage] = useState(null);
//   const videoRef = useRef(null); // Reference for the video element
//   const canvasRef = useRef(null); // Reference for the canvas element

//   // Request camera access
//   useEffect(() => {
//     if (isCameraActive) {
//       navigator.mediaDevices
//         .getUserMedia({ video: true })
//         .then((stream) => {
//           if (videoRef.current) {
//             videoRef.current.srcObject = stream; // Set the video source to the stream
//           }
//         })
//         .catch((error) => {
//           console.error("Error accessing the camera: ", error);
//         });
//     }

//     return () => {
//       // Cleanup and stop camera when component unmounts or camera is deactivated
//       if (videoRef.current && videoRef.current.srcObject) {
//         const stream = videoRef.current.srcObject;
//         const tracks = stream.getTracks();
//         tracks.forEach((track) => track.stop()); // Stop all video tracks
//       }
//     };
//   }, [isCameraActive]);

//   const captureImage = () => {
//     const canvas = canvasRef.current;
//     const video = videoRef.current;
//     if (canvas && video) {
//       const context = canvas.getContext("2d");
//       canvas.width = video.videoWidth;
//       canvas.height = video.videoHeight;
//       context.drawImage(video, 0, 0, canvas.width, canvas.height); // Draw the video frame on the canvas
//       setCapturedImage(canvas.toDataURL("image/png")); // Convert canvas to image data URL
//     }
//   };

//   const toggleCamera = () => {
//     setIsCameraActive((prev) => !prev); // Toggle camera on/off
//   };

//   return (
//     <div>
//       <div>
//         {!isCameraActive ? (
//           <button onClick={toggleCamera}>Open Camera</button>
//         ) : (
//           <button onClick={toggleCamera}>Close Camera</button>
//         )}
//       </div>

//       {isCameraActive && (
//         <div>
//           <video
//             ref={videoRef}
//             autoPlay
//             playsInline
//             style={{
//               width: "100%",
//               border: "1px solid #ddd",
//               borderRadius: "4px",
//             }}
//           />
//           <button onClick={captureImage}>Capture Image</button>
//         </div>
//       )}

//       {capturedImage && (
//         <div>
//           <h3>Captured Image</h3>
//           <img src={capturedImage} alt="Captured" style={{ width: "100%" }} />
//         </div>
//       )}

//       <canvas ref={canvasRef} style={{ display: "none" }} />
//     </div>
//   );
// };

// export default CameraCapture;

// import React, { useRef, useEffect, useState } from "react";
// import CustomButton from "../../../../atoms/CustomButton";

// const CameraCapture = ({ capturedImage, setCapturedImage }) => {
//   const videoRef = useRef(null); // Reference for the video element
//   const canvasRef = useRef(null); // Reference for the canvas element

//   useEffect(() => {
//     // Automatically open camera when component mounts
//     navigator.mediaDevices
//       .getUserMedia({ video: true })
//       .then((stream) => {
//         if (videoRef.current) {
//           videoRef.current.srcObject = stream; // Set the video source to the stream
//         }
//       })
//       .catch((error) => {
//         console.error("Error accessing the camera: ", error);
//       });

//     return () => {
//       // Cleanup and stop camera when component unmounts
//       if (videoRef.current && videoRef.current.srcObject) {
//         const stream = videoRef.current.srcObject;
//         const tracks = stream.getTracks();
//         tracks.forEach((track) => track.stop()); // Stop all video tracks
//       }
//     };
//   }, []);

//   const captureImage = () => {
//     const canvas = canvasRef.current;
//     const video = videoRef.current;
//     if (canvas && video) {
//       const context = canvas.getContext("2d");
//       canvas.width = video.videoWidth;
//       canvas.height = video.videoHeight;
//       context.drawImage(video, 0, 0, canvas.width, canvas.height); // Draw the video frame on the canvas
//       setCapturedImage(canvas.toDataURL("image/png")); // Convert canvas to image data URL
//     }
//   };

//   return (
//     <div>
//       <div>
//         <video
//           ref={videoRef}
//           autoPlay
//           playsInline
//           style={{
//             width: "100%",
//             border: "1px solid #ddd",
//             borderRadius: "4px",
//           }}
//         />
//         {/* <button style={{ border: "none" }} onClick={captureImage}>
//           Capture Image
//         </button> */}
//         <CustomButton
//           text="Capture Image"
//           handleClick={captureImage}
//           iconRequired={false}
//           //   icon={<Add />}
//           //   classes={`px-2.67rem  ${classes} mt-0`}
//           type="btn-primary"
//           //   disabled={disabled}
//         />
//       </div>

//       {capturedImage && (
//         <div>
//           <h3
//             style={{
//               fontSize: "20px",
//               fontWeight: "bold",
//               marginTop: "10px",
//               marginBottom: "10px",
//             }}
//           >
//             Captured Image
//           </h3>
//           <img src={capturedImage} alt="Captured" style={{ width: "100%" }} />
//         </div>
//       )}

//       <canvas ref={canvasRef} style={{ display: "none" }} />
//     </div>
//   );
// };

// export default CameraCapture;
// import React, { useRef, useEffect, useState } from "react";
// import CustomButton from "../../../../atoms/CustomButton";

// const CameraCapture = ({ capturedImage, setCapturedImage }) => {
//   const videoRef = useRef(null); // Reference for the video element
//   const canvasRef = useRef(null); // Reference for the canvas element
//   const [error, setError] = useState(null); // State to store any camera-related errors

//   useEffect(() => {
//     // Automatically open camera when component mounts
//     navigator.mediaDevices
//       .getUserMedia({ video: true })
//       .then((stream) => {
//         if (videoRef.current) {
//           videoRef.current.srcObject = stream; // Set the video source to the stream
//         }
//       })
//       .catch((err) => {
//         console.error("Error accessing the camera: ", err);
//         setError("Unable to access the camera. Please check permissions."); // Display error message
//       });

//     return () => {
//       // Cleanup and stop camera when component unmounts
//       if (videoRef.current && videoRef.current.srcObject) {
//         const stream = videoRef.current.srcObject;
//         const tracks = stream.getTracks();
//         tracks.forEach((track) => track.stop()); // Stop all video tracks
//       }
//     };
//   }, []);

//   const captureImage = () => {
//     const canvas = canvasRef.current;
//     const video = videoRef.current;
//     if (canvas && video) {
//       const context = canvas.getContext("2d");
//       canvas.width = video.videoWidth;
//       canvas.height = video.videoHeight;
//       context.drawImage(video, 0, 0, canvas.width, canvas.height); // Draw the video frame on the canvas
//       setCapturedImage(canvas.toDataURL("image/png")); // Convert canvas to image data URL
//     }
//   };

//   return (
//     <div>
//       {error && <p style={{ color: "red" }}>{error}</p>}{" "}
//       {/* Show error message if there's an issue */}
//       <div>
//         <video
//           ref={videoRef}
//           autoPlay
//           playsInline
//           style={{
//             width: "100%",
//             border: "1px solid #ddd",
//             borderRadius: "4px",
//           }}
//         />
//         <CustomButton
//           text="Capture Image"
//           handleClick={captureImage}
//           iconRequired={false}
//           type="btn-primary"
//         />
//       </div>
//       {capturedImage && (
//         <div>
//           <h3
//             style={{
//               fontSize: "20px",
//               fontWeight: "bold",
//               marginTop: "10px",
//               marginBottom: "10px",
//             }}
//           >
//             Captured Image
//           </h3>
//           <img src={capturedImage} alt="Captured" style={{ width: "100%" }} />
//         </div>
//       )}
//       <canvas ref={canvasRef} style={{ display: "none" }} />
//     </div>
//   );
// };

// export default CameraCapture;

import React, { useRef, useEffect, useState } from "react";
import CustomButton from "../../../../atoms/CustomButton"; // Assuming CustomButton is your custom button component
import MyTextInput from "../../../../atoms/MyTextInput"; // Assuming MyTextInput is your custom text input component

const CameraCapture = ({
  capturedImage,
  setCapturedImage,
  note22,
  setNote22,
}) => {
  const videoRef = useRef(null); // Reference for the video element
  const canvasRef = useRef(null); // Reference for the canvas element
  const [error, setError] = useState(null); // State to store any camera-related errors

  useEffect(() => {
    // Automatically open camera when component mounts
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream; // Set the video source to the stream
        }
      })
      .catch((err) => {
        console.error("Error accessing the camera: ", err);
        setError("Unable to access the camera. Please check permissions."); // Display error message
      });

    return () => {
      // Cleanup and stop camera when component unmounts
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop()); // Stop all video tracks
      }
    };
  }, []);

  const captureImage = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    if (canvas && video) {
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height); // Draw the video frame on the canvas
      setCapturedImage(canvas.toDataURL("image/png")); // Convert canvas to image data URL
    }
  };

  const handleUpload = () => {
    // Handle the upload action (e.g., upload image to a server or database)
    console.log("Uploading Image with Note:", note22);
    // You can add your upload logic here
  };

  return (
    <div>
      {error && <p style={{ color: "red" }}>{error}</p>}{" "}
      {/* Show error message if there's an issue */}
      <div>
        <video
          ref={videoRef}
          autoPlay
          playsInline
          style={{
            width: "100%",
            border: "1px solid #ddd",
            borderRadius: "4px",
          }}
        />
        <CustomButton
          text="Capture Image"
          handleClick={captureImage}
          iconRequired={false}
          type="btn-primary"
        />
      </div>
      {capturedImage && (
        <div>
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Captured Image
          </h3>
          <img src={capturedImage} alt="Captured" style={{ width: "100%" }} />

          {/* MyTextInput for entering a note */}
          {/* <MyTextInput
            value={note}
            onChange={(e) => setNote(e.target.value)}
            placeholder="Enter your note here"
            style={{ marginTop: "10px" }}
          /> */}
          <MyTextInput
            type="text"
            name="note"
            customholder="Note"
            value={note22}
            onChange={(e) => setNote22(e.target.value)}
            style={{ width: "100%" }}
          />

          {/* Upload button */}
          <div style={{ height: "10px" }}></div>
          <CustomButton
            text="Upload"
            handleClick={handleUpload}
            iconRequired={false}
            type="btn-primary"
            style={{ marginTop: "10px" }}
          />
        </div>
      )}
      <canvas ref={canvasRef} style={{ display: "none" }} />
    </div>
  );
};

export default CameraCapture;
