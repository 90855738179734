const ProfileSettings = ({ width = "24", height = "24" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <path d="M3,6C3,2.691,5.691,0,9,0s6,2.691,6,6-2.691,6-6,6S3,9.309,3,6Zm18.724,12.567l1.841,1.06-.998,1.733-1.84-1.06c-.706,.796-1.646,1.378-2.726,1.598v2.101h-2v-2.101c-1.08-.22-2.021-.802-2.726-1.598l-1.84,1.06-.998-1.733,1.841-1.06c-.165-.496-.276-1.016-.276-1.567s.111-1.071,.276-1.567l-1.841-1.06,.998-1.733,1.84,1.06c.706-.796,1.646-1.378,2.726-1.598v-2.101h2v2.101c1.08,.22,2.021,.802,2.726,1.598l1.84-1.06,.998,1.733-1.841,1.06c.165,.496,.276,1.016,.276,1.567s-.111,1.071-.276,1.567Zm-3.224-1.567c0-.827-.673-1.5-1.5-1.5s-1.5,.673-1.5,1.5,.673,1.5,1.5,1.5,1.5-.673,1.5-1.5Zm-10.5,0c0-1.054,.19-2.06,.523-3h-3.523c-2.757,0-5,2.243-5,5v5H11.349c-2.041-1.65-3.349-4.171-3.349-7Z" />
    </svg>
  );
};

export default ProfileSettings;
