import React, { useState } from "react";
import FileSelector from "../../../../atoms/FileSelector/FileSelector"; // Updated for generic file selection
import { postFormData } from "../../../../../services";
import { useCookies } from "react-cookie";
import MyTextInput from "../../../../atoms/MyTextInput";

const FileSelectionComponent = ({
  selectedFile,
  setSelectedFile,
  fileNote,
  setFileNote,
  onSave, // Callback to save the note and file
}) => {
  const [cookies] = useCookies(["t"]);
  const [isLoading, setIsLoading] = useState(false);
  const [tempFile, setTempFile] = useState(selectedFile); // Temporary state for file
  const [tempNote, setTempNote] = useState(fileNote); // Temporary state for note

  const handleFileSelection = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      setIsLoading(true);
      const response = await postFormData({
        endpoint: "vessel/upload-file", // Update endpoint if needed
        data: formData,
        token: cookies.t,
      });

      const s3Link = response?.data?.data?.s3UploadedLink;
      if (s3Link) {
        setTempFile(s3Link); // Update tempFile with S3 link
      }
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = () => {
    setSelectedFile(tempFile);
    setFileNote(tempNote);
    if (onSave) onSave(tempFile, tempNote); // Notify parent if a callback exists
  };

  return (
    <div className="file-upload">
      <FileSelector
        onFileSelect={handleFileSelection} // Updated to handle file selection
        s3FileLink={tempFile}
        onRemoveFile={() => {
          setTempFile(null);
          setTempNote(""); // Clear tempNote when the file is removed
        }}
      />
      {tempFile && (
        <div style={{ marginTop: "10px" }}>
          <MyTextInput
            type="text"
            name="note"
            customholder="Note"
            value={tempNote}
            onChange={(e) => setTempNote(e.target.value)}
            style={{ width: "100%" }}
          />
        </div>
      )}
      <div style={{ height: "10px" }}></div>

      {tempFile && (
        <button
          onClick={handleSave}
          style={{
            ...styles.submitButton,
          }}
        >
          Upload
        </button>
      )}
    </div>
  );
};

const styles = {
  submitButton: {
    marginTop: "16px",
    padding: "8px 16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default FileSelectionComponent;
