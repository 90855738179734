import React, { useState } from "react";
import Delete from "../../../../icons/Delete";
import Add from "../../../../icons/Add";
import AddBold from "../../../../icons/AddBold";
import Trash from "../../../../icons/Trash";
import Modal from "../../../../organisms/modals/DeleteModal";
import MyTextInput from "../../../../atoms/MyTextInput";
import DownloadBold from "../../../../icons/DownloadBold";
import View from "../../../../icons/View";

const SupportingMediaListing = ({
  supportingMediaRows,
  setSupportingMediaRows,
}) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  console.log("supportingMediaRows---->", supportingMediaRows);

  const handleDeleteRow = (index) => {
    const newRows = supportingMediaRows.filter(
      (_, rowIndex) => rowIndex !== index,
    );
    setSupportingMediaRows(newRows);
    setIsModalActive(false); // Close modal after deletion
  };

  // Function to open the modal and set the selected row for deletion
  const confirmDeleteRow = (index) => {
    setSelectedRowIndex(index);
    setIsModalActive(true); // Show modal
  };

  const handleChange = (index, field, value) => {
    const newRows = [...supportingMediaRows];
    newRows[index][field] = value;
    setSupportingMediaRows(newRows);
  };

  const extractFileName = (fileUrl) => {
    return decodeURIComponent(
      fileUrl?.substring(fileUrl?.lastIndexOf("/") + 1),
    );
  };

  // const handleView = (fileLink) => {
  //   window.open(fileLink, "_blank"); // Open the file in a new tab
  // };

  const handleDownload = (fileLink) => {
    const link = document.createElement("a");
    link.href = fileLink;
    link.download = extractFileName(fileLink); // Suggest the filename
    link.click(); // Trigger download
  };

  return (
    <div style={{ overflowX: "scroll" }}>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th style={{ width: "5%" }}>No.</th>
            <th style={{ width: "40%" }}>Note</th>
            <th style={{ width: "40%" }}>Filename</th>
            {/* <th>View</th> */}
            <th style={{ width: "10%" }}>Download</th>
          </tr>
        </thead>
        <tbody>
          {supportingMediaRows?.map((row, index) => (
            <tr key={index}>
              <td>
                <b>{`# ${index + 1}`}</b>
              </td>
              <td>
                <MyTextInput
                  type="text"
                  name="note"
                  customholder="Note"
                  value={row.note}
                  onChange={(e) => handleChange(index, "note", e.target.value)}
                />
              </td>
              <td>
                <MyTextInput
                  type="text"
                  name="filename"
                  customholder="Filename"
                  value={extractFileName(row.file_link)}
                  onChange={(e) =>
                    handleChange(index, "filename", e.target.value)
                  }
                  disabled={true}
                />
              </td>
              {/* <td>
                <View />
              </td> */}
              {/* <td>
                <button
                  className="view-button"
                  onClick={() => handleView(row.file_link)}
                >
                  <View />
                </button>
              </td> */}
              <td>
                {/* <DownloadBold color="black" /> */}
                <button
                  className="download-button"
                  onClick={() => handleDownload(row.file_link)}
                >
                  <DownloadBold color="black" />
                </button>
              </td>

              <td>
                <button
                  className="delete-button"
                  onClick={() => confirmDeleteRow(index)}
                >
                  <Trash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {isModalActive && (
        <Modal
          isActive={isModalActive}
          onClose={() => setIsModalActive(false)} // Close modal without deleting
          title="Row"
          name={`Row ${selectedRowIndex + 1}`}
          onClick={() => handleDeleteRow(selectedRowIndex)} // Delete row when Confirm is clicked
          description="Are you sure you want to delete this row?"
        />
      )}

      {/* CSS Styles */}
      <style jsx>{`
        table {
          width: 100%;
          border-collapse: collapse;
        }
        th,
        td {
          padding: 10px;
          text-align: left;
          border: none;
        }
        input {
          border: 1px solid #ccc;
          padding: 5px;
          width: 100%;
          box-sizing: border-box;
        }
        button {
          display: flex; /* Enables flexbox */
          justify-content: center; /* Centers content horizontally */
          align-items: center; /* Centers content vertically */
          margin-top: 10px;
          //   padding: 10px 10px;
          //   background-color: #007bff;
          color: white;
          border: none;
          border-radius: 40px;
          cursor: pointer;
          background: none;
        }
        // button:hover {
        //   background-color: #0056b3;
        // }

        .delete-button {
          background: none;
          border: none;
          cursor: pointer;
          color: red; /* or your desired color */
          padding: 0;
          margin: 0;
        }

        .delete-button:hover {
          color: darkred; /* Change color on hover */
        }
      `}</style>
    </div>
  );
};

export default SupportingMediaListing;
