import React, { useState } from "react";
import Delete from "../../../../icons/Delete";
import Add from "../../../../icons/Add";
import AddBold from "../../../../icons/AddBold";
import Trash from "../../../../icons/Trash";
import Modal from "../../../../organisms/modals/DeleteModal";
import MyTextInput from "../../../../atoms/MyTextInput";

const KeyDpPersonnel = ({ dpPersonnelRows, setDpPersonnelRows }) => {
  const [isModalActive, setIsModalActive] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const handleAddRow = () => {
    setDpPersonnelRows([
      ...dpPersonnelRows,
      {
        name: "",
        position: "",
        company: "",
        certificate: "",
        issued_by: "",
        remarks: "",
      },
    ]);
  };

  console.log("dpPersonnelRows---->", dpPersonnelRows);

  const handleDeleteRow = (index) => {
    const newRows = dpPersonnelRows.filter((_, rowIndex) => rowIndex !== index);
    setDpPersonnelRows(newRows);
    setIsModalActive(false); // Close modal after deletion
  };

  // Function to open the modal and set the selected row for deletion
  const confirmDeleteRow = (index) => {
    setSelectedRowIndex(index);
    setIsModalActive(true); // Show modal
  };

  const handleChange = (index, field, value) => {
    const newRows = [...dpPersonnelRows];
    newRows[index][field] = value;
    setDpPersonnelRows(newRows);
  };

  return (
    <div style={{ overflowX: "scroll" }}>
      <table
        style={{
          width: "100%",
          borderCollapse: "collapse",
        }}
      >
        <thead>
          <tr>
            <th>No.</th>
            <th>Name</th>
            <th>Position</th>
            <th>Company</th>
            <th>Certificate</th>
            <th>Issued By</th>
            <th>Remarks</th>
            {/* <th>Actions</th> */}
          </tr>
        </thead>
        <tbody>
          {dpPersonnelRows.map((row, index) => (
            <tr key={index}>
              <td>
                <b>{`# ${index + 1}`}</b>
              </td>
              <td>
                <input
                  type="text"
                  value={row.name}
                  onChange={(e) => handleChange(index, "name", e.target.value)}
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.position}
                  onChange={(e) =>
                    handleChange(index, "position", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.company}
                  onChange={(e) =>
                    handleChange(index, "company", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.certificate}
                  onChange={(e) =>
                    handleChange(index, "certificate", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.issued_by}
                  onChange={(e) =>
                    handleChange(index, "issued_by", e.target.value)
                  }
                />
              </td>
              <td>
                <input
                  type="text"
                  value={row.remarks}
                  onChange={(e) =>
                    handleChange(index, "remarks", e.target.value)
                  }
                />
              </td>

              <td>
                <button
                  className="delete-button"
                  onClick={() => confirmDeleteRow(index)}
                >
                  <Trash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleAddRow}>
        <AddBold width="24" height="24" />
      </button>

      {isModalActive && (
        <Modal
          isActive={isModalActive}
          onClose={() => setIsModalActive(false)} // Close modal without deleting
          title="Row"
          name={`Row ${selectedRowIndex + 1}`}
          onClick={() => handleDeleteRow(selectedRowIndex)} // Delete row when Confirm is clicked
          description="Are you sure you want to delete this row?"
        />
      )}

      {/* CSS Styles */}
      <style jsx>{`
        table {
          width: 100%;
          border-collapse: collapse;
        }
        th,
        td {
          padding: 10px;
          text-align: left;
          border: none;
        }
        input {
          border: 1px solid #ccc;
          padding: 5px;
          width: 100%;
          box-sizing: border-box;
        }
        button {
          display: flex; /* Enables flexbox */
          justify-content: center; /* Centers content horizontally */
          align-items: center; /* Centers content vertically */
          margin-top: 10px;
          //   padding: 10px 10px;
          //   background-color: #007bff;
          color: white;
          border: none;
          border-radius: 40px;
          cursor: pointer;
          background: none;
        }
        // button:hover {
        //   background-color: #0056b3;
        // }

        .delete-button {
          background: none;
          border: none;
          cursor: pointer;
          color: red; /* or your desired color */
          padding: 0;
          margin: 0;
        }

        .delete-button:hover {
          color: darkred; /* Change color on hover */
        }
      `}</style>
    </div>
  );
};

export default KeyDpPersonnel;
