import React, { useState } from "react";
import AddBold from "../../../../icons/AddBold";
import Trash from "../../../../icons/Trash";
import Modal from "../../../../organisms/modals/DeleteModal";
import MyTextInput from "../../../../atoms/MyTextInput";
import MyDropdown from "../../../../atoms/MyDropdown";
import { DateSelector } from "../../../../molecules/MonthYearSelector";
import ArrowDown from "../../../../icons/ArrowDown";
import DownloadBold from "../../../../icons/DownloadBold";
import ArrowUp from "../../../../icons/ArrowUp";
import { postFormData } from "../../../../../services";
import { useCookies } from "react-cookie";

const Recommendation = ({
  recommendationRows,
  setRecommendationRows,
  filterClosed,
}) => {
  console.log("rows---->", recommendationRows);
  const [cookies] = useCookies(["t"]);

  const [isModalActive, setIsModalActive] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const [selectedRecIndex, setSelectedRecIndex] = useState(null);
  const [deleteTarget, setDeleteTarget] = useState(""); // Track which delete button was clicked (row or recommendation)
  const [openAccordion, setOpenAccordion] = useState({});

  const handleAddRow = () => {
    setRecommendationRows([
      ...recommendationRows,
      {
        sno: recommendationRows.length + 1,
        number: "",
        description: "",
        type: "",
        status: "Opened",
        recommendations: [],
      },
    ]);
  };

  const handleDeleteRow = (index) => {
    const newRows = recommendationRows.filter(
      (_, rowIndex) => rowIndex !== index,
    );
    setRecommendationRows(
      newRows.map((row, idx) => ({ ...row, sno: idx + 1 })),
    );
    setIsModalActive(false);
    setDeleteTarget(""); // Reset the delete target
  };

  const handleDeleteRecommendation = () => {
    const rowIndex = selectedRowIndex;
    const recIndex = selectedRecIndex;

    const newRows = [...recommendationRows];
    newRows[rowIndex].recommendations.splice(recIndex, 1);

    // Update numbering for recommendations after deletion
    newRows[rowIndex].recommendations = newRows[rowIndex].recommendations.map(
      (rec, idx) => ({
        ...rec,
        no: `#${rowIndex + 1}.${idx + 1}`,
      }),
    );

    setRecommendationRows(newRows);
    setIsModalActive(false);
    setDeleteTarget(""); // Reset the delete target
  };

  const confirmDeleteRow = (index) => {
    setSelectedRowIndex(index);
    setDeleteTarget("row"); // Set the delete target to row
    setIsModalActive(true);
  };

  const confirmDeleteRecommendation = (rowIndex, recIndex) => {
    setSelectedRowIndex(rowIndex);
    setSelectedRecIndex(recIndex);
    setDeleteTarget("recommendation"); // Set the delete target to recommendation
    setIsModalActive(true);
  };

  const handleInputChange = (index, field, value) => {
    const newRows = [...recommendationRows];
    newRows[index][field] = value;

    if (field === "type") {
      newRows[index].number = `${value} ${newRows[index].sno}`;
    }

    setRecommendationRows(newRows);
  };

  const handleAddRecommendation = (rowIndex) => {
    console.log("Hiii");
    const newRows = [...recommendationRows];
    const newRecommendation = {
      no: `#${rowIndex + 1}.${newRows[rowIndex].recommendations.length + 1}`,
      note: "",
      documentDate: "",
      file: null,
    };
    newRows[rowIndex].recommendations.push(newRecommendation);
    setRecommendationRows(newRows);
  };

  const handleRecommendationChange = (rowIndex, recIndex, field, value) => {
    const newRows = [...recommendationRows];
    newRows[rowIndex].recommendations[recIndex][field] = value;
    setRecommendationRows(newRows);
  };

  const toggleAccordion = (index) => {
    setOpenAccordion((prevState) => ({
      ...prevState,
      [index]: !prevState[index],
    }));
  };

  const filteredData = recommendationRows?.filter((item) => {
    if (filterClosed === true) {
      // Show both "Opened" and "Closed"
      return true;
    }
    // Show only "Opened" when filterClosed is undefined or false
    return item.status === "Opened";
  });

  return (
    <div className="recommendation-container">
      <table style={{ width: "100%" }}>
        <tbody>
          {recommendationRows?.map((row, rowIndex) => (
            // {filteredData?.map((row, rowIndex) => (
            <React.Fragment key={rowIndex}>
              <tr>
                <td style={{ padding: "10px", width: "4%" }}>
                  <b>#{row.sno}</b>
                </td>
                <td style={{ width: "10%" }}>
                  <MyTextInput
                    name="number"
                    customholder="Number"
                    value={row.number}
                    onChange={(e) =>
                      handleInputChange(rowIndex, "number", e.target.value)
                    }
                  />
                </td>
                <td style={{ padding: "10px", width: "60%" }}>
                  <MyTextInput
                    name="description"
                    customholder="Description"
                    value={row.description}
                    onChange={(e) =>
                      handleInputChange(rowIndex, "description", e.target.value)
                    }
                  />
                </td>
                <td style={{ padding: "10px", width: "10%" }}>
                  <MyDropdown
                    placeholder="Type"
                    data={[
                      { label: "A", value: "A" },
                      { label: "B", value: "B" },
                      { label: "C", value: "C" },
                    ]}
                    selectedValue={row.type}
                    onChange={(option) =>
                      handleInputChange(rowIndex, "type", option)
                    }
                  />
                </td>
                <td style={{ padding: "10px", width: "20%" }}>
                  <MyDropdown
                    placeholder="Status"
                    data={[
                      { label: "Opened", value: "Opened" },
                      { label: "Closed", value: "Closed" },
                    ]}
                    selectedValue={row.status}
                    onChange={(option) =>
                      handleInputChange(rowIndex, "status", option)
                    }
                  />
                </td>
                {/* <td style={{ padding: "10px", width: "20%" }}>
                  <a href="#" style={{ marginLeft: "10px" }}>
                    Related to test 93
                  </a>
                </td> */}
                <td style={{ padding: "10px" }}>
                  <button
                    onClick={() => confirmDeleteRow(rowIndex)}
                    className="delete-button"
                  >
                    <Trash />
                  </button>
                </td>
              </tr>

              <tr className="recommendation-accordion">
                <td colSpan="7">
                  <div className="accordion-header1">
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <div style={{ fontSize: "16px", fontWeight: "bold" }}>
                        Recommendation #{row.sno} Closeout Evidence
                      </div>
                      <button
                        onClick={() => {
                          console.log("Add Clicked");
                          handleAddRecommendation(rowIndex);
                        }}
                        style={{
                          width: "0px !important",
                          marginTop: "0px !important",
                        }}
                      >
                        <AddBold width="20" height="20" />
                      </button>
                    </div>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <button
                        onClick={() => toggleAccordion(rowIndex)}
                        className="accordion-toggle"
                      >
                        {openAccordion[rowIndex] ? <ArrowUp /> : <ArrowDown />}
                      </button>
                    </div>
                  </div>

                  {openAccordion[rowIndex] && (
                    <table
                      className="recommendation-content-table"
                      style={{ width: "100%" }}
                    >
                      <tbody>
                        {row.recommendations.length === 0 ? (
                          <tr>
                            <td
                              colSpan="6"
                              style={{ textAlign: "center", padding: "20px" }}
                            >
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                <p
                                  style={{
                                    fontWeight: "bold",
                                    marginTop: "unset",
                                    marginBottom: "unset",
                                  }}
                                >
                                  No Closeout Evidence available
                                </p>
                                <button
                                  style={{ marginTop: "0px !important" }}
                                  onClick={() =>
                                    handleAddRecommendation(rowIndex)
                                  }
                                >
                                  <AddBold width="24" height="24" />
                                </button>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          row.recommendations.map((rec, recIndex) => (
                            <tr key={recIndex}>
                              <td>
                                <b>{rec.no}</b>
                              </td>
                              <td style={{ width: "55%" }}>
                                <MyTextInput
                                  name="note"
                                  customholder="Note"
                                  value={rec.note}
                                  onChange={(e) =>
                                    handleRecommendationChange(
                                      rowIndex,
                                      recIndex,
                                      "note",
                                      e.target.value,
                                    )
                                  }
                                />
                              </td>
                              <td style={{ width: "25%" }}>
                                <div style={{ position: "relative" }}>
                                  <DateSelector
                                    selectedDate={rec.documentDate}
                                    onChange={(date) =>
                                      handleRecommendationChange(
                                        rowIndex,
                                        recIndex,
                                        "documentDate",
                                        date,
                                      )
                                    }
                                  />
                                </div>
                              </td>
                              <td style={{ width: "20%" }}>
                                <input
                                  type="file"
                                  className="compact-file-input"
                                  onChange={async (e) => {
                                    const file = e.target.files[0]; // Get the selected file
                                    if (file) {
                                      const formData = new FormData();
                                      formData.append("file", file);

                                      try {
                                        // Call the upload API
                                        const response = await postFormData({
                                          endpoint: "vessel/upload-file",
                                          data: formData,
                                          token: cookies.t, // Use your auth token
                                        });

                                        const s3Link =
                                          response?.data?.data?.s3UploadedLink;

                                        if (s3Link) {
                                          console.log(
                                            "File uploaded successfully:",
                                            s3Link,
                                          );
                                          // Update the document with the uploaded S3 link
                                          handleRecommendationChange(
                                            rowIndex,
                                            recIndex,
                                            "file",
                                            s3Link,
                                          );
                                        } else {
                                          console.error(
                                            "Error: S3 link not returned",
                                          );
                                        }
                                      } catch (error) {
                                        console.error(
                                          "File upload failed:",
                                          error,
                                        );
                                      }
                                    }
                                  }}
                                />
                              </td>

                              <td>
                                {/* <button
                                  className="download-button"
                                  style={{ marginTop: "10px" }}
                                >
                                  <DownloadBold color="black" />
                                </button> */}
                                <button
                                  className="download-button"
                                  style={{ marginTop: "10px" }}
                                  onClick={() => {
                                    if (!rec.file) {
                                      alert("File not available for download.");
                                      return;
                                    }

                                    const anchor = document.createElement("a");
                                    anchor.href = rec.file; // Assuming rec.file contains the S3 link
                                    anchor.download = rec.file.split("/").pop(); // Use the file name from the URL or specify one
                                    anchor.target = "_blank"; // This ensures it doesn't open in the same window
                                    anchor.click(); // Trigger the download
                                  }}
                                  disabled={!rec.file} // Disable the download button if there is no s3Link
                                >
                                  <DownloadBold
                                    color={rec.file ? "black" : "gray"}
                                  />
                                </button>
                              </td>
                              <td style={{ width: "10%" }}>
                                <button
                                  onClick={() =>
                                    confirmDeleteRecommendation(
                                      rowIndex,
                                      recIndex,
                                    )
                                  }
                                  style={{ marginTop: "10px" }}
                                  className="delete-button"
                                >
                                  <Trash />
                                </button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  )}
                </td>
              </tr>
              <tr>
                <td colSpan="7">
                  <hr style={{ margin: "10px 0" }} />
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
      <div style={{ display: "flex", marginTop: "20px" }}>
        {/* <CustomButton
          handleClick={handleAddRow}
          icon={<Add width="15" height="15" />}
        >
          Add Row
        </CustomButton> */}
        <button
          onClick={() => {
            console.log("I Clicked Add");
            handleAddRow();
          }}
        >
          <AddBold width="28" height="28" />
        </button>
      </div>
      {isModalActive && (
        <Modal
          isActive={isModalActive}
          onClose={() => setIsModalActive(false)}
          onClick={
            deleteTarget === "row"
              ? () => handleDeleteRow(selectedRowIndex)
              : handleDeleteRecommendation
          }
        />
      )}
      <style jsx>{`
        .recommendation-content-table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 10px;
        }

        .recommendation-content-table th,
        .recommendation-content-table td {
          padding: 8px;
          border: 1px solid #ddd;
          text-align: left;
        }

        .recommendation-content-table th {
          background-color: #f5f5f5;
          font-weight: bold;
        }
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          //   margin-top: 10px;
          color: white;
          border: none;
          border-radius: 40px;
          cursor: pointer;
          background: none;
        }

        .accordion-header1 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-bottom: 8px;
          background-color: #f5f5f5;
          margin-top: 12px;
          // height: 50px;
          padding: 8px;
          border-radius: 5px;
        }

        .download-button {
          color: black !important;
        }

        .delete-button {
          background-color: transparent;
          border: none;
          cursor: pointer;
        }
        .compact-file-input {
          height: 42px; /* Adjust this value to make it as compact as you need */
          padding: 11px 11px; /* Reduces padding for a more compact appearance */
          font-size: 14px; /* Adjust font size if needed */
          cursor: pointer;
        }

        .compact-file-input::-webkit-file-upload-button {
          padding: 2px 4px; /* Reduces padding on the upload button */
          font-size: 13px; /* Adjust the font size for the upload button text */
          background-color: #f5f5f5; /* Background color for the button */
          border: 1px solid #ddd;
          border-radius: 4px;
          cursor: pointer;
        }

        th,
        td {
          border: none !important;
        }

        .dp-sensor-table {
          width: 100%;
          border-collapse: collapse;
        }

        .table-header {
          padding: 10px;
          text-align: left;
          border: none;
        }

        .table-data {
          padding: 10px;
          text-align: left;
        }

        .input-text {
          border: 1px solid #ccc;
          padding: 5px;
          width: 100%;
          box-sizing: border-box;
        }

        .checkbox-input {
          transform: scale(1.4);
          text-align: left;
          border: 0.5px solid #ccc;
        }

        .add-row-button1 {
          display: flex;
          justify-content: center;
          align-items: center;
          // margin-top: 10px;
          color: white;
          border: none;
          border-radius: 40px;
          cursor: pointer;
          background: none;
        }

        .delete-button {
          background: none;
          border: none;
          cursor: pointer;
          color: red;
          padding: 0;
          margin: 0;
        }

        .delete-button:hover {
          color: darkred;
        }

        .remarks-container {
          margin-top: 15px;
        }
      `}</style>
    </div>
  );
};

export default Recommendation;
