import React from "react";

const Overview = ({ width = "18", height = "18" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <path d="m8,6.5c0,.828-.672,1.5-1.5,1.5s-1.5-.672-1.5-1.5.672-1.5,1.5-1.5,1.5.672,1.5,1.5Zm-1.5,8.5c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5,1.5-.672,1.5-1.5-.672-1.5-1.5-1.5Zm0-5c-.828,0-1.5.672-1.5,1.5s.672,1.5,1.5,1.5,1.5-.672,1.5-1.5-.672-1.5-1.5-1.5ZM20.5,0H3.5C1.57,0,0,1.57,0,3.5v19.5h10.275c-.962-1.038-1.551-2.075-1.817-2.613l-.191-.387H3V3.5c0-.276.224-.5.5-.5h17c.276,0,.5.224.5.5v10.432c1.276.64,2.267,1.52,3,2.372V3.5c0-1.93-1.57-3.5-3.5-3.5Zm-1.5,5h-9v3h9v-3Zm-9,8h9v-3h-9v3Zm13.75,6.5c-.577,1.165-2.592,4.5-6.75,4.5s-6.175-3.338-6.75-4.5c.577-1.165,2.592-4.5,6.75-4.5s6.173,3.334,6.75,4.5Zm-4.75,0c0-1.105-.895-2-2-2s-2,.895-2,2,.895,2,2,2,2-.895,2-2Z" />
    </svg>
  );
};

export default Overview;
