import React, { useEffect, useState } from "react";
import MyTextInput from "../../../../atoms/MyTextInput";
import CheckboxWrapper from "../../../../atoms/CheckBoxWrapper/Checkbox";
import MyDropdown from "../../../../atoms/MyDropdown";
import { getData, postData } from "../../../../../services";
import { useCookies } from "react-cookie";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";

const TestDetails = ({ testDetails, setTestDetails, type }) => {
  const [cookies] = useCookies(["t"]);

  console.log("testDetails----->", testDetails);

  const handleInputChange = (name, value) => {
    setTestDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleCheckboxChange = (name) => {
    setTestDetails((prevState) => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  const fetchVesselListing = async () => {
    try {
      const res = await getData({
        endpoint: "vessel/getAllVesselList",
        token: cookies.t,
        params: {},
      });
      //   setVesselList(res?.data || []);
    } catch (err) {
      console.log("Error fetching vessel data:", err);
      //   setVesselList([]);
    }
  };

  useEffect(() => {
    fetchVesselListing();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Prepare the payload based on new state
    const payload = {
      ...testDetails,
      //   vessel_id: trialDetails.vesselName,
    };

    try {
      const result = await postData({
        endpoint: "vesselTrial/create",
        data: payload,
        token: cookies.t,
      });
      if (result) {
        toast.success("Vessel Trial added successfully", {
          style: confimationStyles,
          duration: 2000,
        });
        // setIsTrialModalOpen(false);
        // fetchVesselTrialData();
      }
    } catch (error) {
      console.error("Error submitting vessel information:", error);
      toast.error(error.message || "An error occurred", {
        style: errorStyles,
        duration: 1000,
      });
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* Third Row */}
      {/* {!type && ( */}
      <div style={styles.row}>
        <div style={{ width: "100%" }}>
          <MyTextInput
            type="text"
            name="title"
            customholder="Test Title"
            value={testDetails.title}
            onChange={(e) => handleInputChange("title", e.target.value)}
          />
        </div>
      </div>
      {/* )} */}

      {/* First Row */}
      <div style={styles.row}>
        {/* <div style={{ width: "20%" }}>
          <MyTextInput
            type="text"
            name="testId"
            customholder="Test ID"
            value={testDetails.testId}
            onChange={(e) => handleInputChange("testId", e.target.value)}
          />
        </div> */}
        <div style={{ width: "50%" }}>
          <MyTextInput
            type="text"
            name="fmeaReference"
            customholder="FMEA Reference"
            value={testDetails.fmeaReference}
            onChange={(e) => handleInputChange("fmeaReference", e.target.value)}
          />
        </div>
        <div style={{ width: "50%" }}>
          <MyDropdown
            placeholder="On DP"
            data={[
              { label: "Yes", value: "Yes" },
              { label: "No", value: "No" },
            ]}
            selectedValue={testDetails.onDp}
            onChange={(option) => handleInputChange("onDp", option)}
          />
        </div>
      </div>

      {/* Second Row */}
      <div style={styles.row}>
        <div style={{ width: "50%" }}>
          <MyDropdown
            placeholder="System"
            // data={/* System options */}
            data={[
              {
                label: "Marine Auxiliary Systems",
                value: "Marine Auxiliary Systems",
              },
              { label: "Power Generation", value: "Power Generation" },
              { label: "Power Distribution", value: "Power Distribution" },
              { label: "PMS and VMS", value: "PMS and VMS" },
              {
                label: "Thrusters and Propulsion",
                value: "Thrusters and Propulsion",
              },
              { label: "DP Control System", value: "DP Control System" },
              { label: "Emergency Shut Downs", value: "Emergency Shut Downs" },
            ]}
            selectedValue={testDetails.system}
            onChange={(option) => handleInputChange("system", option)}
          />
        </div>
        <div style={{ width: "50%" }}>
          <MyTextInput
            type="text"
            name="subsystem"
            customholder="Subsystem"
            value={testDetails.subsystem}
            onChange={(e) => handleInputChange("subsystem", e.target.value)}
          />
        </div>
      </div>

      {/* Third Row */}
      <div style={styles.row}>
        <div style={{ width: "100%" }}>
          <MyTextInput
            type="text"
            name="objective"
            customholder="Objective"
            value={testDetails.objective}
            onChange={(e) => handleInputChange("objective", e.target.value)}
          />
        </div>
      </div>

      {/* Fourth Row - Checkboxes */}
      <div style={styles.row}>
        <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
          <CheckboxWrapper
            checked={testDetails.imagesRequired}
            onChange={() => handleCheckboxChange("imagesRequired")}
          />
          <span>Are images required for this test</span>
        </div>
        <div style={{ width: "50%", display: "flex", alignItems: "center" }}>
          <CheckboxWrapper
            checked={testDetails.performedByCrew}
            onChange={() => handleCheckboxChange("performedByCrew")}
          />
          <span>Can be performed by crew</span>
        </div>
      </div>
    </form>
  );
};

const styles = {
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
    gap: "16px",
  },
  submitButtonContainer: {
    display: "flex",
    justifyContent: "flex-end",
  },
  submitButton: {
    padding: "8px 16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default TestDetails;
