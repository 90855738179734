import React, { useState } from "react";
import ImageSelector from "../../../../atoms/ImageSelector/ImageSelector";
import { postFormData } from "../../../../../services";
import { useCookies } from "react-cookie";

const ThrustersRedundancy = ({
  thrusterRedundancyImage,
  setThrusterRedundancyImage,
  id,
}) => {
  const [cookies] = useCookies(["t"]);
  const [isLoading, setIsLoading] = useState(false);

  const handleImageSelection = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      setIsLoading(true);
      const response = await postFormData({
        endpoint: "vessel/upload-file",
        data: formData,
        token: cookies.t,
      });

      const s3Link = response?.data?.data?.s3UploadedLink;
      if (s3Link) {
        setThrusterRedundancyImage(s3Link); // Update with S3 link
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="vessel-photo">
      <ImageSelector
        onImageSelect={handleImageSelection}
        s3ImageLink={thrusterRedundancyImage}
        id={id}
        onRemoveImage={() => setThrusterRedundancyImage(null)}
      />
    </div>
  );
};

export default ThrustersRedundancy;
