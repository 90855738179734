import React, { useEffect, useState } from "react";
import SearchIcon from "../../icons/Search";
import "./styles.scss";

const Search = ({ onSearchEmit, placeholder = "Search", value, style }) => {
  const [inputValue, setInputValue] = useState("");

  useEffect(() => {
    setInputValue(value);
  }, [value]);

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setInputValue(newValue);

    if (newValue.length >= 3) {
      console.log("emit");
      onSearchEmit(newValue);
    } else {
      onSearchEmit("");
    }
  };

  return (
    <div className="search" style={style}>
      <div className="input-group" style={{ background: "#f4f4f4" }}>
        {/* <div className="input-group-prepend1">
          <span className="input-group-text " id="basic-addon1">
            <SearchIcon />
          </span>
        </div> */}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            paddingLeft: "10px",
          }}
        >
          <SearchIcon />
        </div>
        <input
          type="text"
          className="form-control"
          placeholder={placeholder}
          style={{ paddingLeft: "0" }}
          aria-label="Username"
          aria-describedby="basic-addon1"
          value={inputValue}
          onChange={handleInputChange}
        />
      </div>
    </div>
  );
};

export default Search;

// import React, { useEffect, useState } from "react";
// import "./styles.scss";

// const Search = ({ onSearchEmit, placeholder = "Search", value, style }) => {
//   const [inputValue, setInputValue] = useState("");

//   useEffect(() => {
//     setInputValue(value);
//   }, [value]);

//   const handleInputChange = (e) => {
//     const newValue = e.target.value;
//     setInputValue(newValue);

//     if (newValue.length >= 3) {
//       console.log("emit");
//       onSearchEmit(newValue);
//     } else {
//       onSearchEmit("");
//     }
//   };

//   return (
//     <div className="search1" style={style}>
//       <div className="input-group1">
//         {/* <div className="input-group-prepend1">
//           <span className="input-group-text1" id="basic-addon1">
//             <i className="fi fi-rr-search ms-2 mt-1"></i>
//           </span>
//         </div> */}
//         <input
//           type="text"
//           className="form-control1"
//           placeholder={placeholder}
//           style={{ paddingLeft: "0" }}
//           aria-label="Search"
//           aria-describedby="basic-addon1"
//           value={inputValue}
//           onChange={handleInputChange}
//         />
//       </div>
//     </div>
//   );
// };

// export default Search;
