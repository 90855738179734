// import React, { useRef, useEffect, useState } from "react";
// import CustomButton from "../../../../atoms/CustomButton"; // Assuming CustomButton is your custom button component
// import MyTextInput from "../../../../atoms/MyTextInput"; // Assuming MyTextInput is your custom text input component

// const CameraCapture = ({
//   capturedImage1,
//   setCapturedImage1,
//   note23,
//   setNote23,
// }) => {
//   const videoRef = useRef(null); // Reference for the video element
//   const canvasRef = useRef(null); // Reference for the canvas element
//   const [error, setError] = useState(null); // State to store any camera-related errors

//   useEffect(() => {
//     // Automatically open camera when component mounts
//     navigator.mediaDevices
//       .getUserMedia({ video: true })
//       .then((stream) => {
//         if (videoRef.current) {
//           videoRef.current.srcObject = stream; // Set the video source to the stream
//         }
//       })
//       .catch((err) => {
//         console.error("Error accessing the camera: ", err);
//         setError("Unable to access the camera. Please check permissions."); // Display error message
//       });

//     return () => {
//       // Cleanup and stop camera when component unmounts
//       if (videoRef.current && videoRef.current.srcObject) {
//         const stream = videoRef.current.srcObject;
//         const tracks = stream.getTracks();
//         tracks.forEach((track) => track.stop()); // Stop all video tracks
//       }
//     };
//   }, []);

//   const captureImage = () => {
//     const canvas = canvasRef.current;
//     const video = videoRef.current;
//     if (canvas && video) {
//       const context = canvas.getContext("2d");
//       canvas.width = video.videoWidth;
//       canvas.height = video.videoHeight;
//       context.drawImage(video, 0, 0, canvas.width, canvas.height); // Draw the video frame on the canvas
//       setCapturedImage1(canvas.toDataURL("image/png")); // Convert canvas to image data URL
//     }
//   };

//   const handleUpload = () => {
//     // Handle the upload action (e.g., upload image to a server or database)
//     console.log("Uploading Image with Note:", note23);
//     // You can add your upload logic here
//   };

//   return (
//     <div>
//       {error && <p style={{ color: "red" }}>{error}</p>}{" "}
//       {/* Show error message if there's an issue */}
//       <div>
//         <video
//           ref={videoRef}
//           autoPlay
//           playsInline
//           style={{
//             width: "100%",
//             border: "1px solid #ddd",
//             borderRadius: "4px",
//           }}
//         />
//         <CustomButton
//           text="Capture Image"
//           handleClick={captureImage}
//           iconRequired={false}
//           type="btn-primary"
//         />
//       </div>
//       {capturedImage1 && (
//         <div>
//           <h3
//             style={{
//               fontSize: "20px",
//               fontWeight: "bold",
//               marginTop: "10px",
//               marginBottom: "10px",
//             }}
//           >
//             Captured Image
//           </h3>
//           <img src={capturedImage1} alt="Captured" style={{ width: "100%" }} />

//           {/* MyTextInput for entering a note */}
//           {/* <MyTextInput
//             value={note}
//             onChange={(e) => setNote(e.target.value)}
//             placeholder="Enter your note here"
//             style={{ marginTop: "10px" }}
//           /> */}
//           <MyTextInput
//             type="text"
//             name="note"
//             customholder="Note"
//             value={note23}
//             onChange={(e) => setNote23(e.target.value)}
//             style={{ width: "100%" }}
//           />

//           {/* Upload button */}
//           <div style={{ height: "10px" }}></div>
//           <CustomButton
//             text="Upload"
//             handleClick={handleUpload}
//             iconRequired={false}
//             type="btn-primary"
//             style={{ marginTop: "10px" }}
//           />
//         </div>
//       )}
//       <canvas ref={canvasRef} style={{ display: "none" }} />
//     </div>
//   );
// };

// export default CameraCapture;

import React, { useRef, useEffect, useState } from "react";
import CustomButton from "../../../../atoms/CustomButton";
import MyTextInput from "../../../../atoms/MyTextInput";

const CameraCapture = ({
  capturedImage1,
  setCapturedImage1,
  note23,
  setNote23,
}) => {
  const videoRef = useRef(null);
  const canvasRef = useRef(null);
  const [error, setError] = useState(null);

  // Temporary states
  const [tempFile, setTempFile] = useState(null);
  const [tempNote, setTempNote] = useState("");

  useEffect(() => {
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      })
      .catch((err) => {
        console.error("Error accessing the camera: ", err);
        setError("Unable to access the camera. Please check permissions.");
      });

    return () => {
      if (videoRef.current && videoRef.current.srcObject) {
        const stream = videoRef.current.srcObject;
        const tracks = stream.getTracks();
        tracks.forEach((track) => track.stop());
      }
    };
  }, []);

  const captureImage = () => {
    const canvas = canvasRef.current;
    const video = videoRef.current;
    if (canvas && video) {
      const context = canvas.getContext("2d");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      context.drawImage(video, 0, 0, canvas.width, canvas.height);
      setTempFile(canvas.toDataURL("image/png")); // Store the captured image temporarily
    }
  };

  const handleSave = () => {
    // Assign temp values to the original state variables
    setCapturedImage1(tempFile);
    setNote23(tempNote);
    console.log("Saved Image and Note:", tempFile, tempNote);
  };

  return (
    <div>
      {error && <p style={{ color: "red" }}>{error}</p>}
      <div>
        <video
          ref={videoRef}
          autoPlay
          playsInline
          style={{
            width: "100%",
            border: "1px solid #ddd",
            borderRadius: "4px",
          }}
        />
        <CustomButton
          text="Capture Image"
          handleClick={captureImage}
          iconRequired={false}
          type="btn-primary"
        />
      </div>
      {tempFile && (
        <div>
          <h3
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginTop: "10px",
              marginBottom: "10px",
            }}
          >
            Captured Image
          </h3>
          <img src={tempFile} alt="Captured" style={{ width: "100%" }} />

          <MyTextInput
            type="text"
            name="note"
            customholder="Note"
            value={tempNote}
            onChange={(e) => setTempNote(e.target.value)}
            style={{ width: "100%" }}
          />

          <div style={{ height: "10px" }}></div>
          <CustomButton
            text="Save"
            handleClick={handleSave}
            iconRequired={false}
            type="btn-primary"
            style={{ marginTop: "10px" }}
          />
        </div>
      )}
      <canvas ref={canvasRef} style={{ display: "none" }} />
    </div>
  );
};

export default CameraCapture;
