import React from "react";
import SimpleAccordion from "../../../../molecules/MyAccordion";
import SingleLineDiagram from "./singleLineDiagram";
import ThrustersRedundancy from "./thrustersRedundancy";
import RedundantGroups from "./redundantGroups";
import WorstCaseFailure from "./worstCaseFailure";

const RedundancyConcept = ({
  redundantGroupsRows,
  setRedundantGroupsRows,
  redundantGroupsCount,
  worstCaseFailureRows,
  setWorstCaseFailureRows,
  worstCaseFailureCount,
  singleLineImage,
  setSingleLineImage,
  thrusterRedundancyImage,
  setThrusterRedundancyImage,
  id,
}) => {
  const RedundancyData = [
    {
      title: "Single Line Diagram",
      content: (
        <SingleLineDiagram
          singleLineImage={singleLineImage}
          setSingleLineImage={setSingleLineImage}
          id={id}
        />
      ),
      // count: 10,
    },
    {
      title: "Thrusters Redundancy Concept",
      content: (
        <ThrustersRedundancy
          thrusterRedundancyImage={thrusterRedundancyImage}
          setThrusterRedundancyImage={setThrusterRedundancyImage}
          id={id}
        />
      ),
    },
    {
      title: "Redundant Groups",
      content: (
        <RedundantGroups
          redundantGroupsRows={redundantGroupsRows}
          setRedundantGroupsRows={setRedundantGroupsRows}
        />
      ),
      count: redundantGroupsCount,
    },
    {
      title: "Worst Case Failure Design Intent",
      content: (
        <WorstCaseFailure
          worstCaseFailureRows={worstCaseFailureRows}
          setWorstCaseFailureRows={setWorstCaseFailureRows}
        />
      ),
      count: worstCaseFailureCount,
    },
  ];
  return (
    <div>
      <SimpleAccordion items={RedundancyData} />
    </div>
  );
};

export default RedundancyConcept;
