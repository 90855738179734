import React, { useState } from "react";
import MyTextInput from "../../../../atoms/MyTextInput";
import Trash from "../../../../icons/Trash";
import Modal from "../../../../organisms/modals/DeleteModal";

const NoteWithImage = ({ note, imageSrc, onNoteChange, onDelete, index }) => (
  <div style={{ marginBottom: "20px" }}>
    <div
      style={{ marginRight: "10px", fontWeight: "bold", marginBottom: "10px" }}
    >
      # {index + 1}
    </div>
    <div style={{ display: "flex" }}>
      <MyTextInput
        type="text"
        name="note"
        customholder="Note"
        value={note}
        onChange={onNoteChange}
        style={{ width: "99%" }} // Ensure input takes full width
      />
      <button
        className="delete-button"
        onClick={() => onDelete(index)}
        style={{
          background: "none",
          border: "none",
          cursor: "pointer",
          margin: 0,
          padding: 0,
        }}
      >
        <Trash />
      </button>
    </div>
    <div style={{ marginTop: "20px", justifyContent: "center" }}>
      {imageSrc && (
        <img
          src={imageSrc}
          alt="Rendered"
          style={{
            width: "100%",
            border: "1px solid #ddd",
            borderRadius: "4px",
            objectFit: "cover",
          }}
        />
      )}
    </div>
  </div>
);

const NotesAndImages = ({ notesData, setNotesData }) => {
  const [openModal, setOpenModal] = useState(false); // Modal state
  const [deleteIndex, setDeleteIndex] = useState(null); // State to store the index of the note to delete

  const handleNoteChange = (index, newNote) => {
    setNotesData((prevNotes) =>
      prevNotes.map((item, i) =>
        i === index ? { ...item, note: newNote } : item,
      ),
    );
  };

  const handleDeleteNote = (index) => {
    setDeleteIndex(index); // Set the index to be deleted
    setOpenModal(true); // Open confirmation modal
  };

  const confirmDelete = () => {
    setNotesData((prevNotes) => prevNotes.filter((_, i) => i !== deleteIndex)); // Remove note at given index
    setOpenModal(false); // Close modal after deletion
    setDeleteIndex(null); // Reset delete index
  };

  const cancelDelete = () => {
    setOpenModal(false); // Close modal without deleting
    setDeleteIndex(null); // Reset delete index
  };

  return (
    <div>
      {notesData?.map((item, index) => (
        <NoteWithImage
          key={index}
          note={item.note}
          imageSrc={item.image_link}
          onNoteChange={(e) => handleNoteChange(index, e.target.value)}
          onDelete={() => handleDeleteNote(index)} // Pass delete function
          index={index}
        />
      ))}

      {/* Modal for delete confirmation */}
      {openModal && (
        <Modal
          isActive={openModal}
          title="Note"
          name={notesData[deleteIndex]?.note || ""} // Get the note name or an empty string if undefined
          onClose={cancelDelete} // Close modal without deleting
          onClick={confirmDelete} // Confirm deletion
          type="Delete"
          description="Are you sure you want to delete this note?"
        />
      )}
    </div>
  );
};

export default NotesAndImages;
