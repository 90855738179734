const Media = ({ width = "22", height = "22" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      height={height}
      width={width}
    >
      <path d="M24,13v6c0,2.761-2.239,5-5,5H6c-2.761,0-5-2.239-5-5V11h1.143L7.155,3.2l3.079-.835L4.971,10.593l-1.472,.385-.043,.022H22c1.105,0,2,.895,2,2Zm-7.02-5.544L21.246,.613c-.591-.45-1.342-.677-2.137-.598l-5.263,8.259,3.134-.819Zm4.627-1.209c.865-.226,1.394-1.098,1.195-1.97l-.398-1.741-2.608,4.184,1.811-.473Zm-10.607,2.771L16.286,.725l-3.179,.862L7.822,9.848l3.178-.83ZM2.354,4.501C.739,4.857-.286,6.46,.071,8.083l.417,1.826L4.281,3.979l-1.927,.522Z" />
    </svg>
  );
};

export default Media;
