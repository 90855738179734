// import React, { useState } from "react";
// import "./styles.scss";
// import ArrowDown from "../../icons/ArrowDown";

// const AccordionItem = ({
//   title,
//   icon,
//   children,
//   isOpen,
//   toggle,
//   removePadding,
// }) => {
//   return (
//     <div className="accordion-item">
//       <div
//         className="accordion-header"
//         onClick={toggle}
//         style={{
//           cursor: "pointer",
//           display: "flex",
//           justifyContent: "space-between",
//         }}
//       >
//         <div style={{ display: "flex", alignItems: "center" }}>
//           <span style={{ marginRight: "15px" }}>{icon}</span>
//           <span>{title}</span>
//         </div>
//         <span className={isOpen ? "rotate" : ""}>
//           {isOpen ? <ArrowDown /> : <ArrowDown />}
//         </span>
//       </div>
//       {isOpen && (
//         <div
//           className="accordion-content"
//           style={{ padding: removePadding ? "0" : "15px" }}
//         >
//           {children}
//         </div>
//       )}
//     </div>
//   );
// };

// // Accordion Component
// const Accordion = ({ items }) => {
//   const [openIndex, setOpenIndex] = useState(null);

//   // Function to toggle accordion item
//   const toggleAccordion = (index) => {
//     setOpenIndex(openIndex === index ? null : index);
//   };

//   return (
//     <div className="accordion">
//       {items.map((item, index) => (
//         <AccordionItem
//           key={index}
//           title={item.title}
//           icon={item.icon} // Pass the dynamic icon
//           isOpen={openIndex === index}
//           toggle={() => toggleAccordion(index)}
//           removePadding={item.removePadding} // Use item-specific prop
//         >
//           {item.content}
//         </AccordionItem>
//       ))}
//     </div>
//   );
// };

// export default Accordion;

// import React, { useState } from "react";
// import "./styles.scss";
// import ArrowDown from "../../icons/ArrowDown";

// const AccordionItem = ({
//   title,
//   icon,
//   children,
//   isOpen,
//   toggle,
//   removePadding,
// }) => {
//   return (
//     <div className="accordion-item">
//       <div
//         className="accordion-header"
//         onClick={toggle}
//         style={{
//           cursor: "pointer",
//           display: "flex",
//           justifyContent: "space-between",
//         }}
//       >
//         <div style={{ display: "flex", alignItems: "center" }}>
//           <span style={{ marginRight: "15px" }}>{icon}</span>
//           <span>{title}</span>
//         </div>
//         <span className={isOpen ? "rotate" : ""}>
//           <ArrowDown />
//         </span>
//       </div>
//       {isOpen && (
//         <div
//           className="accordion-content"
//           style={{ padding: removePadding ? "0" : "15px" }}
//         >
//           {children}
//         </div>
//       )}
//     </div>
//   );
// };

// // Accordion Component that allows multiple items to be open at once
// const Accordion = ({ items }) => {
//   const [openItems, setOpenItems] = useState([]); // Array to track open items

//   // Function to toggle accordion item
//   const toggleAccordion = (index) => {
//     if (openItems.includes(index)) {
//       // If item is already open, remove it from the array (close it)
//       setOpenItems(openItems.filter((i) => i !== index));
//     } else {
//       // If item is closed, add it to the array (open it)
//       setOpenItems([...openItems, index]);
//     }
//   };

//   return (
//     <div className="accordion">
//       {items.map((item, index) => (
//         <AccordionItem
//           key={index}
//           title={item.title}
//           icon={item.icon} // Pass the dynamic icon
//           isOpen={openItems.includes(index)} // Check if the item is open
//           toggle={() => toggleAccordion(index)} // Toggle individual items
//           removePadding={item.removePadding} // Use item-specific prop
//         >
//           {item.content}
//         </AccordionItem>
//       ))}
//     </div>
//   );
// };

// export default Accordion;

//----------------------------------------
// import React, { useState } from "react";
// import "./styles.scss";
// import ArrowDown from "../../icons/ArrowDown";

// const AccordionItem = ({
//   title,
//   icon,
//   children,
//   isOpen,
//   toggle,
//   removePadding,
//   status,
//   checkbox,
//   checkboxText,
//   count,
// }) => {
//   const handleCheckboxClick = (e) => {
//     // Prevent the accordion from opening when the checkbox is clicked
//     e.stopPropagation();
//   };
//   return (
//     <div className="accordion-item">
//       <div
//         className="accordion-header"
//         onClick={toggle}
//         style={{
//           cursor: "pointer",
//           display: "flex",
//           justifyContent: "space-between",
//           alignItems: "center",
//         }}
//       >
//         <div style={{ display: "flex", alignItems: "center" }}>
//           {icon && <span style={{ marginRight: "15px" }}>{icon}</span>}
//           <span>{title}</span>
//           {count !== undefined && (
//             <span
//               className="badge"
//               style={{ color: "white", height: "24px", fontSize: "12px" }}
//             >
//               {count}
//             </span>
//           )}
//         </div>
//         <div style={{ display: "flex", alignItems: "center" }}>
//           {status && (
//             <span
//               style={{
//                 fontSize: "15px",
//                 fontWeight: "600",
//               }}
//             >
//               Status : <b>In Progress</b>
//             </span>
//           )}
//           {checkbox && (
//             <label
//               style={{
//                 display: "flex",
//                 alignItems: "center",
//                 marginLeft: "10px",
//                 marginBottom: "0px",
//               }}
//             >
//               <input
//                 type="checkbox"
//                 style={{ marginRight: "10px", transform: "scale(1.2)" }}
//                 onClick={handleCheckboxClick} // Prevent the accordion from opening when clicking the checkbox
//               />
//               {/* {checkboxText} */}
//               <span style={{ fontSize: "15px", fontWeight: "600" }}>
//                 {checkboxText}
//               </span>
//             </label>
//           )}
//           <span
//             className={isOpen ? "rotate" : ""}
//             style={{ marginLeft: "10px" }}
//           >
//             <ArrowDown />
//           </span>
//         </div>
//       </div>
//       {isOpen && (
//         <div
//           className="accordion-content"
//           style={{ padding: removePadding ? "0" : "15px" }}
//         >
//           {children}
//         </div>
//       )}
//     </div>
//   );
// };

// // Accordion Component that allows multiple items to be open at once
// const Accordion = ({ items }) => {
//   const [openItems, setOpenItems] = useState([]); // Array to track open items

//   // Function to toggle accordion item
//   const toggleAccordion = (index) => {
//     if (openItems.includes(index)) {
//       // If item is already open, remove it from the array (close it)
//       setOpenItems(openItems.filter((i) => i !== index));
//     } else {
//       // If item is closed, add it to the array (open it)
//       setOpenItems([...openItems, index]);
//     }
//   };

//   return (
//     <div className="accordion">
//       {items.map((item, index) => (
//         <AccordionItem
//           key={index}
//           title={item.title}
//           icon={item.icon} // Pass the dynamic icon
//           count={item.count} // Pass the dynamic count
//           isOpen={openItems.includes(index)} // Check if the item is open
//           toggle={() => toggleAccordion(index)} // Toggle individual items
//           removePadding={item.removePadding} // Use item-specific prop
//           status={item.status} // Conditionally show status text
//           checkbox={item.checkbox} // Conditionally show checkbox
//           checkboxText={item.checkboxText} // Pass text for checkbox
//         >
//           {item.content}
//         </AccordionItem>
//       ))}
//     </div>
//   );
// };

// export default Accordion;

//-----------------------------------

import React, { useState } from "react";
import "./styles.scss";
import ArrowDown from "../../icons/ArrowDown";

const AccordionItem = ({
  title,
  icon,
  children,
  isOpen,
  toggle,
  removePadding,
  status,
  checkbox,
  checkboxText,
  count,
  checked,
  onCheckboxChange,
}) => {
  const handleCheckboxClick = (e) => {
    e.stopPropagation(); // Prevent accordion toggle when clicking checkbox
    onCheckboxChange(e.target.checked); // Notify parent of checkbox state change
  };

  return (
    <div className="accordion-item">
      <div
        className="accordion-header"
        onClick={toggle}
        style={{
          cursor: "pointer",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ display: "flex", alignItems: "center" }}>
          {icon && <span style={{ marginRight: "15px" }}>{icon}</span>}
          <span>{title}</span>
          {count !== undefined && (
            <span
              // className="badge"
              className={`badge ${
                title === "Recommendations"
                  ? "badge-recommendations"
                  : "badge-default"
              }`}
              style={{ color: "white", height: "24px", fontSize: "12px" }}
            >
              {count}
            </span>
          )}
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          {status && (
            <span
              style={{
                fontSize: "15px",
                fontWeight: "600",
              }}
            >
              Status : <b>In Progress</b>
            </span>
          )}
          {checkbox && (
            <label
              style={{
                display: "flex",
                alignItems: "center",
                marginLeft: "10px",
                marginBottom: "0px",
              }}
            >
              <input
                type="checkbox"
                style={{ marginRight: "10px", transform: "scale(1.2)" }}
                checked={checked} // Bind checkbox state
                onClick={handleCheckboxClick} // Notify parent of state change
              />
              <span style={{ fontSize: "15px", fontWeight: "600" }}>
                {checkboxText}
              </span>
            </label>
          )}
          <span
            className={isOpen ? "rotate" : ""}
            style={{ marginLeft: "10px" }}
          >
            <ArrowDown />
          </span>
        </div>
      </div>
      {isOpen && (
        <div
          className="accordion-content"
          style={{ padding: removePadding ? "0" : "15px" }}
        >
          {children}
        </div>
      )}
    </div>
  );
};

const Accordion = ({ items, checkboxState, setCheckboxState }) => {
  const [openItems, setOpenItems] = useState([]); // Array to track open items
  // const [checkboxState, setCheckboxState] = useState({}); // Track checkbox states

  console.log(checkboxState); // { 0: true, 1: false } if the first checkbox is checked

  // Toggle accordion item
  const toggleAccordion = (index) => {
    if (openItems.includes(index)) {
      setOpenItems(openItems.filter((i) => i !== index));
    } else {
      setOpenItems([...openItems, index]);
    }
  };

  // Handle checkbox state changes
  const handleCheckboxChange = (index, isChecked) => {
    setCheckboxState((prevState) => ({
      ...prevState,
      [index]: isChecked, // Update checkbox state for the specific item
    }));
  };

  return (
    <div className="accordion">
      {items.map((item, index) => (
        <AccordionItem
          key={index}
          title={item.title}
          icon={item.icon}
          count={item.count}
          isOpen={openItems.includes(index)}
          toggle={() => toggleAccordion(index)}
          removePadding={item.removePadding}
          status={item.status}
          checkbox={item.checkbox}
          checkboxText={item.checkboxText}
          checked={checkboxState?.[index] || false} // Pass current checkbox state
          onCheckboxChange={(isChecked) =>
            handleCheckboxChange(index, isChecked)
          } // Handle checkbox change
        >
          {item.content}
        </AccordionItem>
      ))}
    </div>
  );
};

export default Accordion;
