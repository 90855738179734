// import React from "react";
// // import { FaTools, FaCheckCircle, FaUserFriends } from "react-icons/fa";

// const TrialHistory = () => {
//   const timelineData = [
//     {
//       date: "09/10/2024 17:08",
//       icon: "hiii1",
//       title: "Draft",
//       description:
//         "Trials program copied from trials program DP Annual Trials 2024 by Devendra Kathuria",
//       linkText: "DP Annual Trials 2024",
//       user: "Devendra Kathuria",
//     },
//     {
//       date: "11/10/2024 11:23",
//       icon: "hiii2",
//       title: "Ready",
//       description: "Trials status changed by Devendra Kathuria",
//       user: "Devendra Kathuria",
//     },
//     {
//       date: "11/10/2024 11:55",
//       icon: "hiii3",
//       title: "In progress",
//       description: "Trials status changed by Abdelrahman Fahmy",
//       user: "Abdelrahman Fahmy",
//     },
//   ];

//   return (
//     <div style={{ padding: "20px", maxWidth: "400px", margin: "auto" }}>
//       {timelineData.map((item, index) => (
//         <div
//           key={index}
//           style={{
//             display: "flex",
//             alignItems: "flex-start",
//             marginBottom: "20px",
//           }}
//         >
//           <div
//             style={{
//               width: "50px",
//               display: "flex",
//               justifyContent: "center",
//               alignItems: "center",
//             }}
//           >
//             <div
//               style={{
//                 backgroundColor: "#36a3f7",
//                 color: "#fff",
//                 width: "30px",
//                 height: "30px",
//                 borderRadius: "50%",
//                 display: "flex",
//                 justifyContent: "center",
//                 alignItems: "center",
//                 fontSize: "18px",
//               }}
//             >
//               {item.icon}
//             </div>
//           </div>
//           <div
//             style={{
//               borderLeft: "2px solid #ddd",
//               paddingLeft: "20px",
//               flexGrow: 1,
//             }}
//           >
//             <p style={{ margin: 0, color: "#666", fontSize: "14px" }}>
//               {item.date}
//             </p>
//             <h4
//               style={{ margin: "5px 0", fontSize: "16px", fontWeight: "bold" }}
//             >
//               {item.title}
//             </h4>
//             <p style={{ margin: 0, fontSize: "14px", color: "#333" }}>
//               {item.description.replace(item.user, "")}
//               <a href="#" style={{ color: "#36a3f7", textDecoration: "none" }}>
//                 {` ${item.user}`}
//               </a>
//             </p>
//           </div>
//         </div>
//       ))}
//     </div>
//   );
// };

// export default TrialHistory;

import React from "react";
import Tool from "../../../../icons/Tool";
import Progress from "../../../../icons/Progress";
import ProfileSettings from "../../../../icons/ProfileSettings";

const TrialTimeline = () => {
  const timelineData = [
    {
      date: "09/10/2024 17:08",
      status: "Draft",
      message: "Trials program copied from trials program",
      programName: "DP Annual Trials 2024",
      user: "Devendra Kathuria",
      icon: Tool, // Set the component type, not an instance
    },
    {
      date: "11/10/2024 11:23",
      status: "Ready",
      message: "Trials status changed by",
      user: "Devendra Kathuria",
      icon: Progress,
    },
    {
      date: "11/10/2024 11:55",
      status: "In progress",
      message: "Trials status changed by",
      user: "Abdelrahman Fahmy",
      icon: ProfileSettings,
    },
  ];

  return (
    <div className="timeline-container">
      <div className="timeline">
        {timelineData.map((item, index) => (
          <div key={index} className="timeline-item">
            <div className="timeline-date">{item.date}</div>
            {index < timelineData.length - 1 && (
              <div className="timeline-line" />
            )}

            <div className="timeline-icon">
              <item.icon className="icon" /> {/* Render icon component */}
            </div>

            <div className="timeline-content">
              <h3 className="timeline-status">{item.status}</h3>
              <p className="timeline-message">
                {item.message}{" "}
                {item.programName ? (
                  <>
                    <span className="timeline-link">{item.programName}</span>
                    {" by "}
                    <span className="timeline-link">{item.user}</span>
                  </>
                ) : (
                  <span className="timeline-link">{item.user}</span>
                )}
              </p>
            </div>
          </div>
        ))}
        <div style={{ marginBottom: "-60px" }}></div>
      </div>

      <style jsx>{`
        .timeline-container {
          max-width: 800px;
          margin: 0 auto;
          padding: 20px;
        }

        .timeline {
          position: relative;
        }

        .timeline-item {
          display: flex;
          margin-bottom: 32px;
          position: relative;
        }

        .timeline-date {
          width: 140px;
          flex-shrink: 0;
          padding-top: 12px;
          padding-right: 16px;
          color: #666;
          font-size: 14px;
        }

        .timeline-line {
          position: absolute;
          left: 135px;
          top: 32px;
          margin-left: 28px;
          width: 2px;
          height: calc(100% + 32px);
          background-color: #bfdbfe;
        }

        .timeline-icon {
          flex-shrink: 0;
          margin-right: 16px;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          background-color: #eff6ff;
          display: flex;
          align-items: center;
          justify-content: center;
          z-index: 10;
        }

        .timeline-icon :global(.icon) {
          width: 24px;
          height: 24px;
          color: #3b82f6;
        }

        .timeline-content {
          flex-grow: 1;
          // padding-top: 8px;
          padding-left: 18px !important;
          padding-right: 18px;
          height: 100px;
        }

        .timeline-status {
          font-size: 18px;
          font-weight: 600;
          margin: 0;
          color: #1f2937;
        }

        .timeline-message {
          margin-top: 8px;
          color: #374151;
        }

        .timeline-link {
          color: #2563eb;
          text-decoration: none;
          cursor: pointer;
        }

        .timeline-link:hover {
          text-decoration: underline;
        }

        @media (max-width: 600px) {
          .timeline-date {
            width: 100px;
            font-size: 12px;
          }

          .timeline-line {
            left: 120px;
          }

          .timeline-icon {
            width: 40px;
            height: 40px;
          }

          .timeline-icon :global(.icon) {
            width: 20px;
            height: 20px;
          }
        }
      `}</style>
    </div>
  );
};

export default TrialTimeline;
