import React from "react";
import SimpleAccordion from "../../../../molecules/MyAccordion";
import MaintenanceTable from "./mainMachineries";
import DPSensor from "./dpSensors";
import MainSwitchboard from "./mainSwitchboard";
import VesselControlSystem from "./vesselControlSystem";
import HardwareModificationForm from "./hardwareModifications";
import CapabilityAndFootprints from "./capabilityFootprints";
import DPStation from "./dpStation";
import KeyDpPersonnel from "./keyDpPersonnel";
import FMEATrials from "./fmeaTrials";
import OperationalDocumentation from "./operationalDocumentation";

const PersonnelEquipment = ({
  machineMachineriesRows,
  setMachineMachineriesRows,
  machineMachineriesRemarks,
  setMachineMachineriesRemarks,
  machineriesRowsCount,
  mainSwitchboard,
  setMainSwitchboard,
  dpSensorRows,
  setDpSensorRows,
  dpSensorRemarks,
  setDpSensorRemarks,
  dpSensorRowsCount,
  vesselControlRows,
  setVesselControlRows,
  vesselControlRemarks,
  setVesselControlRemarks,
  vesselControlRowsCount,
  hardwareModification,
  setHardwareModification,
  modificationsTested,
  setModificationsTested,
  trialsUpdated,
  setTrialsUpdated,
  capabilityOnboards,
  setCapabilityOnboards,
  footprints,
  setFootprints,
  eventsRecorded,
  setEventsRecorded,
  satisfactoryExplanation,
  setSatisfactoryExplanation,
  dpPersonnelRows,
  setDpPersonnelRows,
  dpPersonnelRowsCount,
  fmeaLastRevision,
  setFmeaLastRevision,
  fmeaLastDate,
  setFmeaLastDate,
  fmeaFindingsCloseOut,
  setFmeaFindingsCloseOut,
  lastDpAnnualTrialsDate,
  setLastDpAnnualTrialsDate,
  findingsClosedOut,
  setFindingsClosedOut,
  fmeaTrialsRemarks,
  setFmeaTrialsRemarks,
  operationalDocumentation,
  setOperationalDocumentation,
}) => {
  const PersonnelData = [
    {
      title: "Main Machineries",
      content: (
        <MaintenanceTable
          machineMachineriesRows={machineMachineriesRows}
          setMachineMachineriesRows={setMachineMachineriesRows}
          machineMachineriesRemarks={machineMachineriesRemarks}
          setMachineMachineriesRemarks={setMachineMachineriesRemarks}
        />
      ),
      count: machineriesRowsCount,
    },
    {
      title: "Main Switchboard",
      content: (
        <MainSwitchboard
          mainSwitchboard={mainSwitchboard}
          setMainSwitchboard={setMainSwitchboard}
        />
      ),
    },
    {
      title: "DP Sensors",
      content: (
        <DPSensor
          dpSensorRows={dpSensorRows}
          setDpSensorRows={setDpSensorRows}
          dpSensorRemarks={dpSensorRemarks}
          setDpSensorRemarks={setDpSensorRemarks}
        />
      ),
      count: dpSensorRowsCount,
    },
    {
      title: "DP and other Vessel Control Systems",
      content: (
        <VesselControlSystem
          vesselControlRows={vesselControlRows}
          setVesselControlRows={setVesselControlRows}
          vesselControlRemarks={vesselControlRemarks}
          setVesselControlRemarks={setVesselControlRemarks}
        />
      ),
      count: vesselControlRowsCount,
    },
    {
      title: "Hardware Modifications",
      content: (
        <HardwareModificationForm
          hardwareModification={hardwareModification}
          setHardwareModification={setHardwareModification}
          modificationsTested={modificationsTested}
          setModificationsTested={setModificationsTested}
          trialsUpdated={trialsUpdated}
          setTrialsUpdated={setTrialsUpdated}
        />
      ),
    },
    {
      title: "Capability and Footprint Plots",
      content: (
        <CapabilityAndFootprints
          capabilityOnboards={capabilityOnboards}
          setCapabilityOnboards={setCapabilityOnboards}
          footprints={footprints}
          setFootprints={setFootprints}
        />
      ),
    },
    {
      title: "DP Station Keeping Events",
      content: (
        <DPStation
          eventsRecorded={eventsRecorded}
          setEventsRecorded={setEventsRecorded}
          satisfactoryExplanation={satisfactoryExplanation}
          setSatisfactoryExplanation={setSatisfactoryExplanation}
        />
      ),
    },
    {
      title: "Key DP Personnel",
      content: (
        <KeyDpPersonnel
          dpPersonnelRows={dpPersonnelRows}
          setDpPersonnelRows={setDpPersonnelRows}
        />
      ),
      count: dpPersonnelRowsCount,
    },
    {
      title: "FMEA and Trials",
      content: (
        <FMEATrials
          fmeaLastRevision={fmeaLastRevision}
          setFmeaLastRevision={setFmeaLastRevision}
          fmeaLastDate={fmeaLastDate}
          setFmeaLastDate={setFmeaLastDate}
          fmeaFindingsCloseOut={fmeaFindingsCloseOut}
          setFmeaFindingsCloseOut={setFmeaFindingsCloseOut}
          lastDpAnnualTrialsDate={lastDpAnnualTrialsDate}
          setLastDpAnnualTrialsDate={setLastDpAnnualTrialsDate}
          findingsClosedOut={findingsClosedOut}
          setFindingsClosedOut={setFindingsClosedOut}
          fmeaTrialsRemarks={fmeaTrialsRemarks}
          setFmeaTrialsRemarks={setFmeaTrialsRemarks}
        />
      ),
    },
    {
      title: "Operational Documentation",
      content: (
        <OperationalDocumentation
          operationalDocumentation={operationalDocumentation}
          setOperationalDocumentation={setOperationalDocumentation}
        />
      ),
    },
  ];

  return (
    <div>
      <SimpleAccordion items={PersonnelData} />
    </div>
  );
};

export default PersonnelEquipment;
