// import React, { useState, useRef } from "react";
// import "./styles.scss"; // Import CSS
// import Upload from "../../icons/Upload";

// const ImageSelector = ({ placeholder = "Upload Image", maxFileSize = 1 }) => {
//   const [imagePreview, setImagePreview] = useState(null);
//   const [fileName, setFileName] = useState(null); // To store and display the file name
//   const fileInputRef = useRef(null);

//   const handleClick = () => {
//     fileInputRef.current.click(); // Trigger the hidden input field
//   };

//   const handleChange = (event) => {
//     const file = event.target.files[0];

//     if (file && file.type.startsWith("image/")) {
//       if (file.size > maxFileSize * 1024 * 1024) {
//         alert(`Image size should be less than ${maxFileSize} MB`);
//         return;
//       }

//       setFileName(file.name); // Store the file name

//       // Preview the selected image
//       const reader = new FileReader();
//       reader.onload = () => {
//         setImagePreview(reader.result);
//       };
//       reader.readAsDataURL(file);
//     } else {
//       alert("Please select a valid image file (JPG, PNG, etc.).");
//     }
//   };

//   const handleRemove = () => {
//     setImagePreview(null); // Remove the preview image
//     setFileName(null); // Clear the file name
//   };

//   return (
//     <div className="image-selector-container">
//       <div className="upload-box" onClick={handleClick}>
//         <Upload />
//         <span
//           className="upload-text"
//           style={{
//             display: "inline-block", // Required to enable width and ellipsis
//             maxWidth: "150px", // Set a maximum width for the span
//             whiteSpace: "nowrap", // Prevent text wrapping
//             overflow: "hidden", // Hide overflow text
//             textOverflow: "ellipsis", // Show ellipsis for overflow text
//             paddingRight: "8px", // Add padding to the right for spacing
//             paddingLeft: "8px",
//           }}
//         >
//           {fileName ? fileName : placeholder}
//         </span>
//       </div>
//       <input
//         type="file"
//         accept="image/*"
//         ref={fileInputRef}
//         style={{ display: "none" }}
//         onChange={handleChange}
//       />
//       <div className="image-preview-area">
//         {imagePreview && (
//           <div className="image-preview-container">
//             {/* Buttons above the image */}
//             <div className="button-container">
//               <button
//                 className="edit-button"
//                 onClick={handleClick}
//                 style={{
//                   padding: "5px 10px", // Adjust padding for consistency
//                   marginRight: "8px", // Margin between buttons
//                 }}
//               >
//                 Edit
//               </button>
//               <button
//                 className="remove-button"
//                 onClick={handleRemove}
//                 style={{
//                   padding: "5px 10px", // Adjust padding for consistency
//                 }}
//               >
//                 Remove
//               </button>
//             </div>
//             <img src={imagePreview} alt="Preview" className="image-preview" />
//           </div>
//         )}
//       </div>
//     </div>
//   );
// };

// export default ImageSelector;
// ImageSelector.js

// import React, { useState, useRef } from "react";

// const ImageSelector = ({ onImageSelect }) => {
//   const [image, setImage] = useState({
//     file: null,
//   });

//   const fileInputRef = useRef(null);

//   const handleImageChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       const imageData = {
//         file: file,
//         previewURL: URL.createObjectURL(file),
//         name: file.name,
//         type: file.type,
//         size: file.size,
//       };
//       setImage(imageData);
//       if (onImageSelect) onImageSelect(imageData); // Pass image data to parent if callback is provided
//     }
//   };

//   const handleRemove = () => {
//     setImage({
//       file: null,
//       // previewURL: "",
//       // name: "",
//       // type: "",
//       // size: 0,
//     });
//     if (fileInputRef.current) {
//       fileInputRef.current.value = "";
//     }
//     if (onImageSelect) onImageSelect(null); // Notify parent of removal
//   };

//   const handleEdit = () => {
//     fileInputRef.current.click();
//   };

//   return (
//     <div className="image-selector">
//       <div
//         className="input-container"
//         style={{ width: "100%", display: "flex" }}
//       >
//         <div style={{ justifyContent: "flex-start" }}>
//           <input
//             type="file"
//             accept="image/*"
//             onChange={handleImageChange}
//             ref={fileInputRef}
//             className="input-file"
//           />
//         </div>
//       </div>
//       {image.previewURL && (
//         <div className="image-container">
//           <img src={image.previewURL} alt="Preview" className="image-preview" />
//           <div className="buttons-container">
//             <button className="edit-button" onClick={handleEdit}>
//               Edit
//             </button>
//             <button className="remove-button" onClick={handleRemove}>
//               Remove
//             </button>
//           </div>
//         </div>
//       )}

//       <style jsx>{`
//         .image-selector {
//           display: flex;
//           flex-direction: column;
//           align-items: center;
//         }

//         .input-file {
//           width: 100%;
//           padding: 0.5rem;
//           border: 1px solid #ccc;
//           border-radius: 5px;
//         }

//         .image-container {
//           display: flex;
//           flex-direction: column;
//           align-items: center;
//           margin-top: 10px !important;
//         }

//         .image-preview {
//           box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
//           border-radius: 5px;
//           max-width: 100%;
//           height: auto !important;
//           margin-bottom: 0.5rem;
//         }

//         .buttons-container {
//           display: flex;
//           gap: 0.7rem;
//         }

//         .edit-button,
//         .remove-button {
//           padding: 0.3rem 0.6rem;
//           border: none;
//           border-radius: 3px;
//           cursor: pointer;
//           width: 80px;
//         }

//         .edit-button {
//           background-color: #007bff;
//           color: #fff;
//         }

//         .remove-button {
//           background-color: #dc3545;
//           color: #fff;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default ImageSelector;

// import React, { useState, useRef } from "react";

// const ImageSelector = ({ onImageSelect }) => {
//   const [image, setImage] = useState(null); // Only store the file

//   const fileInputRef = useRef(null);

//   const handleImageChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       setImage(file); // Only store the file object
//       if (onImageSelect) onImageSelect(file); // Pass only the file to parent
//     }
//   };

//   const handleRemove = () => {
//     setImage(null); // Remove the image
//     if (fileInputRef.current) {
//       fileInputRef.current.value = ""; // Clear file input
//     }
//     if (onImageSelect) onImageSelect(null); // Notify parent of removal
//   };

//   const handleEdit = () => {
//     fileInputRef.current.click(); // Trigger file input click
//   };

//   return (
//     <div className="image-selector">
//       <div
//         className="input-container"
//         style={{ width: "100%", display: "flex" }}
//       >
//         <div style={{ justifyContent: "flex-start" }}>
//           <input
//             type="file"
//             accept="image/*"
//             onChange={handleImageChange}
//             ref={fileInputRef}
//             className="input-file"
//           />
//         </div>
//       </div>
//       {image && (
//         <div className="image-container">
//           <img
//             src={URL.createObjectURL(image)} // Create preview URL on the fly
//             alt="Preview"
//             className="image-preview"
//           />
//           <div className="buttons-container">
//             <button className="edit-button" onClick={handleEdit}>
//               Edit
//             </button>
//             <button className="remove-button" onClick={handleRemove}>
//               Remove
//             </button>
//           </div>
//         </div>
//       )}
//       <style jsx>{`
//         .image-selector {
//           display: flex;
//           flex-direction: column;
//           align-items: center;
//         }

//         .input-file {
//           width: 100%;
//           padding: 0.5rem;
//           border: 1px solid #ccc;
//           border-radius: 5px;
//         }

//         .image-container {
//           display: flex;
//           flex-direction: column;
//           align-items: center;
//           margin-top: 10px !important;
//         }

//         .image-preview {
//           box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
//           border-radius: 5px;
//           max-width: 100%;
//           height: auto !important;
//           margin-bottom: 0.5rem;
//         }

//         .buttons-container {
//           display: flex;
//           gap: 0.7rem;
//         }

//         .edit-button,
//         .remove-button {
//           padding: 0.3rem 0.6rem;
//           border: none;
//           border-radius: 3px;
//           cursor: pointer;
//           width: 80px;
//         }

//         .edit-button {
//           background-color: #007bff;
//           color: #fff;
//         }

//         .remove-button {
//           background-color: #dc3545;
//           color: #fff;
//         }
//       `}</style>
//     </div>
//   );
// };

// export default ImageSelector;

import React, { useState, useRef, useEffect } from "react";

const ImageSelector = ({ onImageSelect, s3ImageLink, id, onRemoveImage }) => {
  const [image, setImage] = useState(null); // Stores the selected file or S3 link
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (s3ImageLink) {
      setImage(s3ImageLink); // Update preview with the S3 image link
    }
  }, [s3ImageLink]);

  const handleImageChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setImage(file); // Store the file locally for preview
      if (onImageSelect) onImageSelect(file); // Pass the file to the parent
    }
  };

  // const handleRemove = () => {
  //   setImage(null); // Clear the image
  //   if (fileInputRef.current) {
  //     fileInputRef.current.value = ""; // Clear the input field
  //   }
  //   if (onImageSelect) onImageSelect(null); // Notify parent of removal
  // };
  const handleRemove = () => {
    setImage(null); // Clear the image
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the input field
    }
    if (onImageSelect) onImageSelect(null); // Notify parent of removal
    if (onRemoveImage) onRemoveImage(id); // Remove S3 link from DB
  };
  const handleEdit = () => {
    fileInputRef.current.click(); // Open file selection dialog
  };

  return (
    <div className="image-selector">
      <div
        className="input-container"
        style={{ width: "100%", display: "flex" }}
      >
        <div style={{ justifyContent: "flex-start" }}>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            ref={fileInputRef}
            className="input-file"
            style={{ display: s3ImageLink && id ? "none" : "block" }} // Hide input if S3 link is present
          />
        </div>

        {/* <div style={{ justifyContent: "flex-start" }}>
          <input
            type="file"
            accept="image/*"
            onChange={handleImageChange}
            ref={fileInputRef}
            className="input-file"
          />
        </div> */}
      </div>
      {image && (
        <div className="image-container">
          <img
            src={
              typeof image === "string"
                ? image // Show S3 link image
                : URL.createObjectURL(image) // Show local preview
            }
            alt="Preview"
            className="image-preview"
          />
          <div className="buttons-container">
            <button className="edit-button" onClick={handleEdit}>
              Edit
            </button>
            <button className="remove-button" onClick={handleRemove}>
              Remove
            </button>
          </div>
        </div>
      )}
      <style jsx>{`
        .image-selector {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .input-file {
          width: 100%;
          padding: 0.5rem;
          border: 1px solid #ccc;
          border-radius: 5px;
        }

        .image-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 10px !important;
        }

        .image-preview {
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          max-width: 100%;
          height: auto !important;
          margin-bottom: 0.5rem;
        }

        .buttons-container {
          display: flex;
          gap: 0.7rem;
        }

        .edit-button,
        .remove-button {
          padding: 0.3rem 0.6rem;
          border: none;
          border-radius: 3px;
          cursor: pointer;
          width: 80px;
        }

        .edit-button {
          background-color: #007bff;
          color: #fff;
        }

        .remove-button {
          background-color: #dc3545;
          color: #fff;
        }
      `}</style>
    </div>
  );
};

export default ImageSelector;
