import { Box } from "@mui/material";
import { useState } from "react";
import TabStrip from "../../../../molecules/tabstrip";
import Camera from "../../../../icons/Camera";
import Image from "../../../../icons/image";
import MultiImage from "../../../../icons/MultiImage";
import NotesAndImages from "./testImageNote";
import CameraCapture from "./cameraCapture";
import TestImageSelection from "./testImageSelection";

const TestStepImage = ({
  testStepData,
  setTestStepData,
  capturedImage5,
  setCapturedImage5,
  note25,
  setNote25,
  testImage3,
  setTestImage3,
  testNote3,
  setTestNote3,
  testNote33,
  setTestNote33,
}) => {
  const [activeTab, setActiveTab] = useState(0); // Default to the first tab (with id 0)

  const onTabChange = (event, value) => {
    setActiveTab(value); // Set active tab to the clicked tab's id
  };

  const getComponent = (tabId) => {
    const activeTabData = data.find((item) => item.id === tabId);
    return activeTabData ? activeTabData.view : null;
  };

  const data = [
    {
      id: 0,
      label: <Image />,
      view: (
        // {testStepData?.map((step, index) => (
        //   <NotesAndImages key={index} step={step} />
        // ))}
        <>
          {testStepData?.map((step, index) => (
            <NotesAndImages key={index} step={step} />
          ))}
        </>
        // <NotesAndImages
        //   notesData={testStepData}
        //   setNotesData={setTestStepData}
        // />
      ),
    },
    {
      id: 1,
      label: <Camera />,
      view: (
        <CameraCapture
          capturedImage={capturedImage5}
          setCapturedImage={setCapturedImage5}
          note22={note25}
          setNote22={setNote25}
        />
      ),
    },
    {
      id: 2,
      label: <MultiImage />,
      view: (
        <TestImageSelection
          setTestNote={setTestNote3}
          testNote={testNote3}
          setTestImage={setTestImage3}
          testImage={testImage3}
        />
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TabStrip
          activeTab={activeTab}
          onTabChange={onTabChange}
          data={data.map((tab) => ({ id: tab.id, label: tab.label }))}
          method="id"
        />
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ p: 2 }}>{getComponent(activeTab)}</Box>
      </Box>
    </>
  );
};

export default TestStepImage;
