import React, { useState } from "react";
import ImageSelector from "../../../../atoms/ImageSelector/ImageSelector";
import { postFormData } from "../../../../../services";
import { useCookies } from "react-cookie";
import MyTextInput from "../../../../atoms/MyTextInput";

const TestImageSelection = ({
  testImage,
  setTestImage,
  testNote,
  setTestNote,
  onSave, // Callback to save the note and image
}) => {
  const [cookies] = useCookies(["t"]);
  const [isLoading, setIsLoading] = useState(false);
  const [tempImage, setTempImage] = useState(testImage); // Temporary state for image
  const [tempNote, setTempNote] = useState(testNote); // Temporary state for note

  const handleImageSelection = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      setIsLoading(true);
      const response = await postFormData({
        endpoint: "vessel/upload-file",
        data: formData,
        token: cookies.t,
      });

      const s3Link = response?.data?.data?.s3UploadedLink;
      if (s3Link) {
        setTempImage(s3Link); // Update tempImage with S3 link
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSave = () => {
    setTestImage(tempImage);
    setTestNote(tempNote);
    if (onSave) onSave(tempImage, tempNote); // Notify parent if a callback exists
  };

  return (
    <div className="vessel-photo">
      <ImageSelector
        onImageSelect={handleImageSelection}
        s3ImageLink={tempImage}
        onRemoveImage={() => {
          setTempImage(null);
          setTempNote(""); // Clear tempNote when the image is removed
        }}
      />
      {tempImage && (
        <div style={{ marginTop: "10px" }}>
          <MyTextInput
            type="text"
            name="note"
            customholder="Note"
            value={tempNote}
            onChange={(e) => setTempNote(e.target.value)}
            style={{ width: "100%" }}
          />
        </div>
      )}
      <div style={{ height: "10px" }}></div>

      {tempImage && (
        <button
          onClick={handleSave}
          style={{
            ...styles.submitButton,
            // marginTop: "10px !important",
          }}
        >
          Upload
        </button>
      )}
    </div>
  );
};

const styles = {
  submitButton: {
    marginTop: "16px",
    padding: "8px 16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default TestImageSelection;
