// import React, { useCallback, useEffect } from "react";
// import { Form, Formik, Field } from "formik";
// import toast from "react-hot-toast";
// import * as Yup from "yup";
// import Error from "../../../atoms/Error";
// import { confimationStyles } from "../../../../assets/styles/toast";
// import { getData, patchData } from "../../../../services";
// import { useCookies } from "react-cookie";
// import PageHeader from "../../../atoms/PageHeader";
// import PageCard from "../../../atoms/PageCard";
// import InputLayout from "../../../atoms/InputLayout";

// const Edit = () => {
//   const [cookies] = useCookies(["t", "uid"]);

//   const initialData = {};

//   const getProfileData = useCallback(async () => {
//     try {
//       const res = await getData({
//         endpoint: "user/getUserById",
//         params: {
//           userId: cookies.uid,
//         },
//         token: cookies.t,
//       });

//       // if (res) {
//       //   setFormData(res?.data?.data);
//       // }
//       if (res) {
//         console.log("res------->", res);
//       }
//     } catch (error) {
//       console.error(error); // Use console.error for errors
//     }
//   }, [cookies.t]);

//   useEffect(() => {
//     getProfileData();
//   }, [getProfileData]);

//   const validationSchema = Yup.object().shape({});
//   return (
//     <Formik
//       initialValues={initialData}
//       validationSchema={validationSchema}
//       enableReinitialize
//       validateOnBlur
//       validateOnChange
//       validateOnMount
//     >
//       {({
//         dirty,
//         isValid,
//         values,
//         errors,
//         touched,
//         setFieldTouched,
//         setFieldValue,
//         handleBlur,
//       }) => (
//         <>
//           <PageHeader title="Edit Profile" />

//           <PageCard classes="w-70">
//             <div className="row">
//               <InputLayout>
//                 <label htmlFor="name">Name</label>
//                 <Field
//                   type="text"
//                   id="name"
//                   name="name"
//                   placeholder="Enter Name"
//                 />
//               </InputLayout>

//               <InputLayout>
//                 <label htmlFor="email">Email</label>
//                 <Field
//                   type="text"
//                   id="email"
//                   name="email"
//                   placeholder="Enter Email"
//                 />
//               </InputLayout>

//               <InputLayout>
//                 <label htmlFor="role">Role</label>
//                 <Field
//                   type="text"
//                   id="role"
//                   name="role"
//                   placeholder="Enter Role"
//                 />
//               </InputLayout>
//             </div>

//             <div className="d-flex justify-content-end mt-3">
//               <button
//                 type="submit"
//                 className="btn btn-primary"
//                 disabled={!isValid}
//               >
//                 Update
//               </button>
//             </div>
//           </PageCard>
//         </>
//       )}
//     </Formik>
//   );
// };

// export default Edit;

import React, { useCallback, useEffect, useState } from "react";
import { Formik, Field, Form } from "formik";
import toast from "react-hot-toast";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import PageHeader from "../../../atoms/PageHeader";
import PageCard from "../../../atoms/PageCard";
import InputLayout from "../../../atoms/InputLayout";
import { getData, patchData } from "../../../../services";

const Edit = () => {
  const [cookies] = useCookies(["t", "uid"]);
  const navigate = useNavigate();
  const [initialData, setInitialData] = useState({
    name: "",
    email: "",
    role: "",
  });
  const [isSaving, setIsSaving] = useState(false);

  const getProfileData = useCallback(async () => {
    try {
      const res = await getData({
        endpoint: "adminAuth/getUserById",
        params: {
          id: cookies.uid,
        },
        token: cookies.t,
      });

      if (res) {
        const userData = res.data;
        setInitialData({
          name: userData.name || "",
          email: userData.email || "",
          role: userData.role || "",
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [cookies.t, cookies.uid]);

  useEffect(() => {
    getProfileData();
  }, [getProfileData]);

  const handleUpdate = async (values) => {
    setIsSaving(true);
    const transformedData = {
      name: values.name,
      email: values.email,
      role: values.role,
      // Any other data transformation if required
    };

    try {
      const res = await patchData({
        endpoint: "adminAuth/updateUser", // Dummy endpoint
        params: {
          id: cookies.uid,
        },
        token: cookies.t,
        data: transformedData,
      });

      if (res?.status) {
        toast.success("Profile Updated Successfully", {
          style: {
            background: "#4caf50",
            color: "#fff",
          },
          duration: 1000,
        });
        navigate("/dashboard/edit-profile");
      } else {
        toast.error(res?.data?.message, {
          style: {
            background: "#f44336",
            color: "#fff",
          },
          duration: 1000,
        });
      }
    } catch (error) {
      console.error(error);
      toast.error("Failed to update profile", {
        style: {
          background: "#f44336",
          color: "#fff",
        },
        duration: 1000,
      });
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <Formik
      initialValues={initialData}
      enableReinitialize
      onSubmit={handleUpdate}
    >
      {({ isValid, dirty }) => (
        <>
          <PageHeader title="Profile" />

          <PageCard classes="w-70">
            <Form className="row">
              <InputLayout>
                <label htmlFor="name">Name</label>
                <Field
                  type="text"
                  id="name"
                  name="name"
                  placeholder="Enter Name"
                  // disabled
                />
              </InputLayout>

              <InputLayout>
                <label htmlFor="email">Email</label>
                <Field
                  type="text"
                  id="email"
                  name="email"
                  placeholder="Enter Email"
                  // disabled
                />
              </InputLayout>

              <InputLayout>
                <label htmlFor="role">Role</label>
                <Field
                  type="text"
                  id="role"
                  name="role"
                  placeholder="Enter Role"
                  disabled // Make the role field non-editable
                />
              </InputLayout>

              <div className="d-flex justify-content-end mt-3">
                <button
                  type="submit"
                  className="btn btn-primary"
                  disabled={!isValid || !dirty || isSaving}
                >
                  {isSaving ? "Updating..." : "Update"}
                </button>
              </div>
            </Form>
          </PageCard>
        </>
      )}
    </Formik>
  );
};

export default Edit;
