import React, { useEffect, useState } from "react";
import Accordion from "../../../../molecules/accordion";
import Exclamation from "../../../../icons/Exclamation";
import Info from "../../../../icons/Info";
import Checklist from "../../../../icons/Checklist";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import { getData, patchData, postData } from "../../../../../services";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import TestDetails from "./testDetails";
import TestProcedure from "./testProcedure";
import TrialTest from "./trialTests";
import Camera from "../../../../icons/Camera";
import GeneralSetup from "./generalSetup";
import Recommendation from "./recommendation";
import TestImage from "./testImage";
import Shield from "../../../../icons/Shield";
import Media from "../../../../icons/Media";
import SupportingMedia from "./supportingMedia";
import Ship from "../../../../icons/Ship";
import Trust from "../../../../icons/Trust";

const AddTest = ({ type }) => {
  const [cookies] = useCookies(["t"]);
  const navigate = useNavigate();
  const { trial_id, test_id, vessel_id } = useParams();
  const [testData, setTestData] = useState({});
  const [status, setStatus] = useState("In Progress");

  console.log("type---->", type);

  // State for Test Details
  const [testDetails, setTestDetails] = useState({
    // title: "K-Cheif 700 Field Station FS33 Failures",
    title: "",
    testId: "",
    fmeaReference: "",
    onDp: "",
    system: "",
    subsystem: "",
    objective: "",
    imagesRequired: false,
    performedByCrew: false,
  });

  const [testImage, setTestImage] = useState(null);
  const [testNote, setTestNote] = useState("");

  const [selectedFile, setSelectedFile] = useState(null);
  const [fileNote, setFileNote] = useState("");

  console.log("testNote---->", testNote);
  console.log("testImage---->", testImage);

  const [methodFields, setMethodFields] = useState([
    // {
    //   method: "",
    //   expected_result: "",
    //   notes: "",
    //   result: "",
    //   is_notes_visible: false,
    // },
  ]);
  const [recommendationRows, setRecommendationRows] = useState([
    // {
    //   sno: 1,
    //   number: "",
    //   description: "",
    //   type: "",
    //   status: "",
    //   recommendations: [],
    // },
  ]);

  console.log("recommendationRows-->", recommendationRows);

  const [notesData, setNotesData] = useState([
    // {
    //   note: "",
    //   image_link: null,
    // },
  ]);

  const [capturedImage, setCapturedImage] = useState(null);
  const [capturedImage1, setCapturedImage1] = useState(null);
  const [note23, setNote23] = useState(""); // State to store the note entered by the user
  const [note22, setNote22] = useState(""); // State to store the note entered by the user

  const [supportingMediaRows, setSupportingMediaRows] = useState([
    // {
    //   note: "",
    //   filename: "",
    // },
  ]);

  useEffect(() => {
    if (testNote && testImage) {
      setNotesData((prevNotes) => [
        ...prevNotes,
        { note: testNote, image_link: testImage },
      ]);
    }
    if (capturedImage) {
      setNotesData((prevNotes) => [
        ...prevNotes,
        { note: testNote, image_link: capturedImage },
      ]);
    }
  }, [testNote, testImage, capturedImage]); // Dependency array watches for changes in testNote and testImage

  useEffect(() => {
    if (fileNote && selectedFile) {
      setSupportingMediaRows((prevNotes) => [
        ...prevNotes,
        { note: fileNote, file_link: selectedFile },
      ]);
    }
    if (capturedImage1) {
      setSupportingMediaRows((prevNotes) => [
        ...prevNotes,
        { note: note23, file_link: capturedImage1 },
      ]);
    }
  }, [fileNote, selectedFile, capturedImage1, note23]); // Dependency array watches for changes in testNote and testImage

  console.log("testImage", testImage);
  console.log("testNote", testNote);
  console.log("notesData", notesData);
  const [checkboxState, setCheckboxState] = useState({}); // Track checkbox states
  console.log("checkboxState--->", checkboxState);

  const notesDataCount = notesData?.length;

  console.log("methodField---->", methodFields);
  const [setup, setSetup] = useState("");
  const [testRemarks, setTestRemarks] = useState("");
  const [completeDate, setCompleteDate] = useState(null);
  const [witnessedBy, setWitnessedBy] = useState("");

  console.log("status--->", status);

  useEffect(() => {
    setTestDetails((prev) => ({
      ...prev, // Spread the previous state to retain existing values
      title: testData?.test_title || "",
      fmeaReference: testData?.fmea_reference || "",
      testId: testData?.test_id || "",
      onDp: testData?.on_dp || "",
      system: testData?.system || "",
      subsystem: testData?.sub_system || "",
      objective: testData?.objective || "",
      imagesRequired: testData?.are_images_required || false,
      performedByCrew: testData?.performed_by_crew || false,
    }));
    setSetup(testData?.setup || "");
    setStatus(testData?.test_status || "");
    setTestRemarks(testData?.test_remarks || "");
    setCompleteDate(testData?.complete_date || null);
    setWitnessedBy(testData?.witnessed_by || "");
    if (testData?.method_fields) {
      setMethodFields(
        testData?.method_fields.map((row) => ({
          method: row.method || "",
          expected_result: row.expected_result || "",
          notes: row.notes || "",
          result: row.result || "",
          is_notes_visible: row.is_notes_visible || false,
        })),
      );
    }
    if (testData?.recommendation_rows) {
      setRecommendationRows(
        testData?.recommendation_rows.map((row, index) => ({
          sno: row.sno || index + 1,
          number: row.number || "",
          description: row.description || "",
          type: row.type || "",
          status: row.status || "",
          recommendations: row.recommendations || [],
        })),
      );
    }
    if (testData?.image_notes) {
      setNotesData(
        testData.image_notes.map((item) => ({
          image_link: item.image_link,
          note: item.note,
        })),
      );
    }
    if (testData?.file_notes) {
      setSupportingMediaRows(
        testData.file_notes.map((item) => ({
          file_link: item.file_link,
          note: item.note,
        })),
      );
    }
  }, [testData]);

  useEffect(() => {
    if (type === "edit") {
      fetchTestData();
    } else {
      // fetchAllTrialTest();
    }
  }, []);

  const [validationErrors, setValidationErrors] = useState({});
  console.log("validationErrors", validationErrors);

  const validateFields = () => {
    const errors = {};

    // Validate Setup
    if (!setup.trim()) errors.setup = "Setup is required";

    console.log("methods", methodFields);
    // Validate Method Fields
    methodFields.forEach((field, index) => {
      console.log("filed", field);
      if (!field.method.trim()) errors[`method${index}`] = "Method is required";
      if (!field.expected_result.trim())
        errors[`expectedResult${index}`] = "Expected Result is required";
      if (!field.result.trim()) errors[`result${index}`] = "Result is required";
    });

    // Validate Test Remarks
    if (!testRemarks.trim()) errors.testRemarks = "Test Remarks are required";

    // Validate Witnessed By
    if (!witnessedBy.trim()) errors.witnessedBy = "Witnessed By is required";

    // Validate Complete Date
    if (!completeDate) errors.completeDate = "Complete Date is required";

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // console.log("validation", validateFields());
    // if (!validateFields()) return;

    const payload = {
      test_title: testDetails?.title,
      test_id: testDetails?.testId,
      fmea_reference: testDetails?.fmeaReference,
      on_dp: testDetails?.onDp,
      system: testDetails?.system,
      sub_system: testDetails?.subsystem,
      objective: testDetails?.objective,
      are_images_required: testDetails?.imagesRequired,
      performed_by_crew: testDetails?.performedByCrew,
      test_status: type === "edit" ? "In Progress" : "Draft",
      setup: setup,
      test_remarks: testRemarks,
      witnessed_by: witnessedBy,
      complete_date: completeDate,
      method_fields: methodFields,
      recommendation_rows: recommendationRows.map((row) => ({
        sno: row.sno,
        number: row.number,
        description: row.description,
        type: row.type,
        status: row.status,
        recommendations: row.recommendations,
      })),
      image_notes: notesData,
      file_notes: supportingMediaRows,
    };

    console.log("payload---->", payload);

    if (type === "edit") {
      try {
        // Make API call using postData
        const result = await patchData({
          endpoint: "trialtest/update",
          data: payload,
          params: { id: test_id },
          token: cookies.t,
        });

        console.log("result----->", result?.data?.data?.id);

        if (result) {
          toast.success(`Trial Test Updated successfully`, {
            style: confimationStyles,
            duration: 2000,
          });

          fetchTestData(result?.data?.data?.id);
        }

        console.log("API Response:", result);
      } catch (error) {
        console.error("Error submitting vessel information:", error);
        //   toast.error("Test data not found");
        toast.error(error, {
          style: errorStyles,
          duration: 1000,
        });
      }
    } else {
      try {
        // Make API call using postData
        const result = await postData({
          endpoint: "trialtest/create",
          data: payload,
          token: cookies.t,
          params: { id: trial_id },
        });

        console.log("result----->", result?.data?.data?.id);

        if (result) {
          toast.success(`Trial Test added successfully`, {
            style: confimationStyles,
            duration: 2000,
          });
          navigate(`/dashboard/Trial/${vessel_id}/${trial_id}`);

          //   fetchVesselData(result?.data?.data?.id);
        }

        console.log("API Response:", result);
      } catch (error) {
        console.error("Error submitting trial test information:", error);
        //   toast.error("Test data not found");
        toast.error(error, {
          style: errorStyles,
          duration: 1000,
        });
      }
    }
  };
  const handleSubmit1 = async (e) => {
    // e.preventDefault();
    console.log("statususususu", status);
    console.log("validation", validateFields());
    if (!validateFields()) return;

    const payload = {
      test_title: testDetails?.title,
      test_id: testDetails?.testId,
      fmea_reference: testDetails?.fmeaReference,
      on_dp: testDetails?.onDp,
      system: testDetails?.system,
      sub_system: testDetails?.subsystem,
      objective: testDetails?.objective,
      are_images_required: testDetails?.imagesRequired,
      performed_by_crew: testDetails?.performedByCrew,
      test_status: "Complete",
      setup: setup,
      test_remarks: testRemarks,
      witnessed_by: witnessedBy,
      complete_date: completeDate,
      method_fields: methodFields,
      recommendation_rows: recommendationRows.map((row) => ({
        sno: row.sno,
        number: row.number,
        description: row.description,
        type: row.type,
        status: row.status,
        recommendations: row.recommendations,
      })),
      image_notes: notesData,
      file_notes: supportingMediaRows,
    };

    console.log("payload---->", payload);

    if (type === "edit") {
      try {
        // Make API call using postData
        const result = await patchData({
          endpoint: "trialtest/update",
          data: payload,
          params: { id: test_id },
          token: cookies.t,
        });

        console.log("result----->", result?.data?.data?.id);

        if (result) {
          toast.success(`Trial Test Updated successfully`, {
            style: confimationStyles,
            duration: 2000,
          });

          fetchTestData(result?.data?.data?.id);
        }

        console.log("API Response:", result);
      } catch (error) {
        console.error("Error submitting vessel information:", error);
        //   toast.error("Test data not found");
        toast.error(error, {
          style: errorStyles,
          duration: 1000,
        });
      }
    } else {
      try {
        // Make API call using postData
        const result = await postData({
          endpoint: "trialtest/create",
          data: payload,
          token: cookies.t,
          params: { id: trial_id },
        });

        console.log("result----->", result?.data?.data?.id);

        if (result) {
          toast.success(`Trial Test added successfully`, {
            style: confimationStyles,
            duration: 2000,
          });

          //   fetchVesselData(result?.data?.data?.id);
        }

        console.log("API Response:", result);
      } catch (error) {
        console.error("Error submitting trial test information:", error);
        //   toast.error("Test data not found");
        toast.error(error, {
          style: errorStyles,
          duration: 1000,
        });
      }
    }
  };

  const fetchTestData = async () => {
    console.log("id------>", trial_id);
    try {
      const res = await getData({
        endpoint: "trialtest/getTestDetailsByTestId",
        token: cookies.t,
        params: { id: test_id },
      });
      setTestData(res?.data || {});
    } catch (err) {
      console.log("Error fetching test data:", err);
      setTestData({}); // Ensure vesselData is empty on error
    }
  };

  const [vesselData, setVesselData] = useState({});
  const [trialData, setTrialData] = useState({});

  const fetchVesselData = async () => {
    console.log("id------>", trial_id);
    try {
      const res = await getData({
        endpoint: "vessel/getVesselDetailsById",
        token: cookies.t,
        params: { id: vessel_id },
      });
      setVesselData(res?.data || {});
    } catch (err) {
      console.log("Error fetching vessel data:", err);
      setVesselData({}); // Ensure vesselData is empty on error
    }
  };

  const fetchTrialData = async () => {
    console.log("id------>", trial_id);
    try {
      const res = await getData({
        endpoint: "vesselTrial/getTrialDetailsById",
        token: cookies.t,
        params: { id: trial_id },
      });
      setTrialData(res?.data || {});
    } catch (err) {
      console.log("Error fetching vessel data:", err);
      setTrialData({}); // Ensure vesselData is empty on error
    }
  };

  console.log("vesselData", vesselData);
  useEffect(() => {
    fetchVesselData();
    fetchTrialData();
  }, [vessel_id, test_id, trial_id]);

  console.log("testDetails", testDetails);

  let totalRecommendations = 0;

  const filteredData = recommendationRows?.filter((item) => {
    if (checkboxState["3"] === true) {
      // Show both "Opened" and "Closed"
      return true;
    }
    // Show only "Opened" when filterClosed is undefined or false
    return item.status === "Opened";
  });

  totalRecommendations = filteredData?.length;

  const accordionData2 = [
    {
      title: type === "edit" ? testDetails?.title : "Test Details",
      icon: type === "edit" ? <Shield /> : <Info />,
      content: (
        <TestDetails
          testDetails={testDetails}
          setTestDetails={setTestDetails}
          type={type}
        />
      ),
    },
    {
      title: "Test Procedure",
      icon: <Checklist />,
      content: (
        <TestProcedure
          methodFields={methodFields}
          setMethodFields={setMethodFields}
          setup={setup}
          setSetup={setSetup}
          testRemarks={testRemarks}
          setTestRemarks={setTestRemarks}
          completeDate={completeDate}
          setCompleteDate={setCompleteDate}
          witnessedBy={witnessedBy}
          setWitnessedBy={setWitnessedBy}
          type={type}
          validationErrors={validationErrors}
          setValidationErrors={setValidationErrors}
        />
      ),
    },
    {
      title: "Image",
      icon: <Camera />,
      content: (
        // <TestProcedure
        //   testProcedure={testProcedure}
        //   setTestProcedure={setTestProcedure}
        // />
        <TestImage
          setTestNote={setTestNote}
          testNote={testNote}
          setTestImage={setTestImage}
          testImage={testImage}
          setNotesData={setNotesData}
          notesData={notesData}
          capturedImage={capturedImage}
          setCapturedImage={setCapturedImage}
          note22={note22}
          setNote22={setNote22}
        />
      ),
      count: notesDataCount,
    },
    {
      title: "Recommendations",
      icon: <Exclamation />,
      content: (
        // <TestProcedure
        //   testProcedure={testProcedure}
        //   setTestProcedure={setTestProcedure}
        // />
        <Recommendation
          recommendationRows={recommendationRows}
          setRecommendationRows={setRecommendationRows}
          filterClosed={checkboxState["3"]}
        />
      ),
      // count: totalRecommendations,
      count: recommendationRows?.length,
      // checkbox: true,
      // checkboxText: "Show Closed",
    },
    {
      title: "Test Supporting Media",
      icon: <Media />,
      content: (
        <SupportingMedia
          capturedImage1={capturedImage1}
          setCapturedImage1={setCapturedImage1}
          supportingMediaRows={supportingMediaRows}
          setSupportingMediaRows={setSupportingMediaRows}
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          fileNote={fileNote}
          setFileNote={setFileNote}
          note23={note23}
          setNote23={setNote23}
        />
      ),
      count: supportingMediaRows?.length,
    },
  ];

  console.log("testData?.title", testData?.test_title);

  return (
    <>
      <div
        style={{
          // display: "flex",
          // alignItems: "center", // Vertically centers the items
          // justifyContent: "space-between", // Spaces out items
          // padding: "10px", // Optional: Add some padding around the container
          // //   backgroundColor: "#f8f9fa", // Optional: Add a background color
          // borderRadius: "4px", // Optional: Add rounded corners
          // marginBottom: "5px",
          // //   backgroundColor: "#f2f2f2",s
          display: "flex",
          alignItems: "center", // Vertically centers the items
          justifyContent: "space-between", // Spaces out items
          paddingTop: "10px", // Optional: Add some padding around the container
          // background: "#f2f2f2", // Optional: Add a background color
          borderRadius: "4px", // Optional: Add rounded corners
          backgroundColor: "white",
          position: "sticky",
          top: "0px",
          marginTop: "-25px",
          marginBottom: "12px",
          zIndex: "99",
          paddingBottom: "10px",
          // top: "30px",
        }}
      >
        {/* <Breadcrumb
          labels={[
            `${vesselData?.vessel_name || "Vessel"}`,
            `${trialData?.trials || "Trial"}`,
            `${testData?.test_title || ""}`,
          ]}
          logos={[
            <Ship />, // Logo for "Vessels"
            <Trust />, // Logo for "Edit Trial"
          ]}
        /> */}
        <Breadcrumb
          labels={[
            `${vesselData?.vessel_name || "Vessel"}`,
            `${trialData?.trials || "Trial"}`,
            `${testData?.test_title || "Add Test"}`,
          ]} // Labels for the breadcrumb
          logos={[
            <Ship width="20px" height="20px" />, // Logo for "Vessels"
            <Trust width="20px" height="20px" />, // Logo for "Edit Trial"
            null, // No logo for "Settings"
          ]}
          paths={[
            `/dashboard/vessel/${vessel_id}`,
            `/dashboard/Trial/${vessel_id}/${trial_id}`,
            `/dashboard/Trial/${vessel_id}/${trial_id}/${test_id} `,
          ]} // Paths for each breadcrumb
          excludeLastPath={false}
          stepsToRemove={0}
        />

        <div style={{ display: "flex", marginLeft: "-15px" }}>
          <button
            // onClick={handleSubmit}
            onClick={() => {
              handleSubmit1();
            }}
            style={{
              ...styles.submitButton,
              marginTop: "0px !important",
              marginRight: "10px",
            }}
          >
            Complete
          </button>
          <button
            onClick={handleSubmit}
            style={{
              ...styles.submitButton,
              marginTop: "0px !important",
            }}
          >
            Save
          </button>
        </div>
      </div>
      <Accordion
        items={accordionData2}
        setCheckboxState={setCheckboxState}
        checkboxState={checkboxState}
      />
    </>
  );
};

const styles = {
  submitButton: {
    marginTop: "16px",
    padding: "8px 16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default AddTest;
