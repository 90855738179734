const Progress = ({ width = "24", height = "24" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      id="Layer_1"
      data-name="Layer 1"
      viewBox="0 0 24 24"
      width={width}
      height={height}
    >
      <path d="M2.847,17.365c-.186,.076-.378,.111-.566,.111-.592,0-1.152-.352-1.39-.933-.219-.536-.4-1.093-.539-1.654-.199-.804,.292-1.617,1.097-1.815,.799-.2,1.616,.292,1.815,1.097,.104,.42,.24,.836,.404,1.238,.313,.767-.055,1.643-.821,1.956ZM5.214,5.488c.342,0,.687-.116,.968-.354,.332-.281,.688-.541,1.057-.771,.701-.44,.914-1.366,.475-2.068-.44-.702-1.366-.911-2.068-.475-.489,.307-.96,.651-1.4,1.023-.633,.535-.712,1.481-.177,2.114,.297,.351,.72,.531,1.146,.531ZM1.442,10.963c.12,.029,.239,.043,.356,.043,.676,0,1.289-.459,1.456-1.145,.104-.423,.237-.839,.399-1.238,.312-.768-.058-1.643-.825-1.954-.771-.312-1.643,.06-1.954,.825-.218,.535-.397,1.093-.534,1.656-.196,.806,.297,1.617,1.102,1.812Zm5.822,8.692c-.367-.229-.724-.486-1.057-.767-.634-.535-1.581-.451-2.113,.182-.533,.634-.452,1.58,.182,2.113,.443,.373,.917,.717,1.406,1.021,.246,.153,.52,.226,.79,.226,.501,0,.991-.251,1.275-.709,.437-.704,.221-1.629-.483-2.065Zm16.735-7.655C24,5.709,19.131,.543,12.964,.049c-.036-.005-.677-.049-.964-.049s-.573,.01-.856,.03c-.826,.059-1.448,.775-1.39,1.603,.056,.79,.715,1.394,1.494,1.394,.036,0,.535-.026,.752-.026,4.963,0,9,4.038,9,9,0,4.802-3.783,8.726-8.524,8.976-.371,.019-.748,.028-1.116,.002-.812-.058-1.543,.563-1.603,1.39s.562,1.544,1.39,1.603c.281,.021,.565,.03,.853,.03,.297,0,.951-.045,.985-.05,6.157-.504,11.015-5.666,11.015-11.95ZM12,6c-.829,0-1.5,.671-1.5,1.5v4.5h-2.623c-.788,0-1.185,.95-.632,1.511l4.202,4.258c.304,.308,.8,.308,1.104,0l4.202-4.258c.553-.561,.156-1.511-.632-1.511h-2.623V7.5c0-.829-.671-1.5-1.5-1.5Z" />
    </svg>
  );
};

export default Progress;
