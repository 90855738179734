import React, { useState } from "react";
import Trash from "../../../../icons/Trash";
import { DateSelector } from "../../../../molecules/MonthYearSelector";
import MyDropdown from "../../../../atoms/MyDropdown";
import MyTextInput from "../../../../atoms/MyTextInput";
import AddBold from "../../../../icons/AddBold";
import Modal from "../../../../organisms/modals/DeleteModal";
import CheckboxWrapper from "../../../../atoms/CheckBoxWrapper/Checkbox";
import DownloadBold from "../../../../icons/DownloadBold";
import { postFormData } from "../../../../../services";
import { useCookies } from "react-cookie";

const VesselDocuments = ({ documents, setDocuments }) => {
  const [cookies] = useCookies(["t"]);

  // const [documents, setDocuments] = useState([
  //   { id: 1, documentName: "", category: "", date: "", file: null },
  // ]);

  // Function to handle adding a new row
  const addRow = () => {
    setDocuments([
      ...documents,
      {
        id: documents.length + 1,
        document_name: "",
        category: "",
        date: "",
        document_link: null,
        third_party_visible: false,
        customer_visible: false,
      },
    ]);
  };
  console.log("documents---->", documents);
  const [isModalActive, setIsModalActive] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const handleDeleteRow = (index) => {
    const newRows = documents.filter((_, rowIndex) => rowIndex !== index);
    setDocuments(newRows);
    setIsModalActive(false); // Close modal after deletion
  };

  // Function to open the modal and set the selected row for deletion
  const confirmDeleteRow = (index) => {
    setSelectedRowIndex(index);
    setIsModalActive(true); // Show modal
  };

  console.log("documents---->", documents);

  // Function to handle input changes
  const handleChange = (id, field, value) => {
    const updatedDocs = documents.map((doc) =>
      doc.id === id ? { ...doc, [field]: value } : doc,
    );
    setDocuments(updatedDocs);
  };

  // Update for dropdown change: Correctly modify the specific document
  const handleDropdownChange = (id, field, option) => {
    const updatedDocs = documents.map((doc) =>
      doc.id === id ? { ...doc, [field]: option?.value } : doc,
    );
    setDocuments(updatedDocs);
  };

  // const downloadFile = (link) => {
  //   if (!link) {
  //     alert("File not available for download.");
  //     return;
  //   }

  //   const anchor = document.createElement("a");
  //   anchor.href = link;
  //   anchor.download = ""; // Optional: specify the file name
  //   document.body.appendChild(anchor);
  //   anchor.click();
  //   document.body.removeChild(anchor);
  // };
  // const downloadFile = (link) => {
  //   if (!link) {
  //     alert("File not available for download.");
  //     return;
  //   }

  //   const anchor = document.createElement("a");
  //   anchor.href = link;
  //   anchor.download = link.split("/").pop(); // Use the file name from the URL or specify one
  //   anchor.target = "_blank"; // This ensures it doesn't open in the same window
  //   document.body.appendChild(anchor);
  //   anchor.click();
  //   document.body.removeChild(anchor);
  // };
  // const downloadFile = (link) => {
  //   if (!link) {
  //     alert("File not available for download.");
  //     return;
  //   }

  //   const anchor = document.createElement("a");
  //   anchor.href = link;
  //   anchor.download = link.split("/").pop(); // Use the file name from the URL or specify one
  //   document.body.appendChild(anchor);
  //   anchor.click(); // Trigger download
  //   document.body.removeChild(anchor);
  // };
  const downloadFile = (link) => {
    if (!link) {
      alert("File not available for download.");
      return;
    }

    const anchor = document.createElement("a");
    anchor.href = link;
    anchor.download = link.split("/").pop(); // Use the file name from the URL or specify one
    anchor.target = "_blank"; // This ensures it doesn't open in the same window

    // Some browsers (like Chrome) allow images or PDFs to open in the browser by default. To force download:
    anchor.setAttribute("download", ""); // Force download in some browsers

    document.body.appendChild(anchor);
    anchor.click(); // Trigger download
    document.body.removeChild(anchor);
  };

  return (
    <div className="document-upload-container">
      <table>
        <thead>
          <tr>
            <th>No.</th>
            <th style={{ width: "25%" }}>Document Name</th>
            <th style={{ width: "12%" }}>Category</th>
            <th style={{ width: "15%" }}>Date</th>
            <th style={{ width: "10%" }}>Customer Visible</th>
            <th style={{ width: "10%" }}>Third Party Visible</th>
            <th>File Name</th>
          </tr>
        </thead>
        <tbody>
          {documents.map((document, index) => (
            <tr key={document.id}>
              <td>
                <b># {index + 1}</b>
              </td>
              <td>
                <MyTextInput
                  type="text"
                  name="document_name"
                  customholder="Document Name"
                  value={document.document_name}
                  onChange={(e) =>
                    handleChange(document.id, "document_name", e.target.value)
                  }
                />
              </td>

              <td>
                <div>
                  <MyDropdown
                    placeholder="Category"
                    data={[
                      { label: "Yes", value: "Yes" },
                      { label: "No", value: "No" },
                    ]}
                    format="all"
                    menuOpen={true}
                    name="type"
                    width="100%"
                    selectedValue={document.category}
                    onChange={(option) =>
                      handleDropdownChange(document.id, "category", option)
                    }
                  />
                </div>
              </td>

              <td style={{ height: "30px !important" }}>
                <div style={{ position: "relative" }}>
                  <DateSelector
                    selectedDate={document.date}
                    onChange={(date) => handleChange(document.id, "date", date)}
                    disableFuture={false}
                    disablePast={false}
                    placeholder="Select Date"
                  />
                </div>
              </td>

              <td style={{ textAlign: "left" }}>
                {/* <div>
                  <input
                    type="checkbox"
                    onChange={(e) =>
                      handleChange(
                        document.id,
                        "customerVisible",
                        e.target.checked,
                      )
                    }
                  />
                </div> */}
                <CheckboxWrapper
                  checked={document.customer_visible}
                  onChange={(e) =>
                    handleChange(
                      document.id,
                      "customer_visible",
                      e.target.checked,
                    )
                  }
                />
              </td>
              <td style={{ textAlign: "left" }}>
                {/* <input
                  type="checkbox"
                  onChange={(e) =>
                    handleChange(
                      document.id,
                      "thirdPartyVisible",
                      e.target.checked,
                    )
                  }
                /> */}
                <CheckboxWrapper
                  checked={document.third_party_visible}
                  onChange={(e) =>
                    handleChange(
                      document.id,
                      "third_party_visible",
                      e.target.checked,
                    )
                  }
                />
              </td>

              <td>
                <input
                  type="file"
                  className="input-file"
                  onChange={async (e) => {
                    const file = e.target.files[0]; // Get the selected file
                    if (file) {
                      const formData = new FormData();
                      formData.append("file", file);

                      try {
                        // Call the upload API
                        const response = await postFormData({
                          endpoint: "vessel/upload-file",
                          data: formData,
                          token: cookies.t, // Use your auth token
                        });

                        const s3Link = response?.data?.data?.s3UploadedLink;

                        if (s3Link) {
                          console.log("File uploaded successfully:", s3Link);
                          // Update the document with the uploaded S3 link
                          handleChange(document.id, "document_link", s3Link); // Update your state here
                        } else {
                          console.error("Error: S3 link not returned");
                        }
                      } catch (error) {
                        console.error("File upload failed:", error);
                      }
                    }
                  }}
                />
              </td>
              <td>
                {/* <button
                  className="download-button"
                  style={{ marginTop: "10px" }}
                >
                  <DownloadBold color="black" />
                </button> */}
                <button
                  className="download-button"
                  style={{ marginTop: "10px" }}
                  onClick={() => downloadFile(document.document_link)}
                  disabled={!document.document_link} // Disable if no link
                >
                  {/* <DownloadBold color="black" /> */}
                  <DownloadBold
                    color={document.document_link ? "black" : "grey"}
                  />{" "}
                  {/* Icon color changes */}
                </button>
              </td>
              <td>
                <button
                  className="delete-button"
                  onClick={() => confirmDeleteRow(index)}
                >
                  <Trash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <button onClick={addRow}>
        <AddBold width="24" height="24" />
      </button>
      {isModalActive && (
        <Modal
          isActive={isModalActive}
          onClose={() => setIsModalActive(false)} // Close modal without deleting
          title="Row"
          name={`Row ${selectedRowIndex + 1}`}
          onClick={() => handleDeleteRow(selectedRowIndex)} // Delete row when Confirm is clicked
          description="Are you sure you want to delete this row?"
        />
      )}

      {/* CSS Styles */}
      <style jsx>{`
        table {
          width: 100%;
          border-collapse: collapse;
        }
        th,
        td {
          padding: 10px;
          text-align: left;
          border: none;
        }
        input {
          border: 1px solid #ccc;
          padding: 5px;
          width: 100%;
          box-sizing: border-box;
        }
        button {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          color: white;
          border: none;
          border-radius: 40px;
          cursor: pointer;
          background: none;
        }

        .delete-button {
          background: none;
          border: none;
          cursor: pointer;
          color: red;
          padding: 0;
          margin: 0;
        }

        .delete-button:hover {
          color: darkred;
        }

        .custom-dropdown {
          appearance: none;
          width: 100%;
          padding: 5px;
          border: 1px solid #ccc;
          border-radius: 4px;
          background-color: white;
          font-size: 14px;
          cursor: pointer;
          box-sizing: border-box;
          background-image: url("data:image/svg+xml;charset=US-ASCII,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5'%3E%3Cpath fill='none' stroke='%23000' d='M0 0l2 2 2-2'/%3E%3C/svg%3E");
          background-repeat: no-repeat;
          background-position: right 10px center;
          background-size: 10px;
        }

        .custom-dropdown option {
          padding: 8px 10px;
          font-size: 14px;
          height: 30px;
        }

        .custom-dropdown option:hover {
          background-color: #f1f1f1;
        }

        .custom-dropdown:focus {
          outline: none;
          border-color: #007bff;
          box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
        }
        .input-file {
          max-height: 75px;
          padding: 8px;
          background: none;
        }
      `}</style>
    </div>
  );
};

export default VesselDocuments;
