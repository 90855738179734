import { useCallback, useEffect, useState } from "react";
import InfoCard from "../../../atoms/InfoCard";
import AdminIcon from "../../../icons/admin";
import Business from "../../../icons/Business";
import { deleteData, getData } from "../../../../services";
import { useCookies } from "react-cookie";
import PageCard from "../../../atoms/PageCard";
import Search from "../../../molecules/search";
import FilterComponent from "../../../molecules/filters/FilterComponent";
import CustomButton from "../../../atoms/CustomButton";
import { useNavigate } from "react-router-dom";
import NoContentIcon from "../../../icons/NocontentIcon";
import CustomDatagrid from "../../../molecules/CustomDatagrid";
import PageHeader from "../../../atoms/PageHeader";
import Pagination from "../../../molecules/Pagination";
import moment from "moment";
import Delete from "../../../icons/Delete";
import DeleteModal from "../../../organisms/modals/DeleteModal";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../assets/styles/toast";
import Trash from "../../../icons/Trash";

const Vessels = () => {
  const [infoCardData, setInfoCardData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchedValue, setSearchedValue] = useState("");
  const [data, setData] = useState([]);
  const [cookies] = useCookies(["t", "role"]);
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalData: null,
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });
  const navigate = useNavigate();

  console.log("modalState", modalState);

  // Fetch dashboard info card data
  const getDashBoardInfoCardData = async () => {
    try {
      const res = await getData({
        endpoint: "getAllDashboard",
        token: cookies.t,
      });
      setInfoCardData(res?.data);
    } catch (err) {
      console.log("err", err);
    }
  };

  // Fetch vessel data
  const fetchVesselData = async () => {
    try {
      const res = await getData({
        endpoint: "vessel/getAll",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchedValue,
        },
      });

      if (res) {
        console.log("res----->", res);
      }
      setData(res?.data || []);
    } catch (err) {
      console.log("Error fetching vessel data:", err);
      setData([]); // Ensure vesselData is empty on error
    }
  };

  const pageCount = data?.pageDetails?.count;

  useEffect(() => {
    getDashBoardInfoCardData();
    fetchVesselData();
  }, [itemsPerPage, currentPage, searchedValue]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  console.log("data?.vesselData?.length", data?.vesselData?.length);

  const handleDeletion = async () => {
    try {
      const res = await deleteData({
        endpoint: `vessel/deleteVessel`,
        params: {
          id: modalState.modalId, // Correct user_id being used here
        },
        token: cookies.t,
      });
      if (res?.data?.statusCode === 200) {
        toast.success("Vessel Deleted Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
      } else {
        toast.error(res?.data?.message, { style: errorStyles, duration: 2000 });
      }
      fetchVesselData(); // Refresh the data after successful deletion
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteModal = (vessel_id, name) => {
    console.log("vessel_id", vessel_id);
    console.log("name", name);
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: vessel_id, // Correct user_id being set here
      name: name,
    }));
  };

  const columns = [
    { field: "vessel_name", headerName: "Vessel", flex: 1 },
    { field: "industrial_mission", headerName: "Industrial Mission", flex: 1 },
    {
      field: "anniversary_date",
      headerName: "Anniversary Date/Last 5 year Trials",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>
              {moment(params.row?.anniversary_date).format("DD-MM-YYYY")}
            </span>
          </>
        );
      },
    },
    {
      field: "last_annual_trial_date",
      headerName: "Last Annual Trials",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>
              {moment(params.row?.last_annual_trial_date).format("DD-MM-YYYY")}
            </span>
          </>
        );
      },
    },
    // { field: "openAB", headerName: "Open A & B Recomm.", flex: 1 },
    // { field: "dmsActive", headerName: "DMS Active", flex: 1 },
    // { field: "dmsExpiration", headerName: "DMS Expiration", flex: 1 },
    {
      field: "year_of_construction",
      headerName: "Year of Construction",
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span
              className="me-2 pointer"
              onClick={() =>
                handleDeleteModal(params.row._id, params.row.vessel_name)
              }
            >
              {/* <Delete color="black" /> */}
              <Trash />
            </span>
          </>
        );
      },
    },
  ];

  return (
    <>
      <div className="header-infoCardWrap info_card_mbr">
        {modalState.isDeleteModalOpen && (
          <DeleteModal
            name={modalState.name}
            id={modalState.modalId}
            isActive={modalState.isDeleteModalOpen}
            onClose={() =>
              setModalState((prevState) => ({
                ...prevState,
                isDeleteModalOpen: false,
              }))
            }
            title="Vessel"
            onClick={handleDeletion}
          />
        )}
        {/* <div className="infoCardWrap">
          <InfoCard
            color="#B1E5FC"
            title="Total Vessels"
            data={infoCardData ? infoCardData?.surveyData : 0}
            svgIcon={<Business />}
            isSvg
          />
          <InfoCard
            color="#B1E5FC"
            title="Total Users"
            data={infoCardData ? infoCardData?.userData : 0}
            svgIcon={<AdminIcon />}
            isSvg
          />
        </div> */}
      </div>
      <PageHeader title="Vessels" type="large" />

      <PageCard>
        {/* <PageHeader title="Vessels" type="small" /> */}
        <div className="d-flex space-between gap-3-mbr">
          <Search
            // onSearchEmit={(value) => {
            //   // Implement search functionality if needed
            // }}
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
          />
          <div className="filter-add" style={{ marginBottom: "20px" }}>
            <FilterComponent
              filterData={[]}
              isDateRange
              dateRangeNames={["Survey Date", "Create Date"]}
              onFilterChange={(filter) => {
                // Implement filter functionality if needed
              }}
            />
            {atob(cookies.role) === "Admin" && (
              <div className="d-flex gap-3">
                <CustomButton
                  type="btn-primary"
                  text="Add Vessel"
                  handleClick={() => navigate("add")}
                  classes={"survery-btn-none-mbr"}
                />
              </div>
            )}
          </div>
        </div>

        {data && data?.vesselData && data?.vesselData?.length > 0 ? (
          <>
            <CustomDatagrid
              getRowId={(row) => row._id}
              rows={data?.vesselData}
              onCellClick={(data) => {
                const clickedColumnField = data?.field;
                console.log("clickedColumnField", data);
                const skipColumnField = "actions";

                if (clickedColumnField !== skipColumnField) {
                  navigate(`${data?.row._id}`, {
                    state: {},
                  });
                } else {
                  console.log("Skipping navigation for action column");
                }
              }}
              columns={columns}
              tHeight="50vh"
            />
            {pageCount && pageCount > 10 && (
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={pageCount}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <div>
              <NoContentIcon />
              <div
                className="graph-heading"
                style={{
                  fontSize: "18px",
                  marginTop: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                No Records Found
              </div>
            </div>
          </div>
        )}
      </PageCard>
    </>
  );
};

export default Vessels;
