// import React from "react";
// import { useLocation } from "react-router-dom";
// import { Document, Packer, Paragraph, TextRun } from "docx";
// import amcaLogo from "../../../../../assets/images/front.png";
// import PageCard from "../../../../atoms/PageCard";
// import PageHeader from "../../../../atoms/PageHeader";

// const DocFormat = () => {
//   const location = useLocation();
//   const { vesselData, trialData, testData } = location?.state || {};

//   const handleDownloadDoc = () => {
//     // Create a new Word document
//     const doc = new Document({
//       sections: [
//         {
//           properties: {},
//           children: [
//             new Paragraph({
//               children: [
//                 new TextRun({ text: "DP Trial Doc", bold: true, size: 32 }),
//               ],
//             }),
//             new Paragraph({ text: "" }), // Spacer
//             new Paragraph({ text: "Vessel Data", heading: "Heading2" }),
//             new Paragraph({
//               text: vesselData
//                 ? JSON.stringify(vesselData, null, 2)
//                 : "No vessel data available",
//             }),
//             new Paragraph({ text: "" }), // Spacer
//             new Paragraph({ text: "Trial Data", heading: "Heading2" }),
//             new Paragraph({
//               text: trialData
//                 ? JSON.stringify(trialData, null, 2)
//                 : "No trial data available",
//             }),
//             new Paragraph({ text: "" }), // Spacer
//             new Paragraph({ text: "Test Data", heading: "Heading2" }),
//             new Paragraph({
//               text: testData
//                 ? JSON.stringify(testData, null, 2)
//                 : "No test data available",
//             }),
//           ],
//         },
//       ],
//     });

//     Packer.toBlob(doc).then((blob) => {
//       const url = window.URL.createObjectURL(blob);
//       const link = document.createElement("a");
//       link.href = url;
//       link.download = "DP_Trial_Doc.docx";
//       link.click();
//       window.URL.revokeObjectURL(url);
//     });
//   };

//   return (
//     <>
//       <PageCard>
//         {/* <PageHeader title="DP Trial Doc" type="small" /> */}
//         <div style={{ height: "150px" }}>
//           <div style={{ display: "flex", justifyContent: "center" }}>
//             <img src={amcaLogo} alt="" style={{ width: "500px" }} />
//           </div>
//         </div>

//         <div style={{ fontSize: "24px", fontWeight: "bold", color: "#00035B" }}>
//           SEAMEC SWORDFISH
//         </div>
//         <div style={{ fontSize: "22px", fontWeight: "bold", color: "#00035B" }}>
//           Trials’ Summary – DP2 Annual Trials 2024
//         </div>
//         <div style={{ marginTop: "20px" }}>
//           Global Maritime witnessed the DP2 Annual Trials 2024 on the DP2 DP2
//           Supply vessel “Seamec Swordfish” from the 12/10/2024 to the
//           13/10/2024.
//         </div>
//         <div style={{ marginTop: "20px" }}>
//           The recommendations from the trials and related status are listed
//           below: these recommendations are subject to revision and quality
//           control by Global Maritime prior to issuing the final report. The
//           recommendations listed in the final report may deviate from the ones
//           given below.
//         </div>
//         <div style={{ marginTop: "20px" }}>
//           <b>A - Findings:</b> there are no findings in this category.{" "}
//         </div>
//         <div style={{ marginTop: "20px" }}>
//           <div style={{ marginTop: "20px" }}></div>
//           <b>B - Findings:</b> there are 4 findings in this category.
//           <ul>
//             <li style={{ marginTop: "10px" }}>
//               Recommendation B1: Reference is made to test No. 1. Engine Room DP
//               checklist correct mode is not matching the ASOG configuration. The
//               checklist shall be updated. The status of this finding is Closed.
//             </li>
//             <li style={{ marginTop: "10px" }}>
//               Recommendation B2: Reference is made to test No. 98. Spot Track
//               Positioning Test Not carried out due to non-availability of
//               external fixed target. The test Shall be carried out by the Master
//               and report shall be submitted. The status of this finding is
//               Opened.
//             </li>
//             <li style={{ marginTop: "10px" }}>
//               Recommendation B3: Reference is made to test No. 99. Taut Wire
//               Failures tests were not carried out due to existing error message
//               on the Taut Wire. The test shall be carried out by crew and
//               evidence shall be submitted. The status of this finding is Closed.
//             </li>
//             <li style={{ marginTop: "10px" }}>
//               Recommendation B4: Reference is made to test No. 100. Taut Wire
//               Positioning tests were not carried out due to existing error
//               message on the Taut Wire. The test shall be carried out by crew
//               and evidence shall be submitted. The status of this finding is
//               Closed.
//             </li>
//           </ul>
//         </div>
//         <div style={{ marginTop: "20px" }}>
//           <b>C - Findings:</b> there are 4 findings in this category.
//           <ul>
//             <li style={{ marginTop: "10px" }}>
//               Recommendation C1: Reference is made to test No. 79. UPS 1 Labels
//               are not matching with the correct Consumers. The status of this
//               finding is Closed.
//             </li>
//             <li style={{ marginTop: "10px" }}>
//               Recommendation C2: Reference is made to test No. 93. DGPS 1 IALA
//               Antena is not in use. The FMEA Documentation shall be corrected.
//               The status of this finding is Opened.
//             </li>
//             <li style={{ marginTop: "10px" }}>
//               Recommendation C3: Reference is made to test No. 73. Upon loss of
//               T4 & T 5 Steering command signal, the thruster RPM remains at 50.
//               The vessel maintained the position and heading; However, it is
//               causing additional thrust. The correct operational status/
//               configuration shall be verified and maintained. The status of this
//               finding is Closed.
//             </li>
//             <li style={{ marginTop: "10px" }}>
//               Recommendation C4: Reference is made to test No. 80. UPS 2 Breaker
//               Labels are not matching with the correct consumer. The status of
//               this finding is Closed.
//             </li>
//           </ul>
//         </div>
//         <div>
//           Recommendations are made in three categories based on the following
//           criteria:
//         </div>
//         <div>
//           <ul>
//             <li>
//               <b>‘A’</b> Recommendations are made where the failure effect
//               exceeds the Worst-Case Failure Design Intent, does not comply with
//               DP-2, or does not comply with IMO MSC 645. These recommendations
//               are for immediate action.
//             </li>
//             <li>
//               <b>‘B’</b> Recommendations are made where the failure effects can
//               equal the Worst-Case Failure Design Intent and/or there is scope
//               for improvement. The design complies with the requirements for
//               DP-2 but does not comply with industry guidance (e.g. IMCA). These
//               are for serious consideration.
//             </li>
//             <li>
//               <b>‘C’</b> Recommendations are observations, comments, and
//               suggestions associated with DP safety and reliability, which the
//               vessel operator may consider.
//             </li>
//           </ul>
//         </div>
//         <div>
//           On the basis of these trials, the vessel is considered compliant with
//           IMO Equipment Class DP2, and is considered capable of carrying out DP
//           operations if operated within the normal operational limits of the
//           vessel and in conditions not exceeding the DP capability after the
//           design and/or identified worst case failure.
//         </div>
//         <div style={{ marginTop: "20px" }}>
//           <span>For Global Maritime</span>
//           <br />
//           <span>Rakesh Ranjan</span>
//           <br />
//           <span>rranjan@amcamarine.com</span>
//         </div>
//       </PageCard>

//       <h1>DP Trial Doc</h1>

//       <div style={{ border: "1px solid black" }}>
//         <h2>Vessel Data</h2>
//         {vesselData ? (
//           <p>{JSON.stringify(vesselData)}</p>
//         ) : (
//           <p>No vessel data available</p>
//         )}
//         <h2>Trial Data</h2>
//         {trialData ? (
//           <p>{JSON.stringify(trialData)}</p>
//         ) : (
//           <p>No trial data available</p>
//         )}
//         <h2>Test Data</h2>
//         {testData ? (
//           <p>{JSON.stringify(testData)}</p>
//         ) : (
//           <p>No test data available</p>
//         )}
//       </div>
//       <button onClick={handleDownloadDoc} style={{ marginTop: "20px" }}>
//         Download as DOCX
//       </button>
//     </>
//   );
// };

// export default DocFormat;

// import React from "react";
// import {
//   Document,
//   Packer,
//   Paragraph,
//   TextRun,
//   PageBreak,
//   HeadingLevel,
//   Header,
//   ImageRun,
//   AlignmentType,
// } from "docx";
// import { saveAs } from "file-saver";

// const DocFormat = () => {
//   const generateDocx = async () => {
//     // Load the logo image
//     const logoBuffer = await fetch("/amcaLogo.png").then((res) =>
//       res.arrayBuffer(),
//     );

//     const doc = new Document({
//       sections: [
//         // Front page
//         {
//           children: [
//             new Paragraph({
//               children: [
//                 new TextRun({
//                   text: "Front Page",
//                   bold: true,
//                   size: 48,
//                 }),
//               ],
//               alignment: AlignmentType.CENTER,
//             }),
//             new Paragraph({ text: "" }), // Empty paragraph for spacing
//             new Paragraph({
//               children: [
//                 new TextRun({
//                   text: "This is the front page content.",
//                   size: 24,
//                 }),
//               ],
//               alignment: AlignmentType.CENTER,
//             }),
//           ],
//         },
//         // Table of contents section
//         {
//           children: [
//             new Paragraph({
//               text: "Table of Contents",
//               heading: HeadingLevel.HEADING_1,
//             }),
//             // Simulating TOC with manual heading links
//             new Paragraph({
//               text: "Front Page",
//               heading: HeadingLevel.HEADING_2,
//             }),
//             new Paragraph({
//               text: "Content",
//               heading: HeadingLevel.HEADING_2,
//             }),
//             new Paragraph({
//               text: "Content",
//               heading: HeadingLevel.HEADING_2,
//             }),
//             new PageBreak(),
//           ],
//         },
//         // Content pages with header
//         {
//           // headers: {
//           //   default: new Header({
//           //     children: [
//           //       new Paragraph({
//           //         children: [
//           //           new ImageRun({
//           //             data: logoBuffer,
//           //             transformation: { width: 50, height: 25 },
//           //           }),
//           //         ],
//           //         alignment: AlignmentType.RIGHT,
//           //       }),
//           //     ],
//           //   }),
//           // },
//           headers: {
//             default: new Header({
//               children: [
//                 new Paragraph({
//                   text: "Header Text",
//                   alignment: AlignmentType.RIGHT,
//                 }),
//               ],
//             }),
//           },

//           children: [
//             new Paragraph({
//               text: "Page 1 Content",
//               heading: HeadingLevel.HEADING_1,
//             }),
//             new Paragraph({ text: "This is content for page 1." }),
//             new PageBreak(),
//             new Paragraph({
//               text: "Page 2 Content",
//               heading: HeadingLevel.HEADING_1,
//             }),
//             new Paragraph({ text: "This is content for page 2." }),
//           ],
//         },
//       ],
//     });

//     // Generate and save the document
//     // const blob = await Packer.toBlob(doc);
//     // saveAs(blob, "Document.doc");
//     // console.log("Document created successfully!");
//     const packer = new Packer();
//     const mimeType =
//       "application/vnd.openxmlformats-officedocument.wordprocessingml.document";
//     // Create a Blob containing the Document instance and the mimeType
//     packer?.toBlob(doc).then((blob) => {
//       const docblob = blob.slice(0, blob.size, mimeType);
//       // Save the file using saveAs from the file-saver package
//       saveAs(docblob, "fileName.docx");
//     });
//   };

//   return (
//     <div style={{ textAlign: "center", marginTop: "50px" }}>
//       <h1 style={styles.title}>React Word Document Generator</h1>
//       <button style={styles.button} onClick={generateDocx}>
//         Generate Word Document
//       </button>
//     </div>
//   );
// };

// const styles = {
//   title: {
//     fontSize: "28px",
//     color: "#333",
//     fontFamily: "Arial, sans-serif",
//     marginBottom: "20px",
//   },
//   button: {
//     backgroundColor: "#4CAF50",
//     color: "white",
//     padding: "10px 20px",
//     border: "none",
//     borderRadius: "5px",
//     cursor: "pointer",
//     fontSize: "16px",
//   },
// };

// export default DocFormat;

import {
  Document,
  Packer,
  Paragraph,
  TextRun,
  Header,
  Footer,
  ImageRun,
  TableOfContents,
} from "docx";
import { saveAs } from "file-saver";
import { useLocation } from "react-router-dom";

const DocFormat = () => {
  const location = useLocation();
  const { vesselData, trialData, testData } = location?.state || {};
  console.log("vesselData", vesselData);
  console.log("trialData", trialData);
  console.log("testData", testData);

  const generateDoc = async () => {
    // Load logo image
    const logoUrl = "/amcaLogo.png"; // Replace with your logo's file path
    const logoBuffer = await fetch(logoUrl).then((res) => res.arrayBuffer());

    // Create the document
    const doc = new Document({
      sections: [
        // Front Page
        {
          headers: {
            default: new Header({
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: logoBuffer,
                      transformation: { width: 100, height: 50 },
                    }),
                  ],
                }),
              ],
            }),
          },
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: vesselData?.vessel_name || "", // Ensure it's a string
                  bold: true,
                  size: 48,
                }),
              ],
              alignment: "center",
            }),
          ],
        },
        // Table of Contents Section
        {
          children: [
            new Paragraph({
              children: [
                new TextRun({
                  text: "Table of Contents",
                  bold: true,
                  size: 36,
                }),
              ],
              alignment: "center",
            }),
            new TableOfContents("Table of Contents", {
              hyperlink: true,
              headingStyleRange: "1-3", // Include Heading 1 to Heading 3 in TOC
            }),
          ],
        },
        // Section 1
        {
          headers: {
            default: new Header({
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: logoBuffer,
                      transformation: { width: 50, height: 50 },
                    }),
                    new TextRun({
                      text: " Header Title",
                      bold: true,
                      size: 24,
                    }),
                  ],
                }),
              ],
            }),
          },
          footers: {
            default: new Footer({
              children: [
                new Paragraph({
                  children: [
                    new TextRun("Page "),
                    new TextRun({
                      children: ["PAGE"], // Placeholder for current page number
                      field: true,
                    }),
                    new TextRun(" of "),
                    new TextRun({
                      children: ["NUMPAGES"], // Placeholder for total pages
                      field: true,
                    }),
                  ],
                  alignment: "center",
                }),
              ],
            }),
          },
          children: [
            new Paragraph({
              text: "Section 1 Title",
              heading: "Heading1", // Mark this as Heading 1 for TOC
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Section 1 Content",
                  size: 24,
                }),
              ],
            }),
          ],
        },
        // Section 2
        {
          headers: {
            default: new Header({
              children: [
                new Paragraph({
                  children: [
                    new ImageRun({
                      data: logoBuffer,
                      transformation: { width: 50, height: 50 },
                    }),
                    new TextRun({
                      text: " Header Title",
                      bold: true,
                      size: 24,
                    }),
                  ],
                }),
              ],
            }),
          },
          footers: {
            default: new Footer({
              children: [
                new Paragraph({
                  children: [
                    new TextRun("Page "),
                    new TextRun({
                      children: ["PAGE"], // Placeholder for current page number
                      field: true,
                    }),
                    new TextRun(" of "),
                    new TextRun({
                      children: ["NUMPAGES"], // Placeholder for total pages
                      field: true,
                    }),
                  ],
                  alignment: "center",
                }),
              ],
            }),
          },
          children: [
            new Paragraph({
              text: "Section 2 Title",
              heading: "Heading1", // Mark this as Heading 1 for TOC
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "Section 2 Content",
                  size: 24,
                }),
              ],
            }),
          ],
        },
      ],
    });

    // Save the document
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, `${new Date()}example_with_toc.docx`);
    });
  };

  return <button onClick={generateDoc}>Download DOCX</button>;
};

export default DocFormat;
