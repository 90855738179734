import { Navigate, Route, Routes } from "react-router-dom";
import { Suspense } from "react";
import AuthGuard from "./AuthGuard";
import GuestGuard from "./GuestGuard";

// Layouts
import AuthLayout from "../component/layouts/AuthLayout";
import DashboardLayout from "../component/layouts/DashboardLayout";
import SurveyLayout from "../component/layouts/surveyLayout";

// import None from "../components/pages/none";

// Auth
import Login from "../component/pages/auth/login";
import Verification from "../component/pages/auth/verification";
import ResetOrCreatePass from "../component/pages/auth/resetOrCreatePass";
import ForgotPassword from "../component/pages/auth/forgotPassword";

// Dashboard
// import Home from "../component/pages/dashboard/home";
import Settings from "../component/pages/dashboard/settings";
import Survey from "../component/pages/dashboard/Survey";
import None from "../component/pages/dashboard/None";
import AddSurvey from "../component/pages/dashboard/Survey/AddSurvey/addSurvey";
import Edit from "../component/pages/dashboard/edit-profile/index";
import ChangePassword from "../component/pages/dashboard/edit-profile/change-password/index";
import ViewSurvey from "../component/pages/dashboard/Survey/viewSurvey";
import ShareGuard from "./shareGuard";
import SurveyLogin from "../component/pages/surveyAuth/surveyLogin";
import SurveyVerifyOTP from "../component/pages/surveyAuth/surveyVerifyOTP";
import ShareAuthGuard from "./shareAuthGuard";
import Vessels from "../component/pages/dashboard/Vessels";
import Trials from "../component/pages/dashboard/Trials";
import AddVessels from "../component/pages/dashboard/Vessels/AddVessels";
import AddTrials from "../component/pages/dashboard/Trials/AddTrials";
import AddTest from "../component/pages/dashboard/Trials/AddTrials/addTest";
import DocFormat from "../component/pages/dashboard/Trials/AddTrials/docFormat.jsx";
const routes = [
  {
    path: "/",
    element: <Navigate to="/auth" />,
  },
  {
    path: "auth",
    element: (
      <GuestGuard>
        <AuthLayout />
      </GuestGuard>
    ),
    children: [
      {
        index: true, // Make this the index route
        element: <Login />,
      },
      {
        path: "login",
        element: <Login />,
      },
      {
        path: "verify",
        element: <Verification />,
      },
      {
        path: "forgot-password",
        element: <ForgotPassword />,
      },
      {
        path: "reset-password",
        element: <ResetOrCreatePass type="reset" />,
      },
      {
        path: "create-password",
        element: <ResetOrCreatePass type="create" />,
      },
    ],
  },
  {
    path: "dashboard/*",
    element: (
      <AuthGuard>
        <DashboardLayout />
      </AuthGuard>
    ),
  },
  {
    path: "dashboard/survey/add/:id?*",
    element: (
      <AuthGuard>
        <SurveyLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <AddSurvey />,
      },
    ],
  },
  {
    path: "dashboard/survey/edit/:id?*",
    element: (
      <AuthGuard>
        <SurveyLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <AddSurvey />,
      },
    ],
  },
  {
    path: "survey/auth/*",
    element: (
      <ShareGuard>
        <AuthLayout />
      </ShareGuard>
    ),
    children: [
      {
        index: true,
        element: <SurveyLogin />,
      },
    ],
  },
  {
    path: "survey/auth/:id/*",
    element: (
      <ShareGuard>
        <AuthLayout />
      </ShareGuard>
    ),
    children: [
      {
        index: true,
        element: <SurveyLogin />,
      },
    ],
  },
  {
    path: "survey/auth/verifyotp/:id?",
    element: (
      <ShareGuard>
        <AuthLayout />
      </ShareGuard>
    ),
    children: [
      {
        index: true,
        element: <SurveyVerifyOTP />,
      },
    ],
  },
  {
    path: "survey/surveyor/:id?",
    element: (
      <ShareAuthGuard>
        <SurveyLayout />
      </ShareAuthGuard>
    ),
    children: [
      {
        index: true,
        element: <AddSurvey />,
      },
    ],
  },
  {
    path: "dashboard/survey/surveyor/:id?",
    element: (
      <AuthGuard>
        <SurveyLayout />
      </AuthGuard>
    ),
    children: [
      {
        index: true,
        element: <AddSurvey />,
      },
    ],
  },
];

export const surveyRoute = (
  <Suspense>
    <Routes>
      <Route
        path="survey/add"
        element={
          <AuthGuard>
            <AddSurvey />
          </AuthGuard>
        }
      />
      <Route
        path="survey/edit/:id"
        element={
          <AuthGuard>
            <AddSurvey type="edit" />
          </AuthGuard>
        }
      />
    </Routes>
  </Suspense>
);

export const dashboardRoute = (
  <Suspense>
    <Routes>
      <Route
        path="/edit-profile"
        element={
          <AuthGuard>
            <Edit />
          </AuthGuard>
        }
      />
      <Route
        path="/change-password"
        element={
          <AuthGuard>
            <ChangePassword />
          </AuthGuard>
        }
      />
      <Route
        path="Vessel"
        element={
          <AuthGuard>
            <Vessels />
          </AuthGuard>
        }
      />
      <Route
        path="Vessel/add"
        element={
          <AuthGuard>
            <AddVessels />
          </AuthGuard>
        }
      />
      <Route
        path="Vessel/:id"
        element={
          <AuthGuard>
            <AddVessels />
          </AuthGuard>
        }
      />
      <Route
        path="Trial/doc-format"
        element={
          <AuthGuard>
            <DocFormat />
          </AuthGuard>
        }
      />
      <Route
        path="Trial"
        element={
          <AuthGuard>
            <Trials />
          </AuthGuard>
        }
      />
      <Route
        path="Trial/add"
        element={
          <AuthGuard>
            <AddTrials type="add" />
          </AuthGuard>
        }
      />
      <Route
        path="Trial/:vessel_id/:trial_id"
        element={
          <AuthGuard>
            <AddTrials />
          </AuthGuard>
        }
      />
      <Route
        path="Trial/:vessel_id/:trial_id/add"
        element={
          <AuthGuard>
            <AddTest />
          </AuthGuard>
        }
      />
      <Route
        path="Trial/:vessel_id/:trial_id/:test_id/edit"
        element={
          <AuthGuard>
            <AddTest type="edit" />
          </AuthGuard>
        }
      />

      {/* <Route
        path="survey"
        element={
          <AuthGuard>
            <Survey />
          </AuthGuard>
        }
      /> */}
      {/* <Route
        path="survey/view/:id"
        element={
          <AuthGuard>
            <ViewSurvey />
          </AuthGuard>
        }
      /> */}
      {/* <Route
        path="survey/edit/:id"
        element={
          <AuthGuard>
            <AddSurvey type="edit" />
          </AuthGuard>
        }
      /> */}

      <Route
        path="setting"
        element={
          <AuthGuard>
            <Settings />
          </AuthGuard>
        }
      />

      <Route
        path="none"
        element={
          <AuthGuard>
            <None />
          </AuthGuard>
        }
      />
    </Routes>
  </Suspense>
);

export default routes;
