import React, { useState } from "react";
import AddBold from "../../../../icons/AddBold";
import Trash from "../../../../icons/Trash";
import MyTextInput from "../../../../atoms/MyTextInput";
import Modal from "../../../../organisms/modals/DeleteModal";

const GeneralSetup = ({ systemSetupRows, setSystemSetupRows }) => {
  console.log("systemSetupRows--------->", systemSetupRows);
  const [isModalActive, setIsModalActive] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);

  const handleAddRow = () => {
    setSystemSetupRows([
      ...systemSetupRows,
      {
        system: "",
        confirmed: false,
      },
    ]);
  };

  const handleDeleteRow = (index) => {
    const newRows = systemSetupRows.filter((_, rowIndex) => rowIndex !== index);
    setSystemSetupRows(newRows);
    setIsModalActive(false);
  };

  const confirmDeleteRow = (index) => {
    setSelectedRowIndex(index);
    setIsModalActive(true);
  };

  const handleChange = (index, field, value) => {
    const newRows = [...systemSetupRows];
    newRows[index][field] = value;
    setSystemSetupRows(newRows);
  };

  const handleCheckboxChange = (index) => {
    const newRows = [...systemSetupRows];
    newRows[index].confirmed = !newRows[index].confirmed;
    setSystemSetupRows(newRows);
  };

  return (
    <div className="general-setup-container">
      <table className="dp-sensor-table">
        <thead>
          <tr>
            <th className="table-header" style={{ width: "5%" }}>
              No.
            </th>
            <th className="table-header" style={{ width: "85%" }}>
              System and Set Up
            </th>
            <th className="table-header" style={{ width: "5%" }}>
              Confirmed
            </th>
          </tr>
        </thead>
        <tbody>
          {systemSetupRows.map((row, index) => (
            <tr key={index}>
              <td className="table-data">
                <b>{`# ${index + 1}`}</b>
              </td>
              <td className="table-data">
                <input
                  type="text"
                  value={row.system}
                  onChange={(e) =>
                    handleChange(index, "system", e.target.value)
                  }
                  className="input-text"
                />
              </td>
              <td className="table-data">
                <input
                  type="checkbox"
                  checked={row.confirmed}
                  onChange={() => handleCheckboxChange(index)}
                  className="checkbox-input"
                />
              </td>
              <td className="table-data">
                <button
                  className="delete-button"
                  onClick={() => confirmDeleteRow(index)}
                >
                  <Trash />
                </button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <button onClick={handleAddRow} className="add-row-button1">
        <AddBold width="24" height="24" />
      </button>

      {/* <div className="remarks-container">
        <MyTextInput
          type="text"
          name="remarks"
          customholder="DP Sensors Remarks"
          value={systemSetupRemarks}
          onChange={(e) => setSystemSetupRemarks(e.target.value)}
        />
      </div> */}
      {isModalActive && (
        <Modal
          isActive={isModalActive}
          onClose={() => setIsModalActive(false)}
          title="Row"
          name={`Row ${selectedRowIndex + 1}`}
          onClick={() => handleDeleteRow(selectedRowIndex)}
          description="Are you sure you want to delete this row?"
        />
      )}

      {/* CSS Styles */}
      <style jsx>{`
        .general-setup-container {
          overflow-x: scroll;
        }

        th,
        td {
          border: none !important;
        }

        .dp-sensor-table {
          width: 100%;
          border-collapse: collapse;
        }

        .table-header {
          padding: 10px;
          text-align: left;
          border: none;
        }

        .table-data {
          padding: 10px;
          text-align: left;
        }

        .input-text {
          border: 1px solid #ccc;
          padding: 5px;
          width: 100%;
          box-sizing: border-box;
        }

        .checkbox-input {
          transform: scale(1.4);
          text-align: left;
          border: 0.5px solid #ccc;
        }

        .add-row-button1 {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 10px;
          color: white;
          border: none;
          border-radius: 40px;
          cursor: pointer;
          background: none;
        }

        .delete-button {
          background: none;
          border: none;
          cursor: pointer;
          color: red;
          padding: 0;
          margin: 0;
        }

        .delete-button:hover {
          color: darkred;
        }

        .remarks-container {
          margin-top: 15px;
        }
      `}</style>
    </div>
  );
};

export default GeneralSetup;
