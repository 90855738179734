import React, { useState } from "react";
import "./style.scss";
import Error from "../../atoms/Error";
import Info from "../Info";
import Modal from "../../organisms/modals/Modal";
import View from "../../icons/View";
import Overview from "../../icons/Overview";

const MyTextInput = ({
  label,
  icon,
  InputHeight = "small",
  info,
  customBlur,
  blurError,
  width,
  customholder,
  value,
  onChange,
  preview, // New prop for preview functionality
  ...props
}) => {
  const [touched, setTouched] = useState(false);
  const [placeholder, setPlaceholder] = useState(customholder);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleFocus = () => {
    setPlaceholder(
      customholder.startsWith("Enter")
        ? customholder.substring(5)
        : customholder,
    );
  };

  const handleBlur = (event) => {
    setTouched(true);
    if (event.target.value === "") {
      setPlaceholder(customholder);
    }
    if (customBlur) customBlur();
  };

  const toggleModal = () => setIsModalOpen(!isModalOpen);

  return (
    <>
      {label && <label htmlFor={props.id || props.name}>{label}</label>}
      <div
        className={`input-group d-flex align-center`}
        style={{ width: width }}
      >
        {/* Icon on the left side of the input */}
        {icon && <div className="input-group-prepend">{icon}</div>}

        <div className={`input-container ${preview ? "no-right-radius" : ""}`}>
          <input
            {...props}
            value={value}
            onChange={(e) => {
              if (onChange) onChange(e);
            }}
            onFocus={handleFocus}
            onBlur={handleBlur}
            className={`${
              touched && !value && blurError ? "error-input " : ""
            }${
              InputHeight === "small" ? "primary-input-small" : "primary-input"
            }`}
            placeholder=""
          />
          <label
            className={`${
              InputHeight === "small"
                ? "input-placeholder-small"
                : "input-placeholder"
            }`}
          >
            {placeholder}
          </label>
        </div>

        {/* Eye icon on the right side for preview functionality */}
        {preview && (
          <div className="input-group-append" onClick={toggleModal}>
            <View width="16" height="16" />
          </div>
        )}
      </div>

      {info && !blurError ? <Info info={info} /> : null}
      {touched && blurError ? (
        <Error error={blurError} type={InputHeight} />
      ) : null}

      {/* Render the Modal for preview */}
      {isModalOpen && (
        <Modal
          id="preview-modal"
          title={placeholder}
          isActive={isModalOpen}
          onClose={toggleModal}
          width="50%" // Customize modal width
          maxHeight="300px" // Customize max height
        >
          <div style={{ padding: "0px" }}>
            <p style={{ fontWeight: 500 }}>
              {value || "No content to display."}
            </p>
          </div>
        </Modal>
      )}
    </>
  );
};

export default MyTextInput;
