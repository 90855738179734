// import { Box } from "@mui/material";
// import { useState } from "react";
// import TabStrip from "../../../../molecules/tabstrip";
// import Camera from "../../../../icons/Camera";
// import Image from "../../../../icons/image";
// import MultiImage from "../../../../icons/MultiImage";
// import NotesAndImages from "./testImageNote";

// const TestImage = () => {
//   const [activeTab, setActiveTab] = useState();

//   const onTabChange = (event, value) => {
//     setActiveTab(value);
//   };
//   const _getComponet = (tabs) => {
//     return data
//       .filter((item) => item.label === tabs)
//       .map((item) => item.view)[0];
//   };
//   const data = [
//     {
//       id: 0,
//       label: <Image />,
//       route: "user-management",
//       view: <NotesAndImages />,
//     },
//     {
//       id: 1,
//       label: <Camera />,
//       route: "amca-logs",
//       view: <></>,
//     },
//     {
//       id: 2,
//       label: <MultiImage />,
//       route: "amca-logs",
//       view: <></>,
//     },
//   ];

//   return (
//     <>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//         }}
//       >
//         <TabStrip activeTab={activeTab} onTabChange={onTabChange} data={data} />
//       </div>
//       <Box sx={{ width: "100%" }}>
//         <Box sx={{ p: 2 }}>{_getComponet(activeTab)}</Box>
//       </Box>
//     </>
//   );
// };
// export default TestImage;

// import { Box } from "@mui/material";
// import { useState } from "react";
// import TabStrip from "../../../../molecules/tabstrip";
// import Camera from "../../../../icons/Camera";
// import Image from "../../../../icons/image";
// import MultiImage from "../../../../icons/MultiImage";
// import NotesAndImages from "./testImageNote";

// const TestImage = () => {
//   // Set activeTab to 0 initially to open NotesAndImages by default
//   const [activeTab, setActiveTab] = useState(0);

//   console.log("activeTab---->", activeTab);

//   const onTabChange = (event, value) => {
//     setActiveTab(value); // Set active tab based on the clicked tab's id
//   };

//   const data = [
//     {
//       id: 0,
//       label: <Image />,
//       view: <NotesAndImages />,
//     },
//     {
//       id: 1,
//       label: <Camera />,
//       view: <div>No content available for this tab.</div>,
//     },
//     {
//       id: 2,
//       label: <MultiImage />,
//       view: <div>No content available for this tab.</div>,
//     },
//   ];

//   const getComponent = () => {
//     const activeTabData = data.find((item) => item.id === activeTab);
//     return activeTabData ? activeTabData.view : null;
//   };

//   return (
//     <>
//       <div
//         style={{
//           display: "flex",
//           justifyContent: "center",
//         }}
//       >
//         <TabStrip
//           activeTab={activeTab}
//           onTabChange={onTabChange}
//           data={data.map((tab) => ({ id: tab.id, label: tab.label }))}
//         />
//       </div>
//       <Box sx={{ width: "100%" }}>
//         <Box sx={{ p: 2 }}>{getComponent()}</Box>
//       </Box>
//     </>
//   );
// };

// export default TestImage;

import { Box } from "@mui/material";
import { useState } from "react";
import TabStrip from "../../../../molecules/tabstrip";
import Camera from "../../../../icons/Camera";
import Image from "../../../../icons/image";
import MultiImage from "../../../../icons/MultiImage";
import NotesAndImages from "./testImageNote";
import CameraCapture from "./cameraCapture";
import TestImageSelection from "./testImageSelection";

const TestImage = ({
  testImage,
  setTestImage,
  testNote,
  setTestNote,
  notesData,
  setNotesData,
  capturedImage,
  setCapturedImage,
  note22,
  setNote22,
}) => {
  const [activeTab, setActiveTab] = useState(0); // Default to the first tab (with id 0)

  const onTabChange = (event, value) => {
    setActiveTab(value); // Set active tab to the clicked tab's id
  };

  const getComponent = (tabId) => {
    const activeTabData = data.find((item) => item.id === tabId);
    return activeTabData ? activeTabData.view : null;
  };

  const data = [
    {
      id: 0,
      label: <Image />,
      view: (
        <NotesAndImages notesData={notesData} setNotesData={setNotesData} />
      ),
    },
    {
      id: 1,
      label: <Camera />,
      view: (
        <CameraCapture
          capturedImage={capturedImage}
          setCapturedImage={setCapturedImage}
          note22={note22}
          setNote22={setNote22}
        />
      ),
    },
    {
      id: 2,
      label: <MultiImage />,
      view: (
        <TestImageSelection
          setTestNote={setTestNote}
          testNote={testNote}
          setTestImage={setTestImage}
          testImage={testImage}
        />
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TabStrip
          activeTab={activeTab}
          onTabChange={onTabChange}
          data={data.map((tab) => ({ id: tab.id, label: tab.label }))}
          method="id"
        />
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ p: 2 }}>{getComponent(activeTab)}</Box>
      </Box>
    </>
  );
};

export default TestImage;
