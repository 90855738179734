// import React from "react";
// import ImageSelector from "../../../../atoms/ImageSelector/ImageSelector";

// const VesselPhoto = ({ vesselImage, setVesselImage }) => {
//   // const handleImageSelection = (file) => {
//   //   setVesselImage(file);
//   // };
//   const handleImageSelection = (file) => {
//     setVesselImage("tfvyhuijk");
//     const reader = new FileReader();
//     reader.onload = () => {
//       const base64String = reader.result.split(",")[1]; // Extract Base64 part
//       console.log("base64String--vesselIamge", base64String);
//     };
//     reader.readAsDataURL(file); // Read the file as a data URL
//   };
//   console.log("vesselImage--->", vesselImage);
//   return (
//     <div className="vessel-photo">
//       <ImageSelector onImageSelect={handleImageSelection} />
//     </div>
//   );
// };

// export default VesselPhoto;

// import React, { useState } from "react";
// import ImageSelector from "../../../../atoms/ImageSelector/ImageSelector";
// import { postFormData } from "../../../../../services";

// const VesselPhoto = ({ vesselImage, setVesselImage }) => {
//   // Handle image selection and convert to base64

//   console.log("vesselImage--to show", vesselImage);
//   const handleImageSelection = (file) => {
//     const reader = new FileReader();

//     reader.onload = () => {
//       const base64String = reader.result.split(",")[1]; // Extract Base64 part
//       console.log("Base64 string of the image:", base64String);

//       setVesselImage(base64String); // Pass the base64 image string to the parent component
//     };
//     reader.readAsDataURL(file);
//   };

//   return (
//     <div className="vessel-photo">
//       <ImageSelector onImageSelect={handleImageSelection} />
//     </div>
//   );
// };

// export default VesselPhoto;
// import React, { useState } from "react";
// import ImageSelector from "../../../../atoms/ImageSelector/ImageSelector";
// import { postFormData } from "../../../../../services";
// import { useCookies } from "react-cookie";

// const VesselPhoto = ({ vesselImage, setVesselImage }) => {
//   const [cookies] = useCookies(["t"]);
//   const [isLoading, setIsLoading] = useState(false);

//   const handleImageSelection = async (file) => {
//     const reader = new FileReader();

//     reader.onload = async () => {
//       const base64String = reader.result.split(",")[1];
//       console.log("Base64 string of the image:", base64String);

//       setVesselImage(base64String); // Update the parent state

//       // Prepare FormData for the API call
//       const formData = new FormData();
//       formData.append("file", file);

//       try {
//         setIsLoading(true);
//         const response = await postFormData({
//           endpoint: "vessel/upload-file",
//           data: formData,
//           token: cookies.t,
//         });

//         console.log("Upload response:", response?.data);
//         setVesselImage(response?.data?.data?.s3UploadedLink);
//       } catch (error) {
//         console.error("Error uploading image:", error);
//       } finally {
//         setIsLoading(false); // Stop loading
//       }
//     };

//     reader.readAsDataURL(file);
//   };

//   console.log("vesselImage---->", vesselImage);

//   return (
//     <div className="vessel-photo">
//       <ImageSelector onImageSelect={handleImageSelection} />
//       {isLoading && <p>Uploading image...</p>}
//     </div>
//   );
// };

// export default VesselPhoto;

// import React, { useState } from "react";
// import ImageSelector from "../../../../atoms/ImageSelector/ImageSelector";
// import { postFormData } from "../../../../../services";
// import { useCookies } from "react-cookie";

// const VesselPhoto = ({ vesselImage, setVesselImage }) => {
//   const [cookies] = useCookies(["t"]);
//   const [isLoading, setIsLoading] = useState(false);

//   const handleImageSelection = async (file) => {
//     // Prepare FormData for the API call
//     const formData = new FormData();
//     formData.append("file", file);

//     try {
//       setIsLoading(true);

//       const response = await postFormData({
//         endpoint: "vessel/upload-file",
//         data: formData,
//         token: cookies.t,
//       });

//       console.log("Upload response:", response?.data);

//       // Set vesselImage to the S3 link
//       const s3Link = response?.data?.data?.s3UploadedLink;
//       if (s3Link) {
//         setVesselImage(s3Link); // Update with the S3 link
//       }
//     } catch (error) {
//       console.error("Error uploading image:", error);
//     } finally {
//       setIsLoading(false); // Stop loading
//     }
//   };

//   return (
//     <div className="vessel-photo">
//       <ImageSelector onImageSelect={handleImageSelection} />
//     </div>
//   );
// };

// export default VesselPhoto;

import React, { useState } from "react";
import ImageSelector from "../../../../atoms/ImageSelector/ImageSelector";
import { postFormData } from "../../../../../services";
import { useCookies } from "react-cookie";

const VesselPhoto = ({ vesselImage, setVesselImage, id }) => {
  const [cookies] = useCookies(["t"]);
  const [isLoading, setIsLoading] = useState(false);

  const handleImageSelection = async (file) => {
    const formData = new FormData();
    formData.append("file", file);

    try {
      setIsLoading(true);
      const response = await postFormData({
        endpoint: "vessel/upload-file",
        data: formData,
        token: cookies.t,
      });

      const s3Link = response?.data?.data?.s3UploadedLink;
      if (s3Link) {
        setVesselImage(s3Link); // Update with S3 link
      }
    } catch (error) {
      console.error("Error uploading image:", error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="vessel-photo">
      <ImageSelector
        onImageSelect={handleImageSelection}
        s3ImageLink={vesselImage}
        id={id}
        onRemoveImage={() => setVesselImage(null)}
      />
    </div>
  );
};

export default VesselPhoto;
