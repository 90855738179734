import { useCallback, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import toast from "react-hot-toast";
import Pagination from "../../../../molecules/Pagination";
import CustomDatagrid from "../../../../molecules/CustomDatagrid";
import Search from "../../../../molecules/search";
import PageHeader from "../../../../atoms/PageHeader";
import PageCard from "../../../../atoms/PageCard";
import Delete from "../../../../icons/Delete";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";
import { deleteData, getData } from "../../../../../services";
import DeleteModal from "../../../../organisms/modals/DeleteModal";
import Trash from "../../../../icons/Trash";
import CustomButton from "../../../../atoms/CustomButton";
import NoContentIcon from "../../../../icons/NocontentIcon";

const TrialTest = ({ vesselId, trialId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [searchedValue, setSearchedValue] = useState("");
  const [data, setData] = useState([]);
  const [trialData, setTrialData] = useState([]);
  const [cookies] = useCookies(["t", "role"]);
  const [modalState, setModalState] = useState({
    isModalOpen: false,
    modalData: null,
    isDeleteModalOpen: false,
    modalId: "",
    name: "",
  });
  const navigate = useNavigate();

  // console.log("vesselId------->", vesselId);

  console.log("modalState", modalState);

  // Fetch vessel data
  // const fetchTestData = async () => {
  //   try {
  //     const res = await getData({
  //       endpoint: "trialtest/getAllTestByTrialId",
  //       token: cookies.t,
  //       params: {
  //         page: currentPage,
  //         limit: itemsPerPage,
  //         search: searchedValue,
  //       },
  //     });

  //     if (res) {
  //       console.log("res----->", res);
  //     }
  //     setData(res?.data || []);
  //   } catch (err) {
  //     console.log("Error fetching vessel data:", err);
  //     setData([]); // Ensure vesselData is empty on error
  //   }
  // };

  const fetchAllTrialTest = async () => {
    try {
      const res = await getData({
        endpoint: "trialtest/getAllTestByTrialId",
        token: cookies.t,
        params: {
          page: currentPage,
          limit: itemsPerPage,
          search: searchedValue,
          id: trialId,
        },
      });

      if (res) {
        console.log("res----->", res);
      }
      setTrialData(res?.data?.testData || []);
    } catch (err) {
      console.log("Error fetching vessel data:", err);
      setTrialData([]); // Ensure vesselData is empty on error
    }
  };

  useEffect(() => {
    if (trialId !== undefined) {
      fetchAllTrialTest();
    }
  }, []);

  console.log("trialData---->", trialData);

  const pageCount = data?.pageDetails?.count;

  useEffect(() => {
    fetchAllTrialTest();
  }, [itemsPerPage, currentPage, searchedValue]);

  const handlePageChange = useCallback((page) => {
    setCurrentPage(page);
  }, []);

  // Handle items per page change
  const handleItemsPerPageChange = useCallback((perPage) => {
    setCurrentPage(1);
    setItemsPerPage(perPage);
  }, []);

  console.log("data?.vesselData?.length", data?.vesselData?.length);

  const handleDeletion = async () => {
    try {
      const res = await deleteData({
        endpoint: `trialtest/delete`,
        params: {
          id: modalState.modalId, // Correct user_id being used here
        },
        token: cookies.t,
      });
      if (res?.data?.statusCode === 200) {
        toast.success("Trial Test Deleted Successfully", {
          style: confimationStyles,
          duration: 1000,
        });
        // fetchAllVesselTrial();
      } else {
        toast.error(res?.data?.message, { style: errorStyles, duration: 2000 });
      }
      fetchAllTrialTest(); // Refresh the data after successful deletion
      setModalState((prevState) => ({
        ...prevState,
        isDeleteModalOpen: false,
      }));
    } catch (error) {
      console.error(error);
    }
  };

  const handleDeleteModal = (trial_id, name) => {
    console.log("vessel_id", trial_id);
    console.log("name", name);
    setModalState((prevState) => ({
      ...prevState,
      isDeleteModalOpen: true,
      modalId: trial_id, // Correct user_id being set here
      name: name,
    }));
  };

  const columns = [
    {
      field: "customTestNo",
      headerName: "Test Id",
      flex: 0.8,
    },
    { field: "test_title", headerName: "Name", flex: 2 },
    { field: "system", headerName: "System", flex: 1.5 },
    { field: "sub_system", headerName: "Subsystem", flex: 1.5 },
    { field: "on_dp", headerName: "On DP", flex: 1 },
    // { field: "on_performed_by_crewdp", headerName: "For Crew", flex: 1 },
    {
      field: "performed_by_crew",
      headerName: "For Crew",
      flex: 1,
      // valueFormatter: (params) => (params.value ? "Yes" : "No"),
      renderCell: (params) => {
        return (
          <>
            <span>{params.row?.performed_by_crew === true ? "Yes" : "No"}</span>
          </>
        );
      },
    },
    // {
    //   field: "performed_by_crew",
    //   headerName: "Opened Recomm.",
    //   flex: 1,
    //   // valueFormatter: (params) => (params.value ? "Yes" : "No"),
    //   renderCell: (params) => {
    //     return (
    //       <>
    //         <span>{params.row?.recommendation_rows}</span>
    //       </>
    //     );
    //   },
    {
      field: "dfrgt",
      headerName: "Opened Recomm.",
      flex: 1,
      renderCell: (params) => {
        // Initialize openedCount to 0
        let openedCount = 0;

        // Check if recommendation_rows exists
        if (params.row?.recommendation_rows) {
          // Iterate through the recommendation_rows array
          params.row.recommendation_rows.forEach((row) => {
            // Check if the status of the recommendation_row is "Opened"
            if (row.status === "Opened") {
              openedCount++;
            }
          });
        }
        // Return the opened count
        return (
          <>
            <span
              style={{
                padding: "5px",
                paddingRight: "8px",
                paddingLeft: "8px",
                borderRadius: "50%",
                color: "white",
                backgroundColor: `${openedCount === 0 ? "green" : "#be0f00"}`,
              }}
            >
              {openedCount}
            </span>
          </>
        );
      },
    },
    { field: "test_status", headerName: "Status", flex: 1 },
    {
      field: "createdAt",
      headerName: "Date",
      flex: 1,
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span>{moment(params.row?.createdAt).format("DD-MM-YYYY")}</span>
          </>
        );
      },
    },
    {
      field: "actions",
      headerName: "Actions",
      sortable: true,
      renderCell: (params) => {
        return (
          <>
            <span
              className="me-2 pointer"
              onClick={() =>
                handleDeleteModal(params.row._id, params.row.trials)
              }
            >
              <Trash />
            </span>
          </>
        );
      },
    },
  ];

  const trialDataWithTestNo = trialData?.map((test, index) => ({
    ...test,
    customTestNo: index + 1, // Add customTestNo starting from 1
  }));

  return (
    <>
      <div className="header-infoCardWrap info_card_mbr">
        {modalState.isDeleteModalOpen && (
          <DeleteModal
            name={modalState.name}
            id={modalState.modalId}
            isActive={modalState.isDeleteModalOpen}
            onClose={() =>
              setModalState((prevState) => ({
                ...prevState,
                isDeleteModalOpen: false,
              }))
            }
            title="Trial Test"
            onClick={handleDeletion}
          />
        )}
      </div>
      <PageCard>
        <PageHeader title="Trial Tests" type="small" />

        <div
          className="d-flex space-between gap-3-mbr"
          style={{ marginBottom: "20px", marginTop: "10px" }}
        >
          <Search
            // onSearchEmit={(value) => {
            //   // Implement search functionality if needed
            // }}
            onSearchEmit={(value) => {
              setSearchedValue(value);
              if (value.length > 2) {
                if (currentPage !== 1) setCurrentPage(1);
              }
            }}
          />
          <div className="d-flex gap-3">
            <CustomButton
              type="btn-primary"
              text="Add Test"
              handleClick={() => navigate("add")}
              classes={"survery-btn-none-mbr"}
            />
          </div>

          {/* <div className="filter-add">
            <FilterComponent
              filterData={[]}
              isDateRange
              dateRangeNames={["Survey Date", "Create Date"]}
              onFilterChange={(filter) => {
                // Implement filter functionality if needed
              }}
            />
            {atob(cookies.role) === "Admin" && (
              <div className="d-flex gap-3">
                <CustomButton
                  type="btn-primary"
                  text="Add Vessel"
                  handleClick={() => navigate("add")}
                  classes={"survery-btn-none-mbr"}
                />
              </div>
            )}
          </div> */}
        </div>

        {trialData && trialData?.length > 0 ? (
          <>
            <CustomDatagrid
              getRowId={(row) => row._id}
              getRowClassName={(row) => {
                console.log("Row :", row?.row?.test_status);
                return row?.row?.test_status === "Complete"
                  ? "complete_border_left"
                  : row?.row?.test_status === "In Progress"
                  ? "inprogress_border_left"
                  : "draft_border_left";
              }}
              rows={trialDataWithTestNo}
              onCellClick={(data) => {
                const clickedColumnField = data?.field;
                console.log("clickedColumnField", data);
                const skipColumnField = "actions";

                if (clickedColumnField !== skipColumnField) {
                  navigate(
                    `/dashboard/Trial/${vesselId}/${trialId}/${data?.row._id}/edit`,
                    {
                      state: {},
                    },
                  );
                } else {
                  console.log("Skipping navigation for action column");
                }
              }}
              columns={columns}
              tHeight="50vh"
            />
            {pageCount && pageCount > 10 && (
              <Pagination
                itemsPerPage={itemsPerPage}
                totalItems={pageCount}
                currentPage={currentPage}
                onPageChange={handlePageChange}
                onItemsPerPageChange={handleItemsPerPageChange}
              />
            )}
          </>
        ) : (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "50vh",
            }}
          >
            <div>
              <NoContentIcon />
              <div
                className="graph-heading"
                style={{
                  fontSize: "18px",
                  marginTop: "20px",
                  textAlign: "center",
                  fontWeight: "bold",
                }}
              >
                No Records Found
              </div>
            </div>
          </div>
        )}
      </PageCard>
    </>
  );
};

export default TrialTest;
