import React, { useState } from "react";
import MyDropdown from "../../../../atoms/MyDropdown"; // Reusing MyDropdown component

const HardwareModificationForm = ({
  hardwareModification,
  setHardwareModification,
  modificationsTested,
  setModificationsTested,
  trialsUpdated,
  setTrialsUpdated,
}) => {
  // State for form fields
  // const [hardwareModification, setHardwareModification] = useState("");
  // const [modificationsTested, setModificationsTested] = useState("");
  // const [trialsUpdated, setTrialsUpdated] = useState("");

  console.log("hardwareModification", hardwareModification);
  console.log("modificationsTested", modificationsTested);
  console.log("trialsUpdated", trialsUpdated);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    const formData = {
      hardwareModification,
      modificationsTested,
      trialsUpdated,
    };
    console.log(formData);
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* First Row: Hardware Modification Dropdown */}
      <div style={styles.row}>
        <div style={styles.fullWidth}>
          <MyDropdown
            placeholder="Have there been any hardware modifications to the DP system components since last annual trials ?"
            data={[
              { label: "Yes", value: "Yes" },
              { label: "No", value: "No" },
            ]}
            format="all"
            menuOpen={true}
            name="hardwareModification"
            width="100%"
            selectedValue={hardwareModification}
            onChange={(options) => setHardwareModification(options?.value)}
          />
        </div>
      </div>

      {/* Second Row: Modifications Tested and Trials Updated Dropdowns */}
      <div style={styles.row}>
        <div style={styles.halfWidth}>
          <MyDropdown
            placeholder="Have modifications being tested ?"
            data={[
              { label: "Yes", value: "Yes" },
              { label: "No", value: "No" },
            ]}
            format="all"
            menuOpen={true}
            name="modificationsTested"
            width="100%"
            selectedValue={modificationsTested}
            onChange={(options) => setModificationsTested(options?.value)}
          />
        </div>

        <div style={styles.halfWidth}>
          <MyDropdown
            placeholder="Have trials procedures been updated ?"
            data={[
              { label: "Yes", value: "Yes" },
              { label: "No", value: "No" },
            ]}
            format="all"
            menuOpen={true}
            name="trialsUpdated"
            width="100%"
            selectedValue={trialsUpdated}
            onChange={(options) => setTrialsUpdated(options?.value)}
          />
        </div>
      </div>

      {/* Submit Button */}
      {/* <button type="submit" style={styles.submitButton}>
        Submit
      </button> */}
    </form>
  );
};

// Styles
const styles = {
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
    gap: "16px",
  },
  fullWidth: {
    width: "100%",
  },
  halfWidth: {
    width: "50%",
  },
  submitButton: {
    marginTop: "16px",
    padding: "8px 16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default HardwareModificationForm;
