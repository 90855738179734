import React, { useState, useRef, useEffect } from "react";

const FileSelector = ({ onFileSelect, s3FileLink, id, onRemoveFile }) => {
  const [file, setFile] = useState(null); // Stores the selected file or S3 link
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (s3FileLink) {
      setFile(s3FileLink); // Update preview with the S3 file link
    }
  }, [s3FileLink]);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file); // Store the file locally
      if (onFileSelect) onFileSelect(file); // Pass the file to the parent
    }
  };

  const handleRemove = () => {
    setFile(null); // Clear the file
    if (fileInputRef.current) {
      fileInputRef.current.value = ""; // Clear the input field
    }
    if (onFileSelect) onFileSelect(null); // Notify parent of removal
    if (onRemoveFile) onRemoveFile(id); // Remove S3 link from DB
  };

  const handleEdit = () => {
    fileInputRef.current.click(); // Open file selection dialog
  };

  return (
    <div className="file-selector">
      <div
        className="input-container"
        style={{ width: "100%", display: "flex" }}
      >
        <div style={{ justifyContent: "flex-start" }}>
          <input
            type="file"
            accept="*/*" // Accept all file types
            onChange={handleFileChange}
            ref={fileInputRef}
            className="input-file"
            style={{ display: s3FileLink && id ? "none" : "block" }} // Hide input if S3 link is present
          />
        </div>
      </div>
      {file && (
        <div className="file-container">
          <div className="file-preview">
            {typeof file === "string" ? (
              <a href={file} target="_blank" rel="noopener noreferrer">
                View Uploaded File
              </a>
            ) : (
              <p>{file.name}</p> // Show file name for local file
            )}
          </div>
          <div className="buttons-container">
            <button className="edit-button" onClick={handleEdit}>
              Edit
            </button>
            <button className="remove-button" onClick={handleRemove}>
              Remove
            </button>
          </div>
        </div>
      )}
      <style jsx>{`
        .file-selector {
          display: flex;
          flex-direction: column;
          align-items: center;
        }

        .input-file {
          width: 100%;
          padding: 0.5rem;
          border: 1px solid #ccc;
          border-radius: 5px;
        }

        .file-container {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-top: 10px !important;
        }

        .file-preview {
          box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
          border-radius: 5px;
          padding: 0.5rem;
          margin-bottom: 0.5rem;
          text-align: center;
        }

        .buttons-container {
          display: flex;
          gap: 0.7rem;
        }

        .edit-button,
        .remove-button {
          padding: 0.3rem 0.6rem;
          border: none;
          border-radius: 3px;
          cursor: pointer;
          width: 80px;
        }

        .edit-button {
          background-color: #007bff;
          color: #fff;
        }

        .remove-button {
          background-color: #dc3545;
          color: #fff;
        }
      `}</style>
    </div>
  );
};

export default FileSelector;
