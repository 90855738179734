import { Box } from "@mui/material";
import { useState } from "react";
import TabStrip from "../../../../molecules/tabstrip";
import Camera from "../../../../icons/Camera";
import Image from "../../../../icons/image";
import MultiImage from "../../../../icons/MultiImage";
import NotesAndImages from "./testImageNote";
import CameraCapture from "./cameraCapture2";
import TestImageSelection from "./testImageSelection";
import Document from "../../../../icons/Document";
import Clip from "../../../../icons/Clip";
import SupportingMediaListing from "./supportingMediaListing";
import FileSelectionComponent from "./testFileSelection";

const SupportingMedia = ({
  capturedImage1,
  setCapturedImage1,
  supportingMediaRows,
  setSupportingMediaRows,
  selectedFile,
  setSelectedFile,
  fileNote,
  setFileNote,
  note23,
  setNote23,
}) => {
  const [activeTab, setActiveTab] = useState(0); // Default to the first tab (with id 0)

  const onTabChange = (event, value) => {
    setActiveTab(value); // Set active tab to the clicked tab's id
  };

  const getComponent = (tabId) => {
    const activeTabData = data.find((item) => item.id === tabId);
    return activeTabData ? activeTabData.view : null;
  };

  const data = [
    // {
    //   id: 0,
    //   label: <Image />,
    //   view: (
    //     <NotesAndImages notesData={notesData} setNotesData={setNotesData} />
    //   ),
    // },
    {
      id: 0,
      label: <Clip />,
      view: (
        <FileSelectionComponent
          selectedFile={selectedFile}
          setSelectedFile={setSelectedFile}
          fileNote={fileNote}
          setFileNote={setFileNote}
        />
      ),
    },
    {
      id: 1,
      label: <Camera />,
      view: (
        <CameraCapture
          capturedImage1={capturedImage1}
          setCapturedImage1={setCapturedImage1}
          note23={note23}
          setNote23={setNote23}
        />
      ),
    },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <TabStrip
          activeTab={activeTab}
          onTabChange={onTabChange}
          data={data.map((tab) => ({ id: tab.id, label: tab.label }))}
          method="id"
        />
      </div>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ p: 2 }}>{getComponent(activeTab)}</Box>
      </Box>
      <hr />
      <SupportingMediaListing
        supportingMediaRows={supportingMediaRows}
        setSupportingMediaRows={setSupportingMediaRows}
      />
    </>
  );
};

export default SupportingMedia;
