import React, { useEffect, useState } from "react";
import Accordion from "../../../../molecules/accordion";
import Calendar from "../../../../icons/Calendar";
import Exclamation from "../../../../icons/Exclamation";
import Gear from "../../../../icons/Gear";
import User from "../../../../icons/User";
import Map from "../../../../icons/Map";
import Info from "../../../../icons/Info";
import TrialDetails from "./trialsDetails";
import TrialConditions from "./trialsConditions";
import PersonnelEquipment from "./personnelEquipment";
import Checklist from "../../../../icons/Checklist";
import Breadcrumb from "../../../../molecules/Breadcrumb";
import { getData, patchData, postData } from "../../../../../services";
import toast from "react-hot-toast";
import {
  confimationStyles,
  errorStyles,
} from "../../../../../assets/styles/toast";
import { useCookies } from "react-cookie";
import { useNavigate, useParams } from "react-router-dom";
import TestDetails from "./testDetails";
import TestProcedure from "./testProcedure";
import TrialTest from "./trialTests";
import Camera from "../../../../icons/Camera";
import GeneralSetup from "./generalSetup";
import Recommendation from "./recommendation";
import TestImage from "./testImage";
import PageCard from "../../../../atoms/PageCard";
import { display, padding } from "@mui/system";
import TrialHistory from "./trialsHistory";
import AllRecommendation from "./allRecommendation";
import Ship from "../../../../icons/Ship";
import Trust from "../../../../icons/Trust";

const AddTrials = () => {
  const [cookies] = useCookies(["t"]);
  const { trial_id } = useParams();
  const { vessel_id } = useParams();
  const [trialData, setTrialData] = useState({});
  const navigate = useNavigate();
  const [testData, setTestData] = useState({});

  useEffect(() => {
    if (trial_id) {
      fetchAllRecommendationData();
    }
  }, [trial_id]);

  const [checkboxState, setCheckboxState] = useState({}); // Track checkbox states

  const fetchAllRecommendationData = async () => {
    try {
      const res = await getData({
        endpoint: "trialtest/getAllRecommendationByTrialId",
        token: cookies.t,
        params: { id: trial_id },
      });
      setAllRecommendationRows(res?.data || {});
    } catch (err) {
      console.log("Error fetching Recommendation data:", err);
      setAllRecommendationRows({});
    }
  };

  useEffect(() => {
    fetchAllRecommendationData();
  }, [checkboxState]);

  // State for Trial Details
  const [trialDetails, setTrialDetails] = useState({
    trials: "",
    vesselName: "",
    rollingScheme: false,
    type: "",
    startDate: null,
    endDate: null,
  });

  // State for Trials Conditions and Synopsis
  const [conditions, setConditions] = useState({
    location: "",
    weather: "",
    sea_state: "",
    water_depth: "",
    sea_current: "",
    limitations: "",
    remarks: "",
  });

  // Sate for Machine Machineries
  const [machineMachineriesRows, setMachineMachineriesRows] = useState([
    {
      engine: "",
      maintainence_checked: "",
      outstanding_maintainence: "",
      last_oil_analysis: "",
      last_oil_anlysis_results: "",
      running_hours: "",
      last_major_overhaul: "",
    },
    {
      engine: "",
      maintainence_checked: "",
      outstanding_maintainence: "",
      last_oil_analysis: "",
      last_oil_anlysis_results: "",
      running_hours: "",
      last_major_overhaul: "",
    },
    {
      engine: "",
      maintainence_checked: "",
      outstanding_maintainence: "",
      last_oil_analysis: "",
      last_oil_anlysis_results: "",
      running_hours: "",
      last_major_overhaul: "",
    },
  ]);

  const machineriesRowsCount = machineMachineriesRows?.length;

  const [machineMachineriesRemarks, setMachineMachineriesRemarks] =
    useState("");

  const [mainSwitchboard, setMainSwitchboard] = useState({
    maintenanceCheck: "",
    contractorsName: "",
    modifications: "",
    remarks: "",
  });

  // State for Dp sensors

  const [dpSensorRows, setDpSensorRows] = useState([
    {
      sensor: "",
      records_check: "",
      remarks: "",
    },
    {
      sensor: "",
      records_check: "",
      remarks: "",
    },
    {
      sensor: "",
      records_check: "",
      remarks: "",
    },
  ]);

  const dpSensorRowsCount = dpSensorRows?.length;

  const [dpSensorRemarks, setDpSensorRemarks] = useState("");

  // State for Vessel Control Rows
  const [vesselControlRows, setVesselControlRows] = useState([
    {
      control_system: "",
      maintainence_checked: "",
      last_software_revision: "",
    },
  ]);
  const [vesselControlRemarks, setVesselControlRemarks] = useState("");

  const vesselControlRowsCount = vesselControlRows?.length;

  // State for Hardware Modification
  const [hardwareModification, setHardwareModification] = useState("");
  const [modificationsTested, setModificationsTested] = useState("");
  const [trialsUpdated, setTrialsUpdated] = useState("");

  // State for capability Footprints
  const [capabilityOnboards, setCapabilityOnboards] = useState("");
  const [footprints, setFootprints] = useState("");

  // State for DP Station
  const [eventsRecorded, setEventsRecorded] = useState("");
  const [satisfactoryExplanation, setSatisfactoryExplanation] = useState("");

  // State for eky Dp personnel
  const [dpPersonnelRows, setDpPersonnelRows] = useState([
    {
      name: "",
      position: "",
      company: "",
      certificate: "",
      issued_by: "",
      remarks: "",
    },
  ]);

  const dpPersonnelRowsCount = dpPersonnelRows?.length;

  // State for Fmea Trials

  const [fmeaLastRevision, setFmeaLastRevision] = useState("");
  const [fmeaLastDate, setFmeaLastDate] = useState(null);
  const [fmeaFindingsCloseOut, setFmeaFindingsCloseOut] = useState("");
  const [lastDpAnnualTrialsDate, setLastDpAnnualTrialsDate] = useState("");
  const [findingsClosedOut, setFindingsClosedOut] = useState(null);
  const [fmeaTrialsRemarks, setFmeaTrialsRemarks] = useState("");

  // State for operational documentation
  const [operationalDocumentation, setOperationalDocumentation] = useState({
    defined_in_section: false,
    are_appropriate_checklists: false,
    is_dp_log_book_kepy: false,
    have_access_to_guidelines_documents: false,
    are_records_available: false,
    remarks: "",
  });

  // State for General Setup
  const [systemSetupRows, setSystemSetupRows] = useState([
    {
      system: "",
      confirmed: false,
    },
    {
      system: "",
      confirmed: false,
    },
    {
      system: "",
      confirmed: false,
    },
  ]);

  const systemSetupRowsCount = systemSetupRows?.length;

  // State for Test Details
  const [testDetails, setTestDetails] = useState({
    title: "",
    testId: "",
    fmeaReference: "",
    onDp: "",
    system: "",
    subsystem: "",
    objective: "",
    imagesRequired: false,
    performedByCrew: false,
  });

  const [testProcedure, setTestProcedure] = useState({
    method: "",
    result: "",
    setup: "",
  });

  useEffect(() => {
    setTrialDetails((prev) => ({
      ...prev, // Spread the previous state to retain existing values
      vesselName: trialData?.vessel_id,
      trials: trialData?.trials || "",
      rollingScheme: trialData?.is_rolling_scheme || false,
      type: trialData?.type || "",
      startDate: trialData?.start_date || null,
      endDate: trialData?.end_date || null,
    }));
    setConditions((prev) => ({
      ...prev,
      location: trialData?.synopsis?.location || "",
      weather: trialData?.synopsis?.weather || "",
      sea_state: trialData?.synopsis?.sea_state || "",
      water_depth: trialData?.synopsis?.water_depth || "",
      sea_current: trialData?.synopsis?.sea_current || "",
      limitations: trialData?.synopsis?.limitations || "",
      remarks: trialData?.synopsis?.remarks || "",
    }));
    if (trialData?.main_mechineries_details?.main_machineries) {
      setMachineMachineriesRows(
        trialData?.main_mechineries_details?.main_machineries.map((row) => ({
          engine: row.engine || "",
          maintainence_checked: row.maintainence_checked || "",
          outstanding_maintainence: row.outstanding_maintainence || "",
          last_oil_analysis: row.last_oil_analysis || "",
          last_oil_anlysis_results: row.last_oil_anlysis_results || "",
          running_hours: row.running_hours || "",
          last_major_overhaul: row.last_major_overhaul || "",
        })),
      );
    }
    if (trialData?.main_mechineries_details?.main_machineries_remarks) {
      setMachineMachineriesRemarks(
        trialData?.main_mechineries_details?.main_machineries_remarks || "",
      );
    }

    if (trialData?.main_switchboard) {
      setMainSwitchboard((prev) => ({
        ...prev,
        maintenanceCheck:
          trialData?.main_switchboard?.last_maintainence_health_check || "",
        contractorsName: trialData?.main_switchboard?.contractors_name || "",
        modifications:
          trialData?.main_switchboard?.any_modification_since_last_trials || "",
        remarks: trialData?.main_switchboard?.main_switchboard_remarks || "",
      }));
    }

    if (trialData?.dp_sensor_details?.dp_sensors) {
      setDpSensorRows(
        trialData?.dp_sensor_details?.dp_sensors.map((row) => ({
          sensor: row.sensor || "",
          records_check: row.records_check || "",
          remarks: row.remarks || "",
        })),
      );
    }
    if (trialData?.dp_sensor_details?.dp_sensors_remarks) {
      setDpSensorRemarks(
        trialData?.dp_sensor_details?.dp_sensors_remarks || "",
      );
    }

    if (trialData?.vessel_control_details?.vessel_control) {
      setVesselControlRows(
        trialData?.vessel_control_details?.vessel_control.map((row) => ({
          control_system: row.control_system || "",
          maintainence_checked: row.maintainence_checked || "",
          last_software_revision: row.last_software_revision || "",
        })),
      );
    }
    if (trialData?.vessel_control_details?.vessel_control_remarks) {
      setVesselControlRemarks(
        trialData?.vessel_control_details?.vessel_control_remarks,
      );
    }

    if (trialData?.hardware_modification) {
      setHardwareModification(
        trialData?.hardware_modification?.modification_to_dp_system || "",
      );
      setModificationsTested(
        trialData?.hardware_modification?.being_tested || "",
      );
      setTrialsUpdated(
        trialData?.hardware_modification?.trial_procedures_updated || "",
      );
    }

    if (trialData?.capability_footprints_plots) {
      setCapabilityOnboards(
        trialData?.capability_footprints_plots?.coreent_capability_plots || "",
      );
      setFootprints(
        trialData?.capability_footprints_plots?.verifing_footprints || "",
      );
    }

    if (trialData?.dp_station_keeping_events) {
      setEventsRecorded(
        trialData?.dp_station_keeping_events?.events_being_recorded || "",
      );
      setSatisfactoryExplanation(
        trialData?.dp_station_keeping_events?.breif_details || "",
      );
    }

    if (trialData?.key_dp_personnel) {
      setDpPersonnelRows(
        trialData?.key_dp_personnel?.map((row) => ({
          name: row.name || "",
          position: row.position || "",
          company: row.company || "",
          certificate: row.certificate || "",
          issued_by: row.issued_by || "",
          remarks: row.remarks || "",
        })),
      );
    }

    if (trialData?.general_setup) {
      setSystemSetupRows(
        trialData?.general_setup?.map((row) => ({
          system: row.system || "",
          confirmed: row.confirmed || false,
        })),
      );
    }

    if (trialData?.fmea) {
      setFmeaLastRevision(trialData?.fmea?.last_revision || "");
      setFmeaLastDate(trialData?.fmea?.last_date || "");
      setFmeaFindingsCloseOut(trialData?.fmea?.all_closed_out_1 || "");
      setLastDpAnnualTrialsDate(trialData?.fmea?.annual_trial_date || "");
      setFindingsClosedOut(trialData?.fmea?.all_closed_out_2 || "");
      setFmeaTrialsRemarks(trialData?.fmea?.fmea_trials_remarks || "");
    }

    if (trialData?.operational_documentation) {
      setOperationalDocumentation((prev) => ({
        ...prev,
        defined_in_section:
          trialData?.operational_documentation?.defined_in_section || false,
        are_appropriate_checklists:
          trialData?.operational_documentation?.are_appropriate_checklists ||
          false,
        is_dp_log_book_kepy:
          trialData?.operational_documentation?.is_dp_log_book_kepy || false,
        have_access_to_guidelines_documents:
          trialData?.operational_documentation
            ?.have_access_to_guidelines_documents || false,
        are_records_available:
          trialData?.operational_documentation?.are_records_available || false,
        remarks: trialData?.operational_documentation?.remarks || "",
      }));
    }
  }, [trialData]);

  useEffect(() => {
    if (trial_id) {
      fetchTrialData();
      fetchAllTrialTest();
    }
    if (vessel_id) {
      fetchVesselData();
    }
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const RecommedationPayload = {
      updates: allRecommendationRows,
    };

    console.log("RecommedationPayload--->", RecommedationPayload);

    const payload = {
      trials: trialDetails?.trials,
      type: trialDetails?.type,
      is_rolling_scheme: trialDetails?.rollingScheme,
      start_date: trialDetails?.startDate,
      end_date: trialDetails?.endDate,
      synopsis: conditions,
      main_mechineries_details: {
        main_machineries: machineMachineriesRows,
        main_machineries_remarks: machineMachineriesRemarks,
      },
      main_switchboard: {
        last_maintainence_health_check: mainSwitchboard?.maintenanceCheck,
        contractors_name: mainSwitchboard?.contractorsName,
        any_modification_since_last_trials: mainSwitchboard?.modifications,
        main_switchboard_remarks: mainSwitchboard?.remarks,
      },
      dp_sensor_details: {
        dp_sensors: dpSensorRows,
        dp_sensors_remarks: dpSensorRemarks,
      },
      // control_systems: {
      //   control_systems_rows: vesselControlRows,
      //   control_systems_remarks: vesselControlRemarks,
      // },
      vessel_control_details: {
        vessel_control: vesselControlRows,
        vessel_control_remarks: vesselControlRemarks,
      },
      hardware_modification: {
        modification_to_dp_system: hardwareModification,
        being_tested: modificationsTested,
        trial_procedures_updated: trialsUpdated,
      },
      capability_footprints_plots: {
        coreent_capability_plots: capabilityOnboards,
        verifing_footprints: footprints,
      },
      dp_station_keeping_events: {
        events_being_recorded: eventsRecorded,
        breif_details: satisfactoryExplanation,
      },
      key_dp_personnel: dpPersonnelRows,
      general_setup: systemSetupRows,
      fmea: {
        last_revision: fmeaLastRevision,
        last_date: fmeaLastDate,
        all_closed_out_1: fmeaFindingsCloseOut,
        all_closed_out_2: findingsClosedOut,
        annual_trial_date: lastDpAnnualTrialsDate,
        fmea_trials_remarks: fmeaTrialsRemarks,
      },
      operational_documentation: {
        defined_in_section: operationalDocumentation?.defined_in_section,
        are_appropriate_checklists:
          operationalDocumentation?.are_appropriate_checklists,
        is_dp_log_book_kepy: operationalDocumentation?.is_dp_log_book_kepy,
        have_access_to_guidelines_documents:
          operationalDocumentation?.have_access_to_guidelines_documents,
        are_records_available: operationalDocumentation?.are_records_available,
        remarks: operationalDocumentation?.remarks,
      },
    };

    console.log("payload---->", payload);

    if (trial_id) {
      try {
        const result = await postData({
          endpoint: "trialtest/bulkEditRecommendations",
          data: RecommedationPayload,
          params: { trialId: trial_id },
          token: cookies.t,
        });

        console.log("Full API Response:", result);
      } catch (error) {
        console.error("Error submitting recommendation information:", error);

        // Display error message using toast
        toast.error(
          error?.response?.data?.message ||
            "An error occurred. Please try again.",
          {
            style: errorStyles,
            duration: 1000,
          },
        );
      }
    } else {
      console.warn("No trial_id provided. Skipping API call.");
    }

    if (trial_id) {
      try {
        // Make API call using postData
        const result = await patchData({
          endpoint: "vesselTrial/update",
          data: { ...payload, vessel_id: trialDetails?.vesselName },
          params: { id: trial_id },
          token: cookies.t,
        });

        console.log("result----->", result?.data?.data?.id);

        if (result) {
          toast.success(`Trial Updated successfully`, {
            style: confimationStyles,
            duration: 2000,
          });

          fetchTrialData(result?.data?.data?.id);
        }

        console.log("API Response:", result);
      } catch (error) {
        console.error("Error submitting vessel information:", error);
        //   toast.error("Test data not found");
        toast.error(error, {
          style: errorStyles,
          duration: 1000,
        });
      }
    } else {
      try {
        // Make API call using postData
        const result = await postData({
          endpoint: "vesselTrial/create",
          data: { ...payload, vessel_id: trialDetails?.vesselName },
          params: {},
          token: cookies.t,
        });

        console.log("result----->", result?.data?.data?.id);

        if (result) {
          toast.success(`Vessel Trial added successfully`, {
            style: confimationStyles,
            duration: 2000,
          });

          //   fetchVesselData(result?.data?.data?.id);
        }

        console.log("API Response:", result);
      } catch (error) {
        console.error("Error submitting vessel trial information:", error);
        //   toast.error("Test data not found");
        toast.error(error, {
          style: errorStyles,
          duration: 1000,
        });
      }
    }
  };

  const fetchTrialData = async () => {
    console.log("id------>", trial_id);
    try {
      const res = await getData({
        endpoint: "vesselTrial/getTrialDetailsById",
        token: cookies.t,
        params: { id: trial_id },
      });
      setTrialData(res?.data || {});
    } catch (err) {
      console.log("Error fetching vessel data:", err);
      setTrialData({}); // Ensure vesselData is empty on error
    }
  };

  const [vesselData, setVesselData] = useState({});

  const fetchVesselData = async () => {
    console.log("id------>", trial_id);
    try {
      const res = await getData({
        endpoint: "vessel/getVesselDetailsById",
        token: cookies.t,
        params: { id: vessel_id },
      });
      setVesselData(res?.data || {});
    } catch (err) {
      console.log("Error fetching vessel data:", err);
      setVesselData({}); // Ensure vesselData is empty on error
    }
  };

  const fetchAllTrialTest = async () => {
    try {
      const res = await getData({
        endpoint: "trialtest/getAllTestByTrialId",
        token: cookies.t,
        params: {
          id: trial_id,
        },
      });

      if (res) {
        console.log("res----->", res);
      }
      setTestData(res?.data?.testData || []);
    } catch (err) {
      console.log("Error fetching vessel data:", err);
      setTestData([]); // Ensure vesselData is empty on error
    }
  };

  console.log("vesselData---->", vesselData);

  console.log("Trialdata---->", trialData);

  const [allRecommendationRows, setAllRecommendationRows] = useState([
    {
      sno: 1,
      number: "",
      description: "",
      type: "",
      status: "",
      recommendations: [],
    },
  ]);

  console.log("allRecommendationRows-->", allRecommendationRows);

  // let totalRecommendations = 0;

  // // Iterate through the data
  // // Iterate through the data
  // allRecommendationRows?.forEach((test) => {
  //   totalRecommendations += test.recommendation_rows?.length;
  // });
  const filterRecommendationRows = (data, filterClosed) => {
    return data
      .map((test) => {
        const filteredRecommendationRows = test.recommendation_rows?.filter(
          (row) => {
            // If filterClosed is true, show both Opened and Closed status
            if (filterClosed === true) {
              return true; // Return both Opened and Closed
            }
            // If filterClosed is undefined or false, show only Opened status
            return row.status === "Opened";
          },
        );

        // Return the test object with the filtered rows
        return {
          ...test,
          recommendation_rows: filteredRecommendationRows,
        };
      })
      .filter((test) => test.recommendation_rows?.length > 0); // Filter out items with no recommendation rows
  };

  const filteredData = filterRecommendationRows(
    allRecommendationRows,
    checkboxState["4"],
  );

  let totalRecommendations = 0;

  // Iterate through the filtered data to count total recommendations
  filteredData?.forEach((test) => {
    totalRecommendations += test.recommendation_rows?.length;
  });

  console.log("Total Recommendations after filtering:", totalRecommendations);

  // Save the total recommendation count
  console.log("Total Recommendations:", totalRecommendations);

  const accordionData1 = [
    {
      title: "Trials Details",
      icon: <Info />,
      content: (
        <TrialDetails
          trialDetails={trialDetails}
          setTrialDetails={setTrialDetails}
        />
      ),
      status: true,
    },
    {
      title: "Trials Conditions and Synopsis",
      icon: <Map />,
      content: (
        <TrialConditions
          conditions={conditions}
          setConditions={setConditions}
        />
      ),
    },
    {
      title: "Personnel, Equipment and Documentation",
      icon: <User />,
      content: (
        <PersonnelEquipment
          machineMachineriesRows={machineMachineriesRows}
          setMachineMachineriesRows={setMachineMachineriesRows}
          machineMachineriesRemarks={machineMachineriesRemarks}
          setMachineMachineriesRemarks={setMachineMachineriesRemarks}
          machineriesRowsCount={machineriesRowsCount}
          mainSwitchboard={mainSwitchboard}
          setMainSwitchboard={setMainSwitchboard}
          dpSensorRows={dpSensorRows}
          setDpSensorRows={setDpSensorRows}
          dpSensorRemarks={dpSensorRemarks}
          setDpSensorRemarks={setDpSensorRemarks}
          dpSensorRowsCount={dpSensorRowsCount}
          vesselControlRows={vesselControlRows}
          setVesselControlRows={setVesselControlRows}
          vesselControlRemarks={vesselControlRemarks}
          setVesselControlRemarks={setVesselControlRemarks}
          vesselControlRowsCount={vesselControlRowsCount}
          hardwareModification={hardwareModification}
          setHardwareModification={setHardwareModification}
          modificationsTested={modificationsTested}
          setModificationsTested={setModificationsTested}
          trialsUpdated={trialsUpdated}
          setTrialsUpdated={setTrialsUpdated}
          capabilityOnboards={capabilityOnboards}
          setCapabilityOnboards={setCapabilityOnboards}
          footprints={footprints}
          setFootprints={setFootprints}
          eventsRecorded={eventsRecorded}
          setEventsRecorded={setEventsRecorded}
          satisfactoryExplanation={satisfactoryExplanation}
          setSatisfactoryExplanation={setSatisfactoryExplanation}
          dpPersonnelRows={dpPersonnelRows}
          setDpPersonnelRows={setDpPersonnelRows}
          dpPersonnelRowsCount={dpPersonnelRowsCount}
          fmeaLastRevision={fmeaLastRevision}
          setFmeaLastRevision={setFmeaLastRevision}
          fmeaLastDate={fmeaLastDate}
          setFmeaLastDate={setFmeaLastDate}
          fmeaFindingsCloseOut={fmeaFindingsCloseOut}
          setFmeaFindingsCloseOut={setFmeaFindingsCloseOut}
          lastDpAnnualTrialsDate={lastDpAnnualTrialsDate}
          setLastDpAnnualTrialsDate={setLastDpAnnualTrialsDate}
          findingsClosedOut={findingsClosedOut}
          setFindingsClosedOut={setFindingsClosedOut}
          fmeaTrialsRemarks={fmeaTrialsRemarks}
          setFmeaTrialsRemarks={setFmeaTrialsRemarks}
          operationalDocumentation={operationalDocumentation}
          setOperationalDocumentation={setOperationalDocumentation}
        />
      ),
      removePadding: true,
    },
    {
      title: "General Setup",
      icon: <Gear />,
      content: (
        <GeneralSetup
          systemSetupRows={systemSetupRows}
          setSystemSetupRows={setSystemSetupRows}
        />
      ),
      count: systemSetupRowsCount,
    },
    {
      title: "Recommendations",
      icon: <Exclamation />,
      content: (
        <AllRecommendation
          recommendationsData={allRecommendationRows}
          setRecommendationsData={setAllRecommendationRows}
          filterClosed={checkboxState["4"]}
          vesselId={vessel_id}
          trialId={trial_id}
        />
      ),
      checkbox: true,
      checkboxText: "Show Closed",
      count: totalRecommendations,
    },
    {
      title: "Trials History",
      icon: <Calendar />,
      content: <TrialHistory />,
    },
  ];

  // const handleNavigate = () => {
  //   navigate("/dashboard/Trial/doc-format", {
  //     state: { vesselData, trialData, testData },
  //   });
  // };

  // const handleNavigate = async () => {
  //   try {
  //     const res = await getData({
  //       endpoint: "vessel/getAllDetailsById",
  //       token: cookies.t,
  //       params: { id: vessel_id, trialId: trial_id },
  //     });
  //     //   console.log("res--->", res?.data);
  //     //   // Convert the Buffer to a Blob
  //     //   const buffer = res?.data; // The Buffer data
  //     //   const blob = new Blob([buffer.data], {
  //     //     type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //     //   });

  //     //   // Create a download link
  //     //   const link = document.createElement("a");
  //     //   link.href = URL.createObjectURL(blob);
  //     //   link.download = "document.docx"; // Specify the file name
  //     //   link.click(); // Trigger the download

  //     const buffer = res?.data; // The ArrayBuffer data
  //     if (buffer && buffer instanceof ArrayBuffer) {
  //       // Convert ArrayBuffer to Blob
  //       const blob = new Blob([buffer.data], {
  //         type: "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  //       });

  //       // Create a download link
  //       const link = document.createElement("a");
  //       link.href = URL.createObjectURL(blob);
  //       link.download = "document.docx"; // Specify the file name
  //       link.click(); // Trigger the download
  //     } else {
  //       console.error("Data is not in expected ArrayBuffer format.");
  //     }
  //   } catch (err) {
  //     console.log("Error fetching Recommendation data:", err);
  //   }
  // };
  const [isLoading, setIsLoading] = useState(false); // State to manage loading

  const handleDocDownload = async () => {
    try {
      setIsLoading(true); // Start loading
      const res = await getData({
        endpoint: "vessel/getAllDetailsById",
        token: cookies.t,
        params: { id: vessel_id, trialId: trial_id },
      });
      const fileLink = res?.data;

      const extractFileName = (fileUrl) => {
        return decodeURIComponent(
          fileUrl?.substring(fileUrl?.lastIndexOf("/") + 1),
        );
      };
      const link = document.createElement("a");
      link.href = fileLink;
      link.download = extractFileName(fileLink); // Suggest the filename
      link.click(); // Trigger download
    } catch (err) {
      console.log("Error fetching Recommendation data:", err);
    } finally {
      setIsLoading(false); // Stop loading
    }
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center", // Vertically centers the items
          justifyContent: "space-between", // Spaces out items
          paddingTop: "10px", // Optional: Add some padding around the container
          // background: "#f2f2f2", // Optional: Add a background color
          borderRadius: "4px", // Optional: Add rounded corners
          backgroundColor: "white",
          position: "sticky",
          top: "0px",
          marginTop: "-25px",
          marginBottom: "12px",
          zIndex: "99",
          paddingBottom: "10px",
          // top: "30px",
        }}
        // style={{
        //   position: "fixed", // Keeps the div fixed at the top
        //   top: 0, // Positions it at the top of the viewport
        //   left: "200px", // Aligns it to the left
        //   right: 0, // Stretches it across the full width
        //   zIndex: 1000, // Ensures it stays on top of other elements
        //   display: "flex",
        //   alignItems: "center", // Vertically centers the items
        //   justifyContent: "space-between", // Spaces out items
        //   padding: "10px", // Optional: Add some padding around the container
        //   backgroundColor: "#fff", // Optional: Add a background color
        //   borderBottom: "1px solid #ccc", // Optional: Add a bottom border for separation
        //   borderRadius: "0", // No rounded corners for a fixed bar
        // }}
      >
        {/* <Breadcrumb
          labels={[
            `${vesselData?.vessel_name || "Vessel"}`,
            `${trialData?.trials || "Edit Trial"}`,
          ]}
          logos={[
            <Ship />, // Logo for "Vessels"
            <Trust />, // Logo for "Edit Trial"
          ]}
        /> */}

        {trialData?.trials && vesselData?.vessel_name ? (
          <Breadcrumb
            labels={[
              `${vesselData?.vessel_name || "Vessel"}`,
              `${trialData?.trials || "Add Trial"}`,
            ]} // Labels for the breadcrumb
            logos={[
              <Ship width="20px" height="20px" />, // Logo for "Vessels"
              <Trust width="20px" height="20px" />, // Logo for "Edit Trial"
              null, // No logo for "Settings"
            ]}
            paths={[
              `/dashboard/vessel/${vessel_id}`,
              `/dashboard/Trial/${vessel_id}/${trial_id}`,
            ]} // Paths for each breadcrumb
            excludeLastPath={false}
            stepsToRemove={0}
          />
        ) : (
          <Breadcrumb
            labels={["Add Trial"]} // Labels for the breadcrumb
            logos={[
              <Trust width="20px" height="20px" />, // Logo for "Edit Trial"
              null, // No logo for "Settings"
            ]}
            paths={[`/dashboard/Trial/`]} // Paths for each breadcrumb
            excludeLastPath={false}
            stepsToRemove={0}
          />
        )}

        <div style={{ display: "flex", gap: "10px" }}>
          <button
            onClick={handleDocDownload}
            style={{
              ...styles.submitButton,
              marginTop: "0px", // Remove `!important` as it's not valid in inline styles
              // backgroundColor: isLoading ? "#e8e8e8" : "#007bff", // Conditional background color
              pointerEvents: isLoading ? "none" : "", // Change cursor when disabled
            }}
            disabled={isLoading}
          >
            Download Doc
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm ms-2"
                role="status"
                aria-hidden="true"
              ></span>
            )}
          </button>

          <button
            // onClick={handleSubmit}
            // onClick={() => {
            //   handleSubmit1();
            // }}
            style={{
              ...styles.submitButton,
              marginTop: "0px !important",
              // marginRight: "10px",
            }}
          >
            Complete
          </button>

          <button
            onClick={handleSubmit}
            style={{
              ...styles.submitButton,
              marginTop: "0px !important",
              // padding: "10px !important",
            }}
          >
            {/* <Disk /> */}
            Save
          </button>
        </div>
      </div>
      <Accordion
        items={accordionData1}
        setCheckboxState={setCheckboxState}
        checkboxState={checkboxState}
      />

      <div style={{ marginTop: "20px" }}></div>
      <TrialTest vesselId={vessel_id} trialId={trial_id} />
    </>
  );
};

const styles = {
  submitButton: {
    marginTop: "16px",
    padding: "8px 16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default AddTrials;
