import React from "react";
import MyTextInput from "../../../../atoms/MyTextInput";
import MyDropdown from "../../../../atoms/MyDropdown";
import { DateSelector } from "../../../../molecules/MonthYearSelector";

const VesselInformation = ({ vesselInformation, setVesselInformation }) => {
  // Single state for all form fields

  console.log("vesselInformation---->", vesselInformation);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setVesselInformation({
      ...vesselInformation,
      [name]: value,
    });
  };

  const handleDropdownChange = (name, option) => {
    setVesselInformation({
      ...vesselInformation,
      [name]: option?.value || "",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(vesselInformation);
  };

  return (
    <form onSubmit={handleSubmit}>
      {/* 1st Row: Vessel Name (100% width) */}
      <div style={styles.row}>
        <div style={styles.inputContainer}>
          <MyTextInput
            type="text"
            name="vesselName"
            customholder="Vessel Name"
            value={vesselInformation.vesselName}
            onChange={handleChange}
          />
        </div>
      </div>
      {/* 2nd Row: Owner / Operator (100% width) */}
      <div style={styles.row}>
        <div style={styles.inputContainer}>
          <MyTextInput
            type="text"
            name="ownerOperator"
            customholder="Owner / Operator"
            value={vesselInformation.ownerOperator}
            onChange={handleChange}
          />
        </div>
      </div>
      {/* 3rd Row: Industrial Mission and IMO Number (50% / 50%) */}
      <div style={styles.row}>
        <div style={styles.inputContainer1}>
          <MyTextInput
            type="text"
            name="industrialMission"
            customholder="Industrial Mission"
            value={vesselInformation.industrialMission}
            onChange={handleChange}
          />
        </div>
        <div style={styles.inputContainer1}>
          <MyTextInput
            type="text"
            name="imoNumber"
            customholder="IMO Number"
            value={vesselInformation.imoNumber}
            onChange={handleChange}
          />
        </div>
      </div>
      {/* 4th Row: IMO DP Equipment Class and Classification Society (50% / 50%) */}
      <div style={styles.row}>
        <div style={styles.inputContainer1}>
          <MyDropdown
            placeholder="IMO DP Equipment Class"
            data={[
              { label: "Class 1", value: "Class 1" },
              { label: "Class 2", value: "Class 2" },
              { label: "Class 3", value: "Class 3" },
            ]}
            format="all"
            menuOpen={true}
            name="imoClass"
            width="100%"
            selectedValue={vesselInformation.imoClass}
            onChange={(option) => handleDropdownChange("imoClass", option)}
          />
        </div>
        <div style={styles.inputContainer1}>
          <MyTextInput
            type="text"
            name="classificationSociety"
            customholder="Classification Society"
            value={vesselInformation.classificationSociety}
            onChange={handleChange}
          />
        </div>
      </div>
      {/* 5th Row: DP Class and Flag (50% / 50%) */}
      <div style={styles.row}>
        <div style={styles.inputContainer1}>
          <MyTextInput
            type="text"
            name="dpClass"
            customholder="DP Class"
            value={vesselInformation.dpClass}
            onChange={handleChange}
          />
        </div>
        <div style={styles.inputContainer1}>
          <MyTextInput
            type="text"
            name="flag"
            customholder="Flag"
            value={vesselInformation.flag}
            onChange={handleChange}
          />
        </div>
      </div>
      {/* 6th Row: Anniversary Date/Last 5 Year Trials and Year of Construction (50% / 50%) */}
      <div style={styles.row}>
        <div style={styles.dateSelectorContainer}>
          <DateSelector
            placeholder="Anniversary Date/Last 5 Year Trials"
            selectedDate={vesselInformation.anniversaryDate}
            onChange={(date) =>
              setVesselInformation({
                ...vesselInformation,
                anniversaryDate: date,
              })
            }
          />
        </div>
        <div style={styles.dateSelectorContainer}>
          <DateSelector
            placeholder="Last Annual Trials"
            selectedDate={vesselInformation.lastAnnualTrial}
            // onChange={(date) =>
            //   setVesselInformation({
            //     ...vesselInformation,
            //     lastAnnualTrial: date,
            //   })
            // }
            onChange={(date) => {
              console.log("Selected Date:", date); // Log the selected date
              setVesselInformation({
                ...vesselInformation,
                lastAnnualTrial: date,
              });
            }}
          />
        </div>
        <div style={styles.inputContainer1}>
          <MyTextInput
            type="text"
            name="yearOfConstruction"
            customholder="Year of Construction"
            value={vesselInformation.yearOfConstruction}
            onChange={handleChange}
          />
        </div>
      </div>
      {/* 7th Row: Length Overall, Tonnage, and Draught (33% / 33% / 33%) */}
      <div style={styles.row}>
        <div style={styles.inputContainer2}>
          <MyTextInput
            type="text"
            name="lengthOverall"
            customholder="Length Overall (in m)"
            value={vesselInformation.lengthOverall}
            onChange={handleChange}
          />
        </div>

        <div style={styles.inputContainer2}>
          <MyTextInput
            type="text"
            name="tonnage"
            customholder="Tonnage (in t)"
            value={vesselInformation.tonnage}
            onChange={handleChange}
          />
        </div>

        <div style={styles.inputContainer2}>
          <MyTextInput
            type="text"
            name="draught"
            customholder="Draught"
            value={vesselInformation.draught}
            onChange={handleChange}
          />
        </div>
      </div>
      {/* Submit Button */}
      {/* <button type="submit" style={styles.submitButton}>
        Submit
      </button> */}
    </form>
  );
};

const styles = {
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
    gap: "16px",
  },
  inputContainer: {
    width: "100%",
  },
  inputContainer1: {
    width: "50%",
  },
  inputContainer2: {
    width: "33.3%",
  },
  dateSelectorContainer: {
    width: "50%",
    position: "relative",
  },
  submitButton: {
    marginTop: "16px",
    padding: "8px 16px",
    backgroundColor: "#007bff",
    color: "#fff",
    border: "none",
    borderRadius: "4px",
    cursor: "pointer",
  },
};

export default VesselInformation;
