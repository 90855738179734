import React from "react";
import MyTextArea from "../../../../atoms/MyTextArea";
import CheckboxWrapper from "../../../../atoms/CheckBoxWrapper/Checkbox";

const OperationalDocumentation = ({
  operationalDocumentation,
  setOperationalDocumentation,
}) => {
  console.log(
    "operationalDocumentation------>",
    operationalDocumentation?.are_records_available,
  );

  const handleCheckboxChange = (field) => {
    setOperationalDocumentation((prev) => ({
      ...prev,
      [field]: !prev[field],
    }));
  };

  const handleRemarksChange = (e) => {
    setOperationalDocumentation((prev) => ({
      ...prev,
      remarks: e.target.value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form Data:", operationalDocumentation);
  };

  return (
    <form onSubmit={handleSubmit}>
      <div style={styles.checkboxContainer}>
        <span style={styles.text}>
          Is there a vessel specific DP operations manual as described in IMCA M
          section 3.3 ?
        </span>
        <CheckboxWrapper
          checked={operationalDocumentation?.defined_in_section}
          onChange={() => handleCheckboxChange("defined_in_section")}
        />
      </div>
      <hr />

      <div style={styles.checkboxContainer}>
        <span style={styles.text}>
          Are there appropriate checklists covering field arrivals trials,
          location checklists, periodic checks as appropriate for bridge and
          engine room ?
        </span>
        <CheckboxWrapper
          checked={operationalDocumentation?.are_appropriate_checklists}
          onChange={() => handleCheckboxChange("are_appropriate_checklists")}
        />
      </div>
      <hr />

      <div style={styles.checkboxContainer}>
        <span style={styles.text}>Is a DP log book kept up to date ?</span>
        <CheckboxWrapper
          checked={operationalDocumentation?.is_dp_log_book_kepy}
          onChange={() => handleCheckboxChange("is_dp_log_book_kepy")}
        />
      </div>
      <hr />

      <div style={styles.checkboxContainer}>
        <span style={styles.text}>
          Do the key DP personnel have access to appropriate IMCA guidance
          documents either via Internet or hard copy ?
        </span>
        <CheckboxWrapper
          checked={
            operationalDocumentation?.have_access_to_guidelines_documents
          }
          onChange={() =>
            handleCheckboxChange("have_access_to_guidelines_documents")
          }
        />
      </div>
      <hr />

      <div style={styles.checkboxContainer}>
        <span style={styles.text}>
          Are records available for vendor's visits for repair or service of the
          system components ?
        </span>
        <CheckboxWrapper
          checked={operationalDocumentation?.are_records_available}
          onChange={() => handleCheckboxChange("are_records_available")}
        />
      </div>
      <hr />

      <div style={styles.row}>
        <div style={styles.inputContainer}>
          <MyTextArea
            name="remarks"
            customholder="Operational Documentation Remarks"
            value={operationalDocumentation?.remarks}
            type="textarea"
            onChange={handleRemarksChange}
          />
        </div>
      </div>
    </form>
  );
};

const styles = {
  row: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "16px",
    gap: "16px",
  },
  text: {
    fontSize: "14px",
    marginRight: "5px",
  },
  inputContainer: {
    width: "100%",
  },
  checkboxContainer: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    gap: "8px",
    marginBottom: "16px",
  },
};

export default OperationalDocumentation;
